import { FC } from "react";
import { Controller } from "react-hook-form";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useLeasingRatesCtx } from "../LeaseRateProvider";
import { LeasingRateForm } from "@/interfaces/LeaseRate";

// Unified Styles for Labels
const gridLabelStyle = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
  whiteSpace: "nowrap", // Prevent text wrapping
};

const containerInputStyle = {
  width: "100%",
  paddingTop: "1px",
  paddingBottom: "1px",
};

const termColumns = ["12 Months", "24 Months", "36 Months", "42 Months", "48 Months"];

const RowLabels: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid sx={{ ...gridLabelStyle, visibility: "hidden" }}>Type</Grid>
    {[
      "Vehicle Maint. Plan",
      "GAP",
      "Limited Warranty ",
      "Credit Education",
      "Tracker",
      "Bank Fee",
      "Total"
    ].map((label, idx) => (
      <Grid key={idx} sx={{ ...gridLabelStyle, padding: "15.75px 8px 15.75px 8px" }}>
        {label}
      </Grid>
    ))}
  </Grid>
);

const Month12Column: FC = () => {
  const { control } = useLeasingRatesCtx((s) => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR1_Main",
    "yR1_GAP",
    "yR1_LW",
    "yR1_CrRep",
    "yR1_Tracker",
    "yR1_BankFee",
    "yR1_Total"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[0]}</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                containerStyle={containerInputStyle}
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month24Column: FC = () => {
  const { control } = useLeasingRatesCtx((s) => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR2_Main",
    "yR2_GAP",
    "yR2_LW",
    "yR2_CrRep",
    "yR2_Tracker",
    "yR2_BankFee",
    "yR2_Total"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[1]}</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                containerStyle={containerInputStyle}
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month36Column: FC = () => {
  const { control } = useLeasingRatesCtx((s) => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR3_Main",
    "yR3_GAP",
    "yR3_LW",
    "yR3_CrRep",
    "yR3_Tracker",
    "yR3_BankFee",
    "yR3_Total"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[2]}</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                containerStyle={containerInputStyle}
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month42Column: FC = () => {
  const { control } = useLeasingRatesCtx((s) => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR35_Main",
    "yR35_GAP",
    "yR35_LW",
    "yR35_CrRep",
    "yR35_Tracker",
    "yR35_BankFee",
    "yR35_Total"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[3]}</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                containerStyle={containerInputStyle}
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month48Column: FC = () => {
  const { control } = useLeasingRatesCtx((s) => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR4_Main",
    "yR4_GAP",
    "yR4_LW",
    "yR4_CrRep",
    "yR4_Tracker",
    "yR4_BankFee",
    "yR4_Total"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[4]}</Grid>
      {formFields.map((fieldName) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                rightAlignInput
                containerStyle={containerInputStyle}
                value={
                  field.value !== null && field.value !== undefined ? Number(field.value) : null
                }
                style={{ textAlign: "right" }}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const GeneralRateSheet: FC = () => (
  <Grid container direction="column" flex={1} boxSizing={"border-box"}>
    <Grid container direction="column" gap={3} flex={1} component="form">
      <Grid
        container
        direction="column"
        flex={1}
        sx={{ backgroundColor: "#eff0f4", padding: "0px 16px 0px 16px", borderRadius: "10px" }}
      >
        <Grid container direction="row" flex={1} padding="16px 32px 16px 32px">
          <Grid container direction="row" spacing={2} flex={1}>
            <Grid sx={{ maxWidth: "180px", minWidth: "180px" }} flex={1}>
              <RowLabels />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month12Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month24Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month36Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month42Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month48Column />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default GeneralRateSheet;