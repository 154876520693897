import { FC } from 'react';
import { Email } from '@/services/customerService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { SetState } from '@/interfaces/utilityTypes';
import { Icons } from '@/components/icons';
import StandardizedActivityCard from '../StandardizedActivityCard';
import styles from '../activityPanel.module.scss';

const EmailActivityCard: FC<{activity:Email, setSelectedRecId:SetState<number | null>}> = ({
  activity, 
  setSelectedRecId
}) => {
  return (
    <StandardizedActivityCard
      icon={<Icons.Mail />}
      timestamp={formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
    >
      <div className={styles.messageHeader}>
        <div>Email from {activity.sentBy}</div>
      </div>
      {!!activity.subject && (
        <div>
          <div>
            {activity.subject ? `"${activity.subject}" - ` : ''}
            <span
              className={styles.link}
              onClick={() => setSelectedRecId(activity.recId)}
            >
              Click to view
            </span>
          </div>
        </div>
      )}
    </StandardizedActivityCard>
  );
};

export default EmailActivityCard;