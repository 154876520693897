import { FC } from 'react';
import { Note } from '@/services/customerService';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { Icons } from '@/components/icons';
import StandardizedActivityCard from '../StandardizedActivityCard';
import styles from '../activityPanel.module.scss';
import { DCS_BLUE } from '@/mui/theme/colors';

const NoteActivityCard: FC<{activity:Note, updatePinnedNote:Function}> = ({
  activity, 
  updatePinnedNote
}) => {
  return (
    <StandardizedActivityCard
      icon={<Icons.Note />}
      timestamp={formatDate(activity.updatedUtc, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
    >
      <div className={styles.row}>
        <div className={styles.messageHeader}>Note created by {activity.createdByString}</div>
        <div className={styles.pinRow}>
          {activity.pinned && (
            <div className={styles.disclaimer}>
              {activity.pinnedByString} - {formatDate(activity.pinnedOnUtc)}
            </div>
          )}
          <div
            className={styles.pin}
            onClick={() => updatePinnedNote(activity)}
            style={{ fill: activity.pinned ? DCS_BLUE : '#b1b1b1' }}
          >
            <Icons.Pin />
          </div>
        </div>
      </div>
      <div>{activity.note}</div>
    </StandardizedActivityCard>
  );
};

export default NoteActivityCard;