import React from 'react';
import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import styles from './BackLink.module.scss';
import { accountActions } from '@/features/Accounts/accountSlice';
import { useAppDispatch } from '@/store/store';

interface BackLinkProps {
  to: To;
  navigateOptions?: NavigateOptions;
  title: string;
  containerStyle?: React.CSSProperties;
}

/** @deprecated Use MUI implementation */
export const BackLink: React.FC<BackLinkProps> = (props) => {
  const { to, title, navigateOptions = { relative: 'path' }, containerStyle = {} } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div
      className={styles.backLink}
      onClick={() => {
        dispatch(accountActions.setQuickActionsListOpen(false));
        navigate(to, navigateOptions)}}
      style={containerStyle}
    >
      <FaChevronLeft className={styles.icon} />
      <span className={styles.label}>{title}</span>
    </div>
  );
};
