import { FC } from "react";
import { Controller } from "react-hook-form";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { NumberInput } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { LeasingRateForm } from "@/interfaces/LeaseRate";
import { useLeasingRatesCtx } from "../LeaseRateProvider";

// Unified Styles for Labels
const gridLabelStyle = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
  whiteSpace: "nowrap", // Prevent text wrapping
};

const containerInputStyle = {
  width: "100%",
  paddingTop: "1px",
  paddingBottom: "1px",
};

const termColumns = ["12 Months", "24 Months", "36 Months", "42 Months", "48 Months"];

const RowLabels: FC = () => (
  <Grid container direction="column" spacing={2}>
    <Grid sx={{ ...gridLabelStyle, visibility: "hidden" }}>Type</Grid>
    {[
      "Residual Percentage",
      "Excess Mileage Charge",
      "Disposition Fee",
      "Early Payoff",
      { text: "Purchase Option", style: { whiteSpace: "normal", fontSize: "14px" } }
    ].map((label, idx) => {
      const labelText = typeof label === 'string' ? label : label.text;
      const customStyle = typeof label === 'string' ? {} : label.style;
      
      return (
        <Grid key={idx} sx={{ 
          ...gridLabelStyle, 
          padding: "15.75px 8px 15.75px 8px",
          ...customStyle
        }}>
          {labelText}
        </Grid>
      );
    })}
  </Grid>
);

const Month12Column: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR1_NewMilesPercent",
    "yR1_NewMilesPer",
    "yR1_NewDisp",
    "yR1_NewELT",
    "yR1_NewPurOpt"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[0]}</Grid>
      {formFields.map((fieldName, index) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => {
              if (index === 0 || index === 1) {
                // Convert value to number or null for NumberInput
                const numericValue = field.value !== null && field.value !== undefined && field.value !== '' 
                  ? Number(field.value) 
                  : null;
                
                return (
                  <NumberInput
                    isPercentage={index === 0}
                    isPercentageLarge={index === 0}
                    rightAlignInput
                    {...field}
                    value={numericValue}
                    containerStyle={containerInputStyle}
                  />
                );
              } else {
                return (
                  <CurrencyInput
                    {...field}
                    rightAlignInput
                    containerStyle={containerInputStyle}
                    value={
                      field.value !== null && field.value !== undefined ? Number(field.value) : null
                    }
                    style={{ textAlign: "right" }}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month24Column: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR2_NewMilesPercent",
    "yR2_NewMilesPer",
    "yR2_NewDisp",
    "yR2_NewELT",
    "yR2_NewPurOpt"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[1]}</Grid>
      {formFields.map((fieldName, index) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => {
              if (index === 0 || index === 1) {
                // Convert value to number or null for NumberInput
                const numericValue = field.value !== null && field.value !== undefined && field.value !== '' 
                  ? Number(field.value) 
                  : null;
                
                return (
                  <NumberInput
                    isPercentage={index === 0}
                    isPercentageLarge={index === 0}
                    rightAlignInput
                    {...field}
                    value={numericValue}
                    containerStyle={containerInputStyle}
                  />
                );
              } else {
                return (
                  <CurrencyInput
                    {...field}
                    rightAlignInput
                    containerStyle={containerInputStyle}
                    value={
                      field.value !== null && field.value !== undefined ? Number(field.value) : null
                    }
                    style={{ textAlign: "right" }}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month36Column: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR3_NewMilesPercent",
    "yR3_NewMilesPer",
    "yR3_NewDisp",
    "yR3_NewELT",
    "yR3_NewPurOpt"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[2]}</Grid>
      {formFields.map((fieldName, index) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => {
              if (index === 0 || index === 1) {
                // Convert value to number or null for NumberInput
                const numericValue = field.value !== null && field.value !== undefined && field.value !== '' 
                  ? Number(field.value) 
                  : null;
                
                return (
                  <NumberInput
                    isPercentage={index === 0}
                    isPercentageLarge={index === 0}
                    rightAlignInput
                    {...field}
                    value={numericValue}
                    containerStyle={containerInputStyle}
                  />
                );
              } else {
                return (
                  <CurrencyInput
                    {...field}
                    rightAlignInput
                    containerStyle={containerInputStyle}
                    value={
                      field.value !== null && field.value !== undefined ? Number(field.value) : null
                    }
                    style={{ textAlign: "right" }}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month42Column: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR35_NewMilesPercent",
    "yR35_NewMilesPer",
    "yR35_NewDisp",
    "yR35_NewELT",
    "yR35_NewPurOpt"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[3]}</Grid>
      {formFields.map((fieldName, index) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => {
              if (index === 0 || index === 1) {
                // Convert value to number or null for NumberInput
                const numericValue = field.value !== null && field.value !== undefined && field.value !== '' 
                  ? Number(field.value) 
                  : null;
                
                return (
                  <NumberInput
                    isPercentage={index === 0}
                    isPercentageLarge={index === 0}
                    rightAlignInput
                    {...field}
                    value={numericValue}
                    containerStyle={containerInputStyle}
                  />
                );
              } else {
                return (
                  <CurrencyInput
                    {...field}
                    rightAlignInput
                    containerStyle={containerInputStyle}
                    value={
                      field.value !== null && field.value !== undefined ? Number(field.value) : null
                    }
                    style={{ textAlign: "right" }}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const Month48Column: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  const formFields: (keyof LeasingRateForm)[] = [
    "yR4_NewMilesPercent",
    "yR4_NewMilesPer",
    "yR4_NewDisp",
    "yR4_NewELT",
    "yR4_NewPurOpt"
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid sx={gridLabelStyle}>{termColumns[4]}</Grid>
      {formFields.map((fieldName, index) => (
        <Grid key={fieldName}>
          <Controller
            name={fieldName}
            control={control}
            render={({ field }) => {
              if (index === 0 || index === 1) {
                // Convert value to number or null for NumberInput
                const numericValue = field.value !== null && field.value !== undefined && field.value !== '' 
                  ? Number(field.value) 
                  : null;
                
                return (
                  <NumberInput
                    isPercentage={index === 0}
                    isPercentageLarge={index === 0}
                    rightAlignInput
                    {...field}
                    value={numericValue}
                    containerStyle={containerInputStyle}
                  />
                );
              } else {
                return (
                  <CurrencyInput
                    {...field}
                    rightAlignInput
                    containerStyle={containerInputStyle}
                    value={
                      field.value !== null && field.value !== undefined ? Number(field.value) : null
                    }
                    style={{ textAlign: "right" }}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const NewCarRateSheet: FC = () => (
  <Grid container direction="column" flex={1} boxSizing={"border-box"}>
    <Grid container direction="column" gap={3} flex={1} component="form">
      <Grid
        container
        direction="column"
        flex={1}
        sx={{ backgroundColor: "#eff0f4", padding: "0px 16px 0px 16px", borderRadius: "10px" }}
      >
        <Grid container direction="row" flex={1} padding="16px 32px 16px 32px">
          <Grid container direction="row" spacing={2} flex={1}>
            <Grid sx={{ maxWidth: "200px", minWidth: "200px" }} flex={1}>
              <RowLabels />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month12Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month24Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month36Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month42Column />
            </Grid>
            <Grid xs={0.75} flex={1}>
              <Month48Column />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default NewCarRateSheet;