import { toast } from "react-toastify";
// utils
import { AxiosService } from "@/services/axiosService";
// interfaces
import { ApiResponse } from "@/interfaces/Api";
import { CustomerContact, CustomerDetails, CustomerVehicle } from "@/interfaces/Customer";
import { EcomRecord } from "@/interfaces/Ecom";
import { PaymentPaidInExtended, PaymentMethod } from "@/enums/payment";
import {
  LedgerSettings,
  Ledger,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/printLedger/interfaces";
import { CreateAppointmentReq } from "@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/quickActionsItems/addApointment/interfaces";
import { OptInData } from "@/features/Accounts/accountsSubviews/interfaces";
import { PaymentArrangementPayload } from "@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/quickActionsItems/paymentArrangement/interfaces";
import {
  InsuranceClaim,
  AcctsInsuranceRes,
  UpdateInsuranceReq,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/AcctInsurance/interfaces";

class CustomerService extends AxiosService {
  public constructor() {
    super();
  }

  async getAccountDetails(recId: number) {
    try {
      const { data } = await this.axios.get<CustomerDetails>("/Users/GetAccountByRecId", {
        params: { recId },
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVehicles(buyerRecId: number) {
    try {
      const { data } = await this.axios.get<CustomerVehicle[]>("/Users/GetVehiclesByBuyerRecId", {
        params: { buyerRecId },
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCustomerActivity(colRecId: number) {
    try {
      const { data } = await this.axios.get<CustomerActivityResponse>("/Customer/Activity", {
        params: { colRecId },
      });
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // @note this is unused
  async getCustomerContacts(appRecId: number) {
    try {
      const { data } = await this.axios.get("/Customer/Contacts", {
        params: { appRecId },
      });
      return data.data as CustomerContact[];
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postPaymentArrangement(payload: PaymentArrangementPayload) {
    try {
      const { data } = await this.axios.post("/Customer/PaymentArrangement", payload);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getLedgerCardSettings() {
    try {
      const res = await this.axios.get<{ data: LedgerSettings }>("/Users/Ledger");
      return res.data.data;
    } catch (e) {
      toast.error(
        "There was an issue loading your Ledger Card settings. You can continue to use this screen. Please double-check your Ledger Card sections."
      );
      console.error(e);
      throw e;
    }
  }

  async updateLedgerCardSettings(values: LedgerSettings) {
    try {
      await this.axios.put("/Users/Ledger", values);
    } catch (e) {
      toast.error("There was an issue saving your settings.");
      console.error(e);
      throw e;
    }
  }

  async getLedger(colRecId: number, values: any) {
    try {
      const res = await this.axios.get<{ data: Ledger }>("/Customer/Ledger", {
        params: { colRecId, ...values },
      });
      return res.data.data;
    } catch (e) {
      toast.error("There was an issue fetching the Ledger.");
      console.error(e);
      throw e;
    }
  }

  async printLedger(html: string) {
    try {
      const res = await this.axios.post<any>(
        "/Customer/LedgerPdf",
        { html },
        { responseType: "blob" }
      );
      return res.data;
    } catch (e) {
      toast.error("There was an issue printing the Ledger.");
      console.error(e);
      throw e;
    }
  }

  async getAcctInsuranceInfo(colRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<AcctsInsuranceRes>>(
        "/Customer/GetInsuranceInfo",
        { params: { colRecId } }
      );
      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an error receiving the customer's insurance information");
      throw e;
    }
  }

  async getInsuranceClaims(colRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<InsuranceClaim[]>>(
        "/Customer/InsuranceClaims",
        { params: { colRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async addInsuranceClaim(colRecId: number, claim: InsuranceClaim) {
    try {
      const { data } = await this.axios.post<ApiResponse<{ RecId: number }>>(
        "/Customer/AddInsuranceClaim",
        claim, // request body: the InsClaim data
        { params: { colRecId } } 
      );
      return data.data; 
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  
  async updateInsuranceClaim(claim: InsuranceClaim) {
    try {
      const { data } = await this.axios.put<ApiResponse<string>>(
        "/Customer/UpdateInsuranceClaim",
        claim 
      );
      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  

  async updateInsuranceInfo(payload: UpdateInsuranceReq) {
    try {
      await this.axios.put("/Customer/UpdateInsuranceInfo", payload);
      toast.success("Insurance information updated");
    } catch (e) {
      console.error(e);
      toast.error("Error updating insurance information");
      throw e;
    }
  }

  async postAppointment(payload: CreateAppointmentReq) {
    // @note `userRecId` is represented in state as `appBuyerRecId`
    if (payload.userRecId === undefined) {
      throw new Error(
        `appBuyerRecId/userRecId is undefined (\`useAccountSelector\`). Request body: ${JSON.stringify(
          payload,
          null,
          2
        )}`
      );
    }

    try {
      await this.axios.post("/Customer/Appointment", payload);
    } catch (e_) {
      const err = e_ as Error;
      throw err;
    }
  }

  async getEcomRecordByRecId(ecomRecId: number) {
    try {
      const response = await this.axios.get<ApiResponse<EcomRecord>>("/Customer/Ecom", {
        params: { recId: ecomRecId },
      });
      return response.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async markEcomMessageAsRead(ecomRecId: number) {
    try {
      await this.axios.put("/Customer/Ecom/Read", null, {
        params: { recId: ecomRecId },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async markEcomMessageAsUnread(ecomRecId: number) {
    try {
      await this.axios.put("/Customer/Ecom/Unread", null, {
        params: { recId: ecomRecId },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updatePinnedNote(payload: { recId: number; pinned: boolean }) {
    try {
      const { data } = await this.axios.put<
        ApiResponse<{ recId: number; pinned: boolean; pinnedBy: string; pinnedOnUtc: Date }>
      >("/Notes/Pin", payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /** @deprecated phone number should be retrieved from the backend. remove from request and update backend */
  async getOptIn(phoneNumber: string, appRecId: number) {
    try {
      const res = await this.axios.post<ApiResponse<OptInData>>("/Customer/GetDirectConsent", {
        PhoneNumberE164: phoneNumber,
        AppRecId: appRecId,
      });
      return res.data?.data;
    } catch (e) {
      console.error(e);
      toast.error("Error fetching direct consent");
      throw e;
    }
  }

  async beginOptIn(phoneNumber: string, appRecId: number) {
    try {
      await this.axios.post("/Customer/BeginDirectConsent", {
        PhoneNumberE164: phoneNumber,
        AppRecId: appRecId,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getOptInWorkflow(appRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<boolean>>(
        `/Customer/GetDirectConsentWorkflowEnabled/${appRecId}`
      );
      res.data.data === undefined &&
        console.warn(
          `customerService.getOptInWorkflow(): No data returned for appRecId: ${appRecId}`
        );
      return res.data.data;
    } catch (e) {
      console.error(e);
      console.error("Error getting opt in workflow authorization", e);
      throw e;
    }
  }
}

export const customerService = new CustomerService();

/** @deprecated move to interface file */
interface CustomerActivityResponse {
  payments: Payment[];
  emails: Email[];
  messages: Messages[];
  notes: Note[];
}

/** @deprecated move to interface file */
export interface Call {
  body: string;
  employeeName: string;
  fromName: string;
  phoneNumber: string; // Customer's number
  recId: number;
  relation: string;
  timestamp: string;
  toName: string; // Customer's name
  fromNumber?: string; // (optional if multiple numbers are used)
}

/** @deprecated move to interface file */
export interface Payment {
  colType: "CD" | "CS" | "DP" | "FS" | "LS" | "SN" | "WS" | "DD";
  recId: number | null;
  pmtRecId: number | null;
  paidIn: PaymentPaidInExtended | null;
  timestamp: string | null;
  paidBy: PaymentMethod | null;
  totalApplied: number | null;
  /** @deprecated @note This is not `pmtType`. Other fields that use `PaymentMethod` may name their field this. */
  paymentType: string | null;
  carPmt: number | null;
  pmtStatus: string | null;
}

/** @deprecated move to interface file */
export interface Email {
  timestamp: string | null;
  subject: string | null;
  sentBy: string | null;
  sentByEmail: string | null;
  sentTo: string | null;
  sentToEmail: string | null;
  recId: number;
}

/** @deprecated move to interface file */
export interface Messages {
  timestamp: string | null;
  body: string | null;
  sentBy: string | null;
  sentByNumber: string | null;
  sentTo: string | null;
  sentToNumber: string | null;
  received: boolean;
  seen: boolean;
  ecomRecId: number;
  isDirectConsentError: boolean | null;
  status: string | null;
  detailRaw: string | null;
  errorCode: number | null;
  errorMessage: string | null;
}

/** @deprecated move to interface file */
export interface Note {
  recId: number | null;
  note: string | null;
  type: string | null;
  status: string | null;
  createdUtc: string | null;
  updatedUtc: string | null;
  createdByString: string | null;
  createdById: number | null;
  processedOn: string | null;
  processedByString: string | null;
  processedById: number | null;
  pinned: boolean;
  pinnedOnUtc: Date | string | null;
  pinnedByString: string | null;
}
