import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// components
import App from './App';
import Router from './Router';
import { InPersonPayment } from '@/features/old/payment/inPersonPayment/InPersonPayment';
import { ACMiscPayment } from '@/features/old/payment/acMiscPayment/AcMiscPayment';
import { AcCPIPay } from '@/features/old/payment/acCPIPay/AcCPIPay';
import { SalesCashDown } from '@/features/old/payment/salesCashDown/salesCashDown';
import { ColPayment } from '@/features/old/payment/colPayment/ColPayment';
import { CashSalesPayment } from '@/features/old/payment/cashSalesPayment/CashSalesPayment';
import { AcPrinPayment } from '@/features/old/payment/acPrinPayment/AcPrinPayment';
import { WholesalePayment } from '@/features/old/payment/wholesalePayment/WholesalePayment';
// state
import { authActions, AuthAppState } from '@/features/auth/authSlice';
import { oldPaymentActions, OldPaymentAppState } from '@/features/old/payment/oldPaymentSlice';
import { store } from '@/store/store';
// utils
import { sentryOptions } from './utils/sentryCommon';
import { isDev, currentEnv } from '@/env';
// style
import '@/styles/index.scss';
import axios from 'axios';

// Alpha will pass us the ID of the div to render to. We will also use this to determine what component to render
const pageMapping = {
  'in-person-payment': <InPersonPayment />,
  'ac-misc-payment': <ACMiscPayment />,
  'ac-cpi-pay': <AcCPIPay />,
  'sales-cash-down': <SalesCashDown />,
  'col-payment': <ColPayment />,
  'cash-sales-payment': <CashSalesPayment />,
  'ac-prin-payment': <AcPrinPayment />,
  'wholesale-payment': <WholesalePayment />,
  'dms-v2': <Router />,
};

// If you determine you will need more data from Alpha, it will need to be added to AlphaAppState
// Alpha will also need to be configured to pass that, but for development purposes we will be mocking it anyway

if (isDev) {
  const renderTarget: keyof typeof pageMapping = 'dms-v2'; // nocommit - leave as dms-v2

  // Mock any values we expect to receive from alpha
  // Use caution when testing org/loc/comp or accounts outside testing org
  // Do not commit any changes using values from outside testing org

  const oldPaymentState: OldPaymentAppState = {
    orgId: 2, // nocommit - 2 for testing
    locId: 1, // nocommit - 1 for testing
    compId: 1, // nocommit - 1 for testing
    colRecId: 546961, // nocommit - can commit changes as long as inside test org
    appRecId: 763796, // nocommit - can commit changes as long as inside test org
  };

  const newUiState: Partial<AuthAppState> = {
    orgId: 2, // nocommit - 2 for testing
    locId: 1, // nocommit - 1 for testing
    compId: 1, // nocommit - 1 for testing
    ccLocId: 1000142, // nocommit - 1000142 for testing
    userId: 1058, // nocommit
    userName: 'Brad Gardner', // nocommit
    userEmail: 'bradgardner@sevenhillstechnology.com', // nocommit
  };

  // Because we are not working within Alpha, we also need to create the div to render to
  const targetElement = document.createElement('div');
  targetElement.setAttribute('id', renderTarget!);
  document.body.appendChild(targetElement);

  if (renderTarget === 'dms-v2') {
    store.dispatch(authActions.setState(newUiState));
  } else {
    store.dispatch(oldPaymentActions.setInitialState(oldPaymentState));
  }

  ReactDOM.createRoot(document.getElementById(renderTarget!) as HTMLElement).render(
    <App>{pageMapping[renderTarget]}</App>
  );
} else {
  Sentry.init({
    ...sentryOptions,
    environment: import.meta.env.MODE,
    integrations: [],
    beforeSend: (event, hint) => {
      try {
        // 🛑 Filter out JS errors from non-app sources (Alpha noise reduction)
        const frames = event?.exception?.values?.[0]?.stacktrace?.frames;
        if (!frames || !frames.length) return null;
        const { filename = "" } = frames[frames.length - 1] || {};
        if (!/assets\/index\..*\.js/.test(filename)) {
          return null;
        }
  
        // 🔍 Capture Response Headers for API issues
        const error = hint?.originalException;
        if (axios.isAxiosError(error) && error.response) {
          const traceId = error.response.headers?.["Dcl-Apm-Trace-Id"];
          const transactionId = error.response.headers?.["Dcl-Apm-Transaction-Id"];
  
          event.extra = {
            ...event.extra,
            "Dcl-Apm-Trace-Id": traceId,
            "Dcl-Apm-Transaction-Id": transactionId,
          };
        }
  
        return event;
      } catch {
        return null;
      }
    },
  });

  // renderTarget is always passed. The rest of the type depends on if it's dms-v2 or an old payment form being rendered
  type AlphaParams = OldPaymentAppState & AuthAppState & { renderTarget: keyof typeof pageMapping };

  window.ReactApp = {
    // When Alpha loads the react app, this property is set on the window so it can call ReactApp.render(alphaParams)
    render(alphaParams: AlphaParams) {
      const {
        renderTarget,
        orgId,
        compId,
        locId,
        ccLocId,
        userId,
        userName,
        userEmail,
        colRecId,
        appRecId,
      } = alphaParams;

      alphaParams.compId && localStorage.setItem("selectedCompId", alphaParams.compId.toString());
      if (renderTarget === 'dms-v2') {
        // @note keep for qa
        (currentEnv.key === 'qa' || currentEnv.key === 'staging') && console.warn('alpha dms-v2 (qa message):', currentEnv, alphaParams)

        Sentry.setUser({ email: userEmail, id: userId });
        // @todo remove risky code - this state already updates when the `/System/UserInfo` request is returned
        store.dispatch(
          authActions.setState({
            orgId,
            locId,
            compId,
            ccLocId,
            userId,
            userName,
            userEmail,
          })
        );
      } else {
        currentEnv.key === 'qa' && console.warn('alpha not-dms-v2 (qa message):', currentEnv, alphaParams)
        store.dispatch(
          oldPaymentActions.setInitialState({ orgId, locId, compId, colRecId, appRecId })
        );
      }

      ReactDOM.createRoot(document.getElementById(renderTarget) as HTMLElement).render(
        <App>{pageMapping[renderTarget]}</App>
      );
    },
  };
}
