import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
//mui
import Grid from "@mui/material/Unstable_Grid2";
import ConfirmationModal from "@/mui/components/ConfirmationModal";
// kendo
import Button from '@/mui/components/buttons/MuiKendoButton';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { Spacer } from "@/components/spacer/Spacer";
import { TextArea } from "@/components/inputs/textarea/TextArea";
import { TextInput } from "@/components/inputs/text/TextInput";
// kendo
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
// state
import { useAppDispatch } from "@/store/store";
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import { getAccountInformation } from "@/features/Accounts/accountActionCreators";
// services
import { accountsService } from "@/services/accountsService";
import { paymentService } from "@/services/paymentService";
// utils
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { genericRequired } from "@/utils/helpers/formValidation";
import { useNavUp } from "@/utils/routing/useNavUpEvent";
// interfaces
import {
  ChargeOffInformation,
  ReactivateChargeOffForm,
  ReactivateChargeOffPayload,
} from "@/interfaces/Accounts";
import { ColTypeCode } from "@/enums/general";
import { EmployeeField } from "@/interfaces/System";
// style
import styles from "../accountsMainPanel/AccountsMainPanel.module.scss";

/** @deprecated needs refactor */
export const ReactivateChargeOff = () => {
  const navUp = useNavUp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const compId = useAuthSelector((s) => s.compId);
  const userId = useAuthSelector((s) => s.userId);
  const accountInformation = useAccountSelector((s) => s.accountInformation);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [chargeOffInfo, setChargeOffInfo] = useState<ChargeOffInformation>();
  const [employees, setEmployees] = useState<EmployeeField[]>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const colRecId = Number(params.colRecId);
  const isLease = accountInformation?.colType === ColTypeCode.enum.LS;

  // Event handlers
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);
  const handleOpenConfirmationModal = () => setIsConfirmOpen(true);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ReactivateChargeOffForm>();

  const handleClickCancel = () => {
    navUp();
  };

  const initData = async () => {
    setLoading(true);
    try {
      const chargeOffInfoRes = await accountsService.getChargeOffInformation(colRecId);
      setChargeOffInfo(chargeOffInfoRes);
      const employeesRes = await paymentService.getUsersByCompanyId(compId!);
      setEmployees(employeesRes);
      const currentEmployee = employeesRes.find((e) => e.recId === userId);
      if (currentEmployee) {
        setValue("employee", currentEmployee);
      }
    } finally {
      setLoading(false);
    }
  };

  const submit = async (data: ReactivateChargeOffForm) => {
    try {
      setSubmitting(true);
      const payload: ReactivateChargeOffPayload = {
        userId: data.employee!.recId,
        password: data.password,
        colRecId,
      };
      await accountsService.postReactivateChargeOff(payload);
      toast.success("Account reactivated");
      dispatch(getAccountInformation(colRecId));
      navigate("..");
    } catch {
      // handle error if needed
    } finally {
      handleCloseConfirmationModal();
      setSubmitting(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <AccountsMainPanel
      navBarTitle={isLease ? "Reactivate Lease" : "Reactivate Charge-Off"}
      loading={loading}
    >
      {!chargeOffInfo ? (
        <div>Unable to load charge off information for this account</div>
      ) : (
        <Grid
          container
          direction="column"
          onSubmit={handleSubmit(submit)}
          gap={3}
          flex={1}
          component="form"
        >
          <div className={styles.columns}>
            <div className={styles.column}>
              <DateInput
                readOnly
                label="Date"
                value={formatDate(new Date(), { pattern: DateFormat.DateInput })}
              />
              <CurrencyInput
                readOnly
                label="Principal Balance"
                value={chargeOffInfo.principalBalance ?? 0}
              />
              <CurrencyInput readOnly label="Misc Balance" value={chargeOffInfo.miscBalance ?? 0} />
              <CurrencyInput
                readOnly
                label="Total"
                value={(chargeOffInfo.principalBalance ?? 0) + (chargeOffInfo.miscBalance ?? 0)}
              />
              <TextInput readOnly label="Type" value={chargeOffInfo.chgOffCat ?? ""} />
              <Controller
                name="employee"
                control={control}
                rules={genericRequired}
                render={({ field }) => (
                  <DropdownInput
                    required
                    label="Employee"
                    data={employees}
                    dataItemKey="recId"
                    textField="shortName"
                    errors={errors.employee?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={genericRequired}
                render={({ field }) => (
                  <TextInput
                    label="Password"
                    required
                    errors={errors.password?.message}
                    type="password"
                    {...field}
                  />
                )}
              />
              <Controller
                name="note"
                control={control}
                render={({ field }) => <TextArea label="Notes" rows={3} {...field} />}
              />
            </div>
            <div className={styles.column}>
              <Spacer expand />
              <Grid container direction="row" justifyContent="flex-end" gap={1}>
                <Button
                  type="button"
                  onClick={handleOpenConfirmationModal}
                  disabled={!isValid || isSubmitting}
                  fullWidth={false}
                >
                  Submit
                </Button>
                <Button type="button" onClick={handleClickCancel} fullWidth={false}>
                  Cancel
                </Button>
              </Grid>
            </div>
          </div>
          <ConfirmationModal
            isOpen={isConfirmOpen}
            setIsOpen={setIsConfirmOpen}
            title="Confirm Lease Reactivation"
            message="Are you sure you would like to reactivate this lease?"
            onConfirm={handleSubmit(submit)}
            isSubmitting={isSubmitting}
          />
        </Grid>
      )}
    </AccountsMainPanel>
  );
};
