import { Checkbox, PhoneInput, TextInput } from "@/components";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { FC } from "react";
import { Controller } from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useLienholdersCtx } from "../LienHolderProvider";
import { CheckboxInputNew } from "@/components/inputs/checkboxNew/CheckboxInputNew";

const fieldContainerStyle = {
  marginBottom: "0px",
};

const gridLabelStyle = {
  fontSize: "14px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  minHeight: "52px", // Makes all label rows uniform in height
};

const RightContainer: FC = () => {
  const { control } = useLienholdersCtx((s) => s.lienholdersForm);

  return (
    <SectionColumn p={0}>
      <Controller
        name={"contact"}
        control={control}
        render={({ field }) => <TextInput label="Contact Person" {...field} />}
      />
      <Controller
        name={"phone"}
        control={control}
        render={({ field }) => (
          <PhoneInput {...field} label="Phone" value={field.value ? field.value : ""} />
        )}
      />
      <Controller
        name={"fax"}
        control={control}
        render={({ field }) => (
          <PhoneInput {...field} label="Fax" value={field.value ? field.value : ""} />
        )}
      />
      <Grid style={fieldContainerStyle}>
        <Controller
          name="inHouse"
          control={control}
          render={({ field }) => (
            <CheckboxInputNew
              {...field}
              label="In House"
              labelPlacement="before"
              containerStyles={{
                display: "flex",
                alignItems: "center",
                height: "36px",
              }}
            />
          )}
        />
      </Grid>
      <Grid style={fieldContainerStyle}>
        <Controller
          name="active"
          control={control}
          render={({ field }) => (
            <CheckboxInputNew
              {...field}
              label="Active"
              labelPlacement="before"
              containerStyles={{
                display: "flex",
                alignItems: "center",
                height: "36px",
              }}
            />
          )}
        />
      </Grid>
      <Grid style={fieldContainerStyle}>
        <Controller
          name="defaultLh"
          control={control}
          render={({ field }) => (
            <CheckboxInputNew
              {...field}
              label="Default Lienholder"
              labelPlacement="before"
              containerStyles={{
                display: "flex",
                alignItems: "center",
                height: "36px",
              }}
            />
          )}
        />
      </Grid>
      <Grid style={fieldContainerStyle}>
        <Controller
          name="withRecourse"
          control={control}
          render={({ field }) => (
            <CheckboxInputNew
              {...field}
              label="With Recourse"
              labelPlacement="before"
              containerStyles={{
                display: "flex",
                alignItems: "center",
                height: "36px",
              }}
            />
          )}
        />
      </Grid>
      <Grid sx={gridLabelStyle}>Assign Contract</Grid>
      <Grid container direction="column" spacing={2} style={{ paddingLeft: "48px" }}>
        <Grid flex={1}>
          <Controller
            name="assCont"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxInputNew
                checked={value === "Assigned"}
                onChange={() => onChange("Assigned")}
                label="Assigned"
                labelPlacement="before"
                containerStyles={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            )}
          />
        </Grid>
        <Grid flex={1}>
          <Controller
            name="assCont"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxInputNew
                checked={value === "Contracted"}
                onChange={() => onChange("Contracted")}
                label="Contracted"
                labelPlacement="before"
                containerStyles={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </SectionColumn>
  );
};

export default RightContainer;

// defaultLh: null,
// withRecourse: null,
