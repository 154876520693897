import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { CircleSpinner } from "react-spinners-kit";
import { debounce } from "lodash";
// kendo
import { Icons } from "@/components/icons";
import { TextInput } from "@components/inputs/text/TextInput";
import { Button } from "@components/button/Button";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useAcctDetailsCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/AcctDetailProvider";
// utils
import { Messages, customerService } from "@/services/customerService";
import { accountsService } from "@/services/accountsService";
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { formatPhone } from "@/utils/helpers/formatting";
// interfaces
import { Events } from "@/enums/general";
import { ActivityType, CustomerActivity } from "./activityPanelInterfaces";
// style
import styles from "./activityPanel.module.scss";
import { DropdownInput } from "../inputs";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import TextTemplateModalContent from "./textImportModalContent"
import CommonModal from "@/mui/components/CommonModal";
import { Grid2Props } from "@mui/material";
interface ChatBoxProps {
  activityData: CustomerActivity[];
  appBuyerRecId: number;
  initData: () => Promise<void>;
  height?: number;
  loading: boolean;
  isLegal: boolean;
  canTextBuyer: boolean;
  canTextCoBuyer: boolean;
}

interface ContactOption {
  appBuyerRecId: number;
  contact: string;
}

interface SmsForm {
  newMessage: string;
  appBuyerRecId: number | null;
}

export const ChatBox: FC<ChatBoxProps> = (props) => {
  const { userName, compId } = useAuthSelector((s) => s);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [newMessageSending, setNewMessageSending] = useState(false);
  const optStatus = useAcctDetailsCtx((s) => s.optStatus);
  const optInAuthorization = useAcctDetailsCtx((s) => s.optInAuthorization);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const [contacts, setContacts] = useState<ContactOption[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactOption | null>(null);
  const { signalRConnection, setSignalRConnection, setUnreadSmsCount } = useAuthCtx((s) => s);
  const [isTextTemplateOpen, setIsTextTemplateOpen] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<SmsForm>({
    defaultValues: {
      newMessage: "",
      appBuyerRecId: props.appBuyerRecId,
    },
    mode: "onChange",
  });

  const modalSx: Grid2Props | undefined = {
    display: "flex",
    p: 0,
    overflow: "hidden",
    gap: 2,
    width: "50%",
  };

  const [draftMessage, setDraftMessage] = useState(localStorage.getItem("smsDraft") || "");

  useEffect(() => {
    localStorage.setItem("smsDraft", draftMessage);
  }, [draftMessage]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("smsDraft");
    };
  }, []);

  const newMessage = watch("newMessage");

  const init = () => {
    if (!props.activityData) return;
    if (!accountInformation) return;
    const tempMessages = props.activityData
      .filter((ad) => ad.activityType === ActivityType.enum.Message)
      .map((ad) => ad.activity as Messages);

    // Use reliable date comparison for sorting
    tempMessages.sort((a, b) => {
      const dateA = new Date(a.timestamp || "");
      const dateB = new Date(b.timestamp || "");
      return dateB.getTime() - dateA.getTime(); // Newest first
    });

    setMessages(tempMessages);

    const { buyer, coBuyer } = accountInformation;
    const hasCoBuyer = !!(coBuyer?.appBuyerRecId && coBuyer?.firstName);

    if (props.canTextBuyer) {
      setContacts([
        {
          appBuyerRecId: buyer.appBuyerRecId!,
          contact: `Buyer - ${buyer.firstName} ${buyer.lastName}`,
        },
      ]);
    }

    if (hasCoBuyer && props.canTextCoBuyer) {
      setContacts((prevContacts) => [
        ...prevContacts,
        {
          appBuyerRecId: coBuyer.appBuyerRecId!,
          contact: `Co-buyer - ${coBuyer.firstName} ${coBuyer.lastName}`,
        },
      ]);
    }
  };

  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContact(contacts[0] ?? null);
      setValue("appBuyerRecId", contacts[0]?.appBuyerRecId ?? props.appBuyerRecId); // Set the first contact
    }
  }, [contacts, setValue, props.appBuyerRecId]);

  useEffect(() => {
    init();
  }, [props.activityData]);

  useEffect(() => {
    // Create a debounced handler to prevent multiple rapid refreshes
    const debouncedHandler = debounce(() => {
      props.initData();
    }, 300);
    
    window.addEventListener(Events.ReceivedSMS, debouncedHandler);
    
    return () => {
      window.removeEventListener(Events.ReceivedSMS, debouncedHandler);
      debouncedHandler.cancel(); // Cancel any pending debounced calls
    };
  }, [props.initData]);

  const addNewMessage = async (formData: SmsForm) => {
    if (!isValid) return;

    setNewMessageSending(true);
    try {
      await accountsService.sendTextMessege(formData.appBuyerRecId!, formData.newMessage);
      setDraftMessage("");
      localStorage.removeItem("smsDraft");
      reset();
      const insertedMessage: Messages = {
        timestamp: new Date().toString(),
        body: formData.newMessage,
        sentBy: userName || "",
        sentByNumber: null,
        sentTo: null,
        sentToNumber: null,
        received: false,
        ecomRecId: Number(Math.random().toString().slice(2)),
        seen: false,
        isDirectConsentError: null,
        status: null,
        detailRaw: null,
        errorCode: null,
        errorMessage: null,
      };
      setMessages([insertedMessage, ...messages]); // Insert at beginning (newest first)
      props.initData();
    } finally {
      setNewMessageSending(false);
    }
  };
  
  const changeReadStatus = async (changedMessage: Messages) => {
    try {
      const wasUnread = !changedMessage.seen;

      if (changedMessage.seen) {
        await customerService.markEcomMessageAsUnread(changedMessage.ecomRecId);
      } else {
        await customerService.markEcomMessageAsRead(changedMessage.ecomRecId);
      }
      if (signalRConnection && compId) {
        await signalRConnection.send("UpdateMessageCountForConnections", compId, "");
      }
      if (wasUnread) {
        setUnreadSmsCount((prev) => prev - 1); // Decrease when marking as read
      } else {
        setUnreadSmsCount((prev) => prev + 1); // Increase when marking as unread
      }

      setMessages(
        messages.map((m) => {
          if (m.ecomRecId === changedMessage.ecomRecId) {
            m.seen = !m.seen;
          }
          return m;
        })
      );
    } catch (err) {
      toast.error("Unable to change read status");
    }
  };

  const placeholderMessageSwitch = () => {
    if (optInAuthorization && optStatus !== "accepted") {
      return "Customer has not consented to texts. Messaging is disabled.";
    }
    if (props.isLegal) {
      return "Cannot contact customer with legal status";
    }

    if (!props.canTextBuyer && !props.canTextCoBuyer) {
      return "No Text preference on";
    }
    return "Type a new message...";
  };

  const messagingInputDisabledSwitch = () => {
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (props.isLegal || !props.canTextBuyer) {
      return true;
    }
    return false;
  };

  const messagingButtonDisabledSwitch = () => {
    if (optInAuthorization && optStatus !== "accepted") {
      return true;
    }
    if (
      props.isLegal ||
      !newMessage ||
      newMessageSending ||
      (!props.canTextBuyer && !props.canTextCoBuyer) ||
      !isValid
    ) {
      return true;
    }
    return false;
  };

  function insertTextTemplate(template: { body: string; }){
    setDraftMessage(template.body)
    setIsTextTemplateOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(addNewMessage)} className={styles.smsInputContainer}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", gap: 15 }}>
        <Controller
          name="newMessage"
          control={control}
          render={({ field }) => (
            <TextInput
              containerStyles={{ display: "flex", flex: "1" }}
              inputStyles={{ paddingRight: "50px" }}
              placeholder={placeholderMessageSwitch()}
              disabled={messagingInputDisabledSwitch()}
              {...field}
              value={draftMessage}
              onChange={(e) => {
                field.onChange(e);
                setDraftMessage(e.target.value);
              }}
            />
          )}
        />
          <button
            type = "button"
            className={styles.textTemplateButton}
            onClick={() => setIsTextTemplateOpen(true)}
          >
            <Icons.ArrowRightImport />        
          </button>
          <CommonModal
              open={isTextTemplateOpen} 
              onClose={() => setIsTextTemplateOpen(false) }
              containerProps={modalSx}
              title="Text Templates" 
            >
              <TextTemplateModalContent onRowClick={(params)=>{insertTextTemplate(params.row)}}/>
            </CommonModal>
          <Controller
            name="appBuyerRecId"
            control={control}
            rules={{ required: "Field is required" }}
            render={({ field }) => (
              <DropdownInput
                required={props.canTextBuyer || props.canTextCoBuyer}
                disabled={!props.canTextBuyer && !props.canTextCoBuyer}
                data={contacts}
                dataItemKey="appBuyerRecId"
                textField="contact"
                value={selectedContact}
                onChange={(e) => {
                  setSelectedContact(e.value);
                  setValue("appBuyerRecId", e.value?.appBuyerRecId);
                }}
                errors={errors.appBuyerRecId?.message}
              />
            )}
          />
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            label="Send"
            disabled={messagingButtonDisabledSwitch()}
            loading={newMessageSending}
          />
        </div>
      </form>
      <div className={styles.chatBoxContainer}>
        {props.loading ? (
          <div className={styles.loading}>
            <CircleSpinner color={"#3299df"} size={50} />
          </div>
        ) : (
          messages.map((message) => {
            const wasOutboundSMS = !message.received;
            const headerContents = [
              message.sentBy,
              formatDate(message.timestamp, { pattern: DateFormat.DateTime, utc: false }),
            ];
            // Date may be missing, if so don't show the dash
            const headerText = headerContents.filter((el) => !!el).join(" - ");
            return (
              <div
                className={wasOutboundSMS ? styles.smsOutbound : styles.smsInbound}
                key={message.ecomRecId}
              >
                <div className={styles.smsHeader}>{headerText}</div>
                <div className={styles.smsBodyWrapper}>
                  <div className={styles.readIcon} onClick={() => changeReadStatus(message)}>
                    {!wasOutboundSMS && message.seen && <Icons.Eye color="#129A38" />}
                    {!wasOutboundSMS && !message.seen && <Icons.EyeX color="#C60000" />}
                    {(message.isDirectConsentError ||
                      message.status === "failed" ||
                      message.status === "undelivered") && <Icons.RoundX color="#C60000" />}
                  </div>
                  <div className={styles.smsBody}>
                    {message.isDirectConsentError && (
                      <div className={styles.smsDirectConsentFailure}>
                        Failed to send, Customer opted out of text communication with phone number:{" "}
                        {formatPhone(message.sentToNumber ?? "")}
                      </div>
                    )}
                    {(message.status === "failed" || message.status === "undelivered") && (
                      <div className={styles.smsDirectConsentFailure}>
                        Message failed with error: {message.errorMessage ?? message.errorCode}
                      </div>
                    )}
                    <div>{message.body}</div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};