import { toast } from "react-toastify";
// utils
import { AxiosService } from "@/services/axiosService";
import { store } from "@/store/store";
// interfaces
import { Company, ICompany, ICompanyLookup } from "@/interfaces/Company";
import { ApiResponse } from "@/interfaces/Api";
import { SupportUserData } from "@/interfaces/Support";
import { CpiSettings } from "@/interfaces/applications";
import { UnreadSMS } from "@/features/notifications/interfaces";
import { CreateSupportPostReq } from "@/features/RootView/SupportWidget/interfaces";
import { CompanyAddOnsTax, CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";
import { ICompanyDefaults } from "@/interfaces/CompanyDefaults";
import { RepoCompany } from "@/interfaces/RepoCompany";
import dayjs from "dayjs";
import { Lienholders } from "@/interfaces/Lienholders";
import { ChecklistItem } from "@/interfaces/CompanyChecklist";
import { CompanyDefSaleType } from "@/interfaces/CompanyDefSaleType";
import { LeasingRate, LeasingRateForm } from "@/interfaces/LeaseRate";

class CompanyService extends AxiosService {
  public constructor() {
    super();
  }

  async getUnreadSMS(locId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<UnreadSMS[]>>("/Company/Messages", {
        params: { locId },
      });

      // We only need the most recent message per phone number
      const sortedSms = data.data!.sort((a, b) => {
        return b.recId - a.recId;
      });

      const unreadSmsMap: { [key: string]: UnreadSMS } = {};

      sortedSms.forEach((sms) => {
        const existingSMS = unreadSmsMap[sms.phoneNumber];
        if (existingSMS) {
          existingSMS.unreadCount = existingSMS.unreadCount ? existingSMS.unreadCount + 1 : 1;
        } else {
          unreadSmsMap[sms.phoneNumber] = { unreadCount: 1, ...sms };
        }
      });

      return Object.values(unreadSmsMap);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCompanyDetails(recId: number) {
    try {
      const data = await this.axios.get<{ data: Company }>("/Company/GetCompanyInfoSettings", {
        params: { recId },
      });
      return data.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue fetching the Company information.");
      throw e;
    }
  }

  async getCompanyDefaults(recId: number) {
    try {
      const data = await this.axios.get<{ data: ICompanyDefaults }>("/Company/GetCompanyDefaults", {
        params: { recId },
      });
      return data.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue fetching the Company information.");
      throw e;
    }
  }
  async getCompanyChecklist(recId: number) {
    try {
      const data = await this.axios.get<{ data: ChecklistItem[] }>("/Company/GetCompanyChecklist", {
        params: { recId },
      });
      return data.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue fetching the checklist information.");
      throw e;
    }
  }
  
  async updateCompanyChecklist(formData: { openingChecklist: ChecklistItem[]; closingChecklist: ChecklistItem[] }) {
    try {
      const combinedChecklists = [...formData.openingChecklist, ...formData.closingChecklist];
  
      await this.axios.put("/Company/UpdateCompanyChecklist", combinedChecklists);
  
      toast.success("Checklist updated successfully.");
    } catch (e) {
      console.error(e);
      toast.error("There was an issue updating the checklist.");
      throw e;
    }
  }

  async updateCompanyDefaults(company: ICompanyDefaults) {
    try {
      const response = await this.axios.put<ApiResponse<ICompanyDefaults>>(
        "/Company/UpdateCompanyDefaults",
        company
      );
      return response.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue updating the company information.");
      throw e;
    }
  }

  async updateCompanyDetails(company: Company) {
    try {
      const response = await this.axios.put<ApiResponse<Company>>(
        "/Company/UpdateCompanyInformation",
        company
      );
      return response.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue updating the company information.");
      throw e;
    }
  }

  async getCompanyDefType(recId: number) {
    try {
      const res = await this.axios.get<ApiResponse<CompanyDefSaleType>>(
        `/Company/GetCompanyDefSaleType?recId=${recId}`
      );
  
      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error('Unable to fetch company default sale type');
      throw e;
    }
  }
  

  async getCompanies() {
    try {
      const res = await this.axios.get<ApiResponse<ICompanyLookup[]>>(
        "/Company/GetCompaniesByDefOrgId"
      );

      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to fetch companies");
      throw e;
    }
  }

  async createSupportRequest(payload: CreateSupportPostReq) {
    try {
      await this.axios.post("Support/CreateSupportRequest", payload);
    } catch (e) {
      console.error(e);
      toast.error("Error creating support request");
      throw e;
    }
  }

  async getSupportUserData() {
    try {
      const { data } = await this.axios.get<ApiResponse<SupportUserData>>("Support/UserData");
      return data.data!;
    } catch (e) {
      console.error(e);
      //intentionally not throwing error if 404
      return;
    }
  }

  async getCpiSettings(companyId?: number) {
    let compId;
    if (!companyId) {
      compId = store.getState().auth.compId;
    }
    compId = companyId;
    if (!compId) {
      console.error("No compId found in state");
      return;
    }

    try {
      const res = await this.axios.get<ApiResponse<CpiSettings>>("Company/GetCpiSettings", {
        params: { compId },
      });
      return res.data.data;
    } catch (e) {
      toast.error("Unable to load CPI settings");
      console.error(e);
      throw e;
    }
  }

  async uploadFile(formData: FormData): Promise<{ url: string }> {
    try {
      const response = await this.axios.post<ApiResponse<{ url: string }>>(
        "/File/Upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Ensure that the response contains the URL
      if (!response.data.data || !response.data.data.url) {
        throw new Error("File upload failed: URL not returned");
      }

      return response.data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCompanyInfo(recId: number) {
    try {
      const data = await this.axios.get<{ data: ICompany }>("/Company/GetCompanyInfoSettings", {
        params: { recId },
      });
      return data.data.data;
    } catch (e) {
      console.error(e);
      toast.error("There was an issue fetching the Company information.");
      throw e;
    }
  }

  async getAddOnsSalesTaxSettings(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<CompanyAddOnsTax[]>>(
        "/Company/GetAddOnsSalesTaxSettings",
        {
          params: { compId },
        }
      );

      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to fetch companies add ons and sales tax settings");
      throw e;
    }
  }

  async updateAddOnsSalesTaxSettings(reqBody: CompanyAddOnsTaxForm) {
    try {
      const res = await this.axios.post("/Company/UpdateAddOnsSalesTaxSettings", reqBody);

      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to update companies add ons and sales tax settings");
      throw e;
    }
  }

  async getLeasingRateSettings(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<LeasingRate[]>>(
        "/Company/GetLeasingRateSettings",
        {
          params: { compId },
        }
      );
      
      // Make sure we return the array of leasing rates
      return res.data.data || [];
    } catch (e) {
      console.error(e);
      toast.error("Unable to fetch leasing rate settings");
      throw e;
    }
  }
  
  async updateLeasingRateSettings(reqBody: LeasingRateForm) {
    try {
      const res = await this.axios.post("/Company/UpdateLeasingRateSettings", reqBody);
      
      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to update leasing rate settings");
      throw e;
    }
  }

  async getLienholdersSettings(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<Lienholders[]>>(
        "/Company/GetLienholdersSettings",
        {
          params: { compId },
        }
      );

      return res.data.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to fetch companies lienholders settings");
      throw e;
    }
  }

  async updateLienholdersSettings(reqBody: Lienholders) {
    try {
      const res = await this.axios.post("/Company/UpdateLienholdersSettings", reqBody);

      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to update companies lienholders settings");
      throw e;
    }
  }

  async addLienholdersSettings(reqBody: Lienholders) {
    try {
      const res = await this.axios.post("/Company/AddLienholdersSettings", reqBody);

      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Unable to add company lienholders settings");
      throw e;
    }
  }

  async getRepoCompaniesByCompanyId(compId: number) {
    try {
      const res = await this.axios.get<ApiResponse<RepoCompany[]>>(
        "/Users/GetFullRepoCompaniesByCompanyId",
        { params: { compId } }
      );
      return res.data.data;
    } catch (err) {
      console.error(err);
      toast.error("Unable to fetch companies repo companies");
      throw err;
    }
  }

  async upsertRepoCompany(reqBody: RepoCompany) {
    try {
      var validReqBody = {
        ...reqBody,
        bondExpired: dayjs(reqBody.bondExpired).isValid() ? reqBody.bondExpired : null,
        insuranceExpired: dayjs(reqBody.insuranceExpired).isValid()
          ? reqBody.insuranceExpired
          : null,
        licenseExpired: dayjs(reqBody.licenseExpired).isValid() ? reqBody.licenseExpired : null,
      } as RepoCompany;

      const res = await this.axios.post("/Users/UpsertRepoCompany", validReqBody);

      return res.data;
    } catch (e) {
      console.error(e);
      toast.error("Failed to save repo company information");
      throw e;
    }
  }
}

export const companyService = new CompanyService();
