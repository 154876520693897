// state
// utils
import { AxiosService } from "./axiosService";
import { displayErrors } from "@/utils/helpers/general";
// interfaces
import { ApiResponse } from "@/interfaces/Api";
import { PreviousAmendments, RequestForRewriteForm, RequestForRewriteRes } from "@/interfaces/RequestRewrite";

class RequestRewriteService extends AxiosService {
  public constructor() {
    super();
  }

  async getRequestsForRewrite(appRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<RequestForRewriteRes[]>>("/requestForRewrite/accounts", {
        params: { appRecId: appRecId }
      });
      return res.data.data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async getPreviousAmendments(appRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<PreviousAmendments>>("/requestForRewrite/amendments", {
        params: { appRecId: appRecId }
      });
      return res.data.data;
    } catch (err: any) {
      displayErrors(err.message);
      throw err;
    }
  }

  async postRequestForRewrite(payload: RequestForRewriteForm) {
    try {
      const { data } = await this.axios.post<ApiResponse<RequestForRewriteRes>>(
        "/requestForRewrite/create",
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  // async printAuthFormDoc(payload: PrintAuthForm) {
  //   try {
  //     const { data } = await this.axios.post<ApiResponse<string>>(
  //       "/Payment/PrintAuthForm",
  //       payload
  //     );
  //     return data.data!;
  //   } catch (e) {
  //     console.error(e);
  //     throw e;
  //   }
  // }
}

export const requestRewriteService = new RequestRewriteService();
