import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
// mui
import DateInput from "@/mui/components/form/MuiKendoDateField";
// kendo
import { Button, Checkbox, DropdownInput, TextArea, TextInput } from "@/components";
import { Modal } from "@/components/modals/Modal";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { InventoryExpense, Vendor, InvCosPolOptions, InventoryExpenseDocument } from '@/interfaces';
import { DateFormat, formatDate } from '@/utils/helpers/general';
import { notGreaterThanTodayValidation } from '@/utils/helpers/formValidation';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { FormLinkWrapper } from '@/components/formLinkWrapper/formLinkWrapper';
import { FunctionLink } from '@/components/functionLink/FunctionLink';
import { SelectVendorModal } from '@components/selectVendorModal/selectVendorModal';
import { useAuthSelector } from '@/features/auth/authSlice';
import { inventoryService } from '@/services/inventoryService';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
import { systemService } from '@/services/systemService';
import ConfirmationModal from '@/components/confirmationModal/confirmationModal';
// style
import inventoryPageStyles from '../../InventoryPageStyles.module.scss';

const paidViaOptions = ['AMEX', 'Cash Drawer ', 'Check', 'Bill Vendor'];

const defaultFormValues: InventoryExpense = {
  recId: null,
  orgId: 2, // TODO: set current orgId
  docLocalName: '',
  docFileType: '',
  docName: '',
  docFlag: false,
  stockNum: '',
  internal: false,
  paidTo: '',
  poNum: '',
  labor: 0,
  parts: 0,
  other: 0,
  invCosPol: 'Dealership Expense',
  compId: 2,
  expDate: formatDate(dayjs().format(), { pattern: DateFormat.DateInput , utc: false}),
  expType: '',
  expFor: '',
  expAmt: 0,
  vendorName: '',
  ckNum: '',
  payVia: null,
  custAmt: 0,
  dealAmt: 0,
};

interface EditExpenseModalProps {
  expense?: InventoryExpense;
  historicalExpense: boolean;
  opened: boolean;
  setOpened: (value: boolean) => void;
  saleStatus: string;
  vehRecId: string;
  stockNum: string;
  reloadGrid: () => void;
}

export const EditExpenseModal: FC<EditExpenseModalProps> = ({
  expense,
  historicalExpense,
  opened,
  setOpened,
  saleStatus,
  vehRecId,
  stockNum,
  reloadGrid,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingDocument, setDeletingDocument] = useState<boolean>(false);
  const [vendorListModalOpened, setVendorListModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showDeleteDocumentConfirmationModal, setShowDeleteDocumentConfirmationModal] =
    useState<boolean>(false);
  const { orgId, compId, backDateExp } = useAuthSelector((state) => state);

  const closeModal = () => {
    reset(defaultFormValues, { keepDirty: false });
    reloadGrid();
    setOpened(false);
  };

  const saveExpense = useCallback(
    async (data: InventoryExpense) => {
      if (!data) {
        return;
      }

      try {
        setLoading(true);

        if (data.recId) {
          await inventoryService.updateInventoryExpense(data.recId, data);
          toast.success('Updated expense!');
        } else {
          data.invCosPol = InvCosPolOptions[
            data.invCosPol as keyof typeof InvCosPolOptions
          ] as string;

          // set defaults for orgId, compId, vehRecId, and stockNum
          data.orgId = orgId;
          data.compId = compId;
          data.vehRecId = parseInt(vehRecId);
          data.stockNum = stockNum;

          const insertedRecId = await inventoryService.createInventoryExpense(data);
          data.recId = insertedRecId ?? null;
          toast.success('Created expense!');
        }

        if (selectedFile) {
          try {
            const uuid = crypto.randomUUID();
            const fileType = '.' + selectedFile.name.split('.').pop();
            const fileNameCloud = `${uuid}${fileType}`;
            const blobUrl = await systemService.uploadBlob(selectedFile, orgId!, fileNameCloud);

            const doc: InventoryExpenseDocument = {
              docUrl: blobUrl,
              docSize: selectedFile.size,
              docName: `orgid_${orgId!}/${fileNameCloud}`,
              docFileType: selectedFile.type,
              docLocalName: data.docLocalName,
            };

            await inventoryService.saveDocument(data.recId!, doc);
          } catch (error) {
            console.error(error);
          }
        }

        reloadGrid();
        closeModal();
      } finally {
        setLoading(false);
      }
    },
    [inventoryService, selectedFile, setLoading, systemService, toast, orgId, compId]
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<InventoryExpense>({
    defaultValues: defaultFormValues,
  });

  const deleteDocument = useCallback(async () => {
    const recId = watch('recId');

    try {
      setDeletingDocument(true);
      await inventoryService.deleteDocument(recId!, orgId!);

      setValue('docName', '');
      setValue('docLocalName', '');
      setSelectedFile(null);
      setValue('docFlag', false);

      toast.success('Expense document deleted!');
      reloadGrid();
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingDocument(false);
    }
  }, [inventoryService, setLoading, toast, watch, orgId]);

  const resetForm = () => {
    reset(
      expense
        ? {
            ...expense,
            expDate: formatDate(expense.expDate || '', { pattern: DateFormat.DateInput, utc: false}),
          }
        : defaultFormValues,
      { keepDirty: false }
    );
  };

  const handleUploadedDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (file) {
      setValue('docName', file.name, { shouldDirty: true });
      setSelectedFile(file);
    } else {
      setValue('docName', '', { shouldDirty: true });
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    reset(watch(), { keepDirty: false });
  }, [isSubmitSuccessful]);

  useEffect(() => {
    reset(
      expense
        ? {
            ...expense,
            expDate: formatDate(expense.expDate || '', { pattern: DateFormat.DateInput, utc: false }),          }
        : defaultFormValues,
      { keepDirty: false }
    );

    clearErrors();
  }, [expense, opened]);

  const showOrHidePaidToField = (event: CheckboxChangeEvent) => {
    const internal = event.target.value == true;
    setValue('internal', internal, { shouldDirty: true });

    if (internal) {
      setValue('paidTo', 'Internal');
    } else {
      setValue('paidTo', expense ? expense.paidTo : '');
    }
  };

  const selectVendor = (vendor: Vendor) => {
    setValue('paidTo', vendor.vendorName, { shouldDirty: true });
    setValue('vendRecId', vendor.recId!, { shouldDirty: true });
  };

  useEffect(() => {
    const [internal, labor, parts, other] = watch(['internal', 'labor', 'parts', 'other']);
    if (internal) {
      setValue('expAmt', labor + parts + other);
    }
  }, [watch('internal'), watch('labor'), watch('parts'), watch('other')]);

  useEffect(() => {
    const [invCosPol, expAmt, custAmt] = watch(['invCosPol', 'expAmt', 'custAmt']);
    if (invCosPol?.toLowerCase() !== 'dealership expense') {
      setValue('dealAmt', expAmt - custAmt);
    }
  }, [watch('expAmt'), watch('custAmt')]);

  const [recId, internalExpense, selectedInvCosPol, payVia, docName, docFlag] = watch([
    'recId',
    'internal',
    'invCosPol',
    'payVia',
    'docName',
    'docFlag',
  ]);
  const vehicleSold = saleStatus?.toLowerCase() === 's';
  const isDealershipExpense =
    selectedInvCosPol?.toString().toLowerCase() === 'dealership expense' ||
    selectedInvCosPol?.toString().toLowerCase() === 'e';
  const payViaCheck = payVia?.toString().toLowerCase() === 'check';
  const editingExpense = recId != null;
  const canDeleteDocument = recId && docFlag;

  return (
    <>
      <Modal
        isOpen={opened}
        centerModal
        closeButton
        onCloseButtonClick={closeModal}
        onDimmerClick={closeModal}
      >
        <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Vehicle Expense</div>
        <form onSubmit={handleSubmit(saveExpense)}>
          <div className={inventoryPageStyles.columns}>
            <div className={inventoryPageStyles.column}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 1.25 }}>
                  <Controller
                    name="internal"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        label="Internal Expense"
                        disabled={historicalExpense || editingExpense}
                        {...field}
                        onChange={showOrHidePaidToField}
                      />
                    )}
                  />
                </div>
              </div>
              {vehicleSold && !editingExpense && (
                <Controller
                  name="invCosPol"
                  control={control}
                  rules={{
                    required: 'Expense To is required',
                  }}
                  render={({ field }) => (
                    <DropdownInput
                      label="Expense To"
                      suggest
                      data={Object.keys(InvCosPolOptions)}
                      errors={errors.invCosPol?.message}
                      disabled={historicalExpense}
                      required
                      {...field}
                    />
                  )}
                />
              )}
              <Controller
                name="expDate"
                control={control}
                rules={{
                  required: 'Date is required',
                  ...notGreaterThanTodayValidation,
                }}
                render={({ field }) => (
                  <DateInput
                    label="Date"
                    required
                    disabled={historicalExpense || !backDateExp}
                    errors={errors.expDate?.message}
                    {...field}
                  />
                )}
              />
              {!internalExpense && (
                <>
                  <Controller
                    name="paidTo"
                    control={control}
                    rules={{
                      required: 'Paid To is required',
                    }}
                    render={({ field }) => (
                      <TextInput
                        label="Paid To"
                        disabled
                        required
                        errors={errors.paidTo?.message}
                        {...field}
                      />
                    )}
                  />
                  {!historicalExpense && (
                    <FormLinkWrapper>
                      <FunctionLink
                        onClick={() => setVendorListModalOpened(true)}
                        label="Select Vendor"
                      />
                    </FormLinkWrapper>
                  )}
                </>
              )}
              <Controller
                name="expFor"
                control={control}
                rules={{
                  required: 'Expense For is required',
                }}
                render={({ field }) => (
                  <TextArea
                    label="Expense For"
                    required
                    errors={errors.expFor?.message}
                    disabled={historicalExpense}
                    rows={3}
                    {...field}
                  />
                )}
              />
              <Controller
                name="poNum"
                control={control}
                rules={{
                  required: 'Invoice # is required',
                }}
                render={({ field }) => (
                  <TextInput
                    label="Invoice #"
                    required
                    errors={errors.poNum?.message}
                    disabled={historicalExpense}
                    {...field}
                  />
                )}
              />
              {internalExpense && (
                <>
                  <Controller
                    name="labor"
                    control={control}
                    rules={{
                      validate: (value) => value !== undefined || 'Labor is required',
                    }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Labor"
                        errors={errors.labor?.message}
                        allowNegative 
                        disabled={historicalExpense || editingExpense}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="parts"
                    control={control}
                    rules={{
                      validate: (value) => value !== undefined || 'Parts is required',
                    }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Parts"
                        errors={errors.parts?.message}
                        allowNegative 
                        disabled={historicalExpense || editingExpense}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="other"
                    control={control}
                    rules={{
                      validate: (value) => value !== undefined || 'Other is required',
                    }}
                    render={({ field }) => (
                      <CurrencyInput
                        label="Other"
                        errors={errors.other?.message}
                        allowNegative 
                        disabled={historicalExpense || editingExpense}
                        {...field}
                      />
                    )}
                  />
                </>
              )}
              <Controller
                name="expAmt"
                control={control}
                rules={{
                  validate: (value) => {
                    if (value === undefined || value === null) {
                      return 'Expense Total is required';
                    }
                    return true; 
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    label="Expense Total"
                    errors={errors.expAmt?.message}
                    allowNegative 
                    disabled={historicalExpense || internalExpense || editingExpense}
                    {...field}
                  />
                )}
              />
              {!isDealershipExpense && (
                <Controller
                  name="dealAmt"
                  control={control}
                  rules={{
                    validate: (value) =>
                    value !== undefined || 'Dealership Amount is required',
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      label="Dealership Amt"
                      errors={errors.dealAmt?.message}
                      disabled
                      {...field}
                    />
                  )}
                />
              )}
              {!internalExpense && (
                <Controller
                  name="payVia"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      label="Paid by"
                      suggest
                      data={paidViaOptions}
                      errors={errors.payVia?.message}
                      disabled={historicalExpense || editingExpense}
                      {...field}
                    />
                  )}
                />
              )}
              {payViaCheck && !internalExpense && (
                <Controller
                  name="ckNum"
                  control={control}
                  rules={{
                    required: 'Check # is required',
                  }}
                  render={({ field }) => (
                    <TextInput
                      label="Check #"
                      required
                      errors={errors.ckNum?.message}
                      disabled={historicalExpense}
                      {...field}
                    />
                  )}
                />
              )}
              {!docFlag && (
                <>
                  <FileInput
                    label="Document"
                    onChange={handleUploadedDocument}
                    fileName={docName}
                    errors={errors.docName?.message}
                    accept="application/pdf"
                    disabled={historicalExpense}
                  />
                  <Controller
                    name="docLocalName"
                    control={control}
                    rules={{
                      validate: (docLocalName: string) => {
                        const docName = watch('docName');
                        if (docName && !docLocalName) {
                          return 'Document Description is required when uploading a document';
                        }

                        return undefined;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        required={docName != null && docName !== ''}
                        label="Document Description"
                        errors={errors.docLocalName?.message}
                        disabled={historicalExpense}
                        {...field}
                      />
                    )}
                  />
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  paddingTop: '20px',
                  gap: '10px',
                }}
              >
                {canDeleteDocument && !historicalExpense && (
                  <Button
                    label="Delete Document"
                    secondary
                    onClick={() => setShowDeleteDocumentConfirmationModal(true)}
                    disabled={deletingDocument}
                    loading={deletingDocument}
                    themeColor="error"
                    type="button"
                  />
                )}
                <Button
                  label="Cancel"
                  secondary
                  onClick={resetForm}
                  disabled={!isDirty}
                  type="button"
                />
                <Button
                  label="Submit"
                  loading={loading}
                  secondary={!isDirty}
                  disabled={!isDirty}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <SelectVendorModal
        opened={vendorListModalOpened}
        setOpened={setVendorListModalOpened}
        selectVendor={selectVendor}
      />
      <ConfirmationModal
        opened={showDeleteDocumentConfirmationModal}
        setOpened={setShowDeleteDocumentConfirmationModal}
        confirmationAction={deleteDocument}
        message="Are you sure you want to delete this document?"
      />
    </>
  );
};
