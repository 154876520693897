import { FC, ReactNode, isValidElement } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// components
import DetailViewHeader from "./DetailViewHeader";
import DetailViewBody from "./DetailViewBody";
import BackLink from "../../buttons/BackLink";
import { borderRadiusHeader } from "./style";

/** ###
 *
 * ## Usage:
 * ### Example #1
 * ```tsx
 * const AccountDetailExample1: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout>
 *       <DetailViewHeader>...</DetailViewHeader>
 *       <DetailViewBody isLoading={isLoading}>...</DetailViewBody>
 *     </DetailViewLayout>
 *   );
 * };
 * ```
 * ### Example #2
 * ```tsx
 * const AccountDetailExample2: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout headerComponent={<DetailViewHeader>...</DetailViewHeader>}>
 *       <DetailViewBody isLoading={isLoading}>...</DetailViewBody>
 *     </DetailViewLayout>
 *   );
 * };
 * ```
 * ### Example #3
 * ```tsx
 * const AccountDetailExample3: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewLayout
 *       headerComponent={<DetailViewHeader>...</DetailViewHeader>}
 *       bodyComponent={<DetailViewBody isLoading={isLoading}>...</DetailViewBody>}
 *     />
 *   );
 * };
 * ```
 */
const DetailViewLayout: FC<
  Grid2Props & {
    headerComponent?: ReturnType<typeof DetailViewHeader>;
    bodyComponent?: ReturnType<typeof DetailViewBody>;
    backLink?: boolean | string | ReactNode;
  }
> = ({ headerComponent, bodyComponent, backLink = true, children, sx, ...gridProps }) => {
  if (!children && !headerComponent && !bodyComponent)
    throw new Error(
      "Must provide: 1. `children` element or 2. Both `headerComponent` and `bodyComponent` properties."
    );

  const appliedElems: ReactNode = children ? children : [headerComponent, bodyComponent];
  // @todo If nullish, render an invisible, disabled button to maintain height
  const appliedBackLink =
    backLink === true ? (
      <BackLink />
    ) : typeof backLink === "string" ? (
      <BackLink to={backLink} />
    ) : isValidElement(backLink) ? (
      backLink
    ) : (
      <></>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%", 
        overflow: "hidden",
        px: { xs: "16px", sm: "30px", md: "45px" },
        pb: '32px',
        pt: '0px',
        boxSizing: "border-box",
      }}
    >
      {appliedBackLink}

      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          color: "#111827",
          background: "#fff",
          borderRadius: borderRadiusHeader,
          overflow: "hidden", // Don't allow scrolling at this level
          minWidth: { xs: "min-content", sm: "auto" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden", // Don't allow scrolling at this level either
          }}
        >
          {appliedElems}
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailViewLayout;