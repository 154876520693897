import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useBlocker, useParams } from "react-router-dom";
// state
import { useLienholdersCtx } from "../LienHolderProvider";
// interfaces
import { Lienholders } from "@/interfaces/Lienholders";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
// components
import BackLink from "@/mui/components/buttons/BackLink";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { Button, Loader } from "@/components";
import NavBlock from "@/utils/forms/NavBlock";
// services
import { companyService } from "@/services/companyService";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";
import { useAuthSelector } from "@/features/auth/authSlice";

const CancelButton: FC<{ onClick?: () => void }> = ({ onClick = () => null }) => {
  const { reset } = useLienholdersCtx((s) => s.lienholdersForm);
  const activeLienholder = useLienholdersCtx((s) => s.activeLienholder);

  // Event handlers
  const handleClickCancel = () => {
    reset({ ...activeLienholder } as Lienholders);
    onClick && onClick();
  };

  return <Button type="button" label="Cancel" onClick={handleClickCancel} />;
};

const SubmitButton: FC = () => {
  const { isDirty, isSubmitting } = useLienholdersCtx((s) => s.lienholdersForm.formState);

  return (
    <Button
      type="submit"
      disabled={!isDirty || isSubmitting}
      loading={isSubmitting}
      label={"Submit"}
    />
  );
};

const LienHolderDetail: FC<{ isNew?: boolean; onSubmit?: () => void; onCancel?: () => void }> = ({
  isNew = false,
  onSubmit = () => null,
  onCancel = () => null,
}) => {
  const { orgId, compId, locId } = useAuthSelector((s) => s);
  const setIsConfirmOpen = useLienholdersCtx((s) => s.setIsConfirmOpen);
  const setActiveLienholder = useLienholdersCtx((s) => s.setActiveLienholder);
  const activeLienholder = useLienholdersCtx((s) => s.activeLienholder);
  const lienholders = useLienholdersCtx((s) => s.lienholders);
  const loadLienHolders = useLienholdersCtx((s) => s.loadLienHolders);
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = useLienholdersCtx((s) => s.lienholdersForm);
  const routeParam = useParams().id;
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleSubmitForm = async (form: Lienholders) => {
    try {
      if (form.recId === null) {
        if (!orgId || !locId || !compId) {
          toast.error("Error: CompId, OrgId, and LocId cannot be null.");
          throw new Error("CompId, OrgId, and LocId cannot be null");
        }
        await companyService.addLienholdersSettings({ ...form, orgId, locId, compId });
      } else {
        await companyService.updateLienholdersSettings(form);
      }
      reset(form);
      setActiveLienholder(form);
      toast.success("Success: updating company lienholders settings.");

      onSubmit && onSubmit();
    } catch (error) {
      console.log("Error: ", error);
      toast.error("Error: updating company lienholders settings.");
    } finally {
      handleCloseConfirmationModal();
    }
  };

  useEffect(() => {
    if (isNew) {
      // setActiveLienholder(undefined);
      const currentValues = getValues(); // Get current form values
      const updatedValues: Lienholders = {
        ...currentValues,
        recId: null,
        assCont: null,
        active: null,
        inHouse: null,
        email: null,
        contact: null,
        fax: null,
        phone: null,
        state: null,
        city: null,
        zip: null,
        address: null,
        abrName: null,
        name: null,
        defAprLh: null,
        defaultLh: null,
        discount: null,
        lhDocCode: null,
        lhFedId: null,
        lhId: null,
        resRateLh: null,
        snIntRateLh: null,
        withRecourse: null,
      };
      //set to new state
      reset(updatedValues); // Reset the form with updated values
    } else {
      !lienholders && loadLienHolders.load();
    }
  }, []);

  return (
    <>
      {loadLienHolders.isLoading ? (
        <Loader size="large" />
      ) : (
        <>
          {!isNew && <BackLink onClick={() => isSubmitSuccessful && loadLienHolders.load()} />}
          <Grid
            container
            direction="row"
            flex={1}
            component="header"
            width="100%"
            marginTop={isNew ? 3 : 2}
            marginBottom={isNew ? 0 : 2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{ backgroundColor: BG_HIGHLIGHT }}
          >
            <Grid component="h2" marginTop={0}>
              {isNew ? "Add New Lien Holder" : `Lien Holder - ${activeLienholder?.name ?? ""}`}
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            flex={1}
            marginTop={isNew ? 0 : 2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{
              width: "100%",
              backgroundColor: BG_HIGHLIGHT,
            }}
          >
            <Grid
              container
              direction="column"
              onSubmit={handleSubmit(handleSubmitForm)}
              gap={3}
              flex={1}
              paddingY={2}
              component="form"
            >
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Lien Holder Details
                </Grid>

                <Divider
                  sx={{ alignSelf: "center", flex: 1, maxHeight: "1px", mx: 2, bgcolor: DCS_BLUE }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <LeftContainer />
                <RightContainer />
              </Grid>

              <Grid container direction="row" justifyContent="end" gap={1}>
                <SubmitButton />
                <CancelButton onClick={onCancel} />
              </Grid>
            </Grid>

            {!isNew && <NavBlock blocker={blocker} />}
          </Grid>
        </>
      )}
    </>
  );
};

export default LienHolderDetail;
