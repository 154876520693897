import { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// MUI
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
// Local components
import SectionBody from "@/mui/components/form/Section/SectionBody";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { TextInput } from "@/components/inputs/text/TextInput";
import { PhoneInput } from "@/components";
import { Checkbox } from "@/components/checkbox/Checkbox";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { Button } from "@/components/button/Button";
import { DropdownInput } from "@/components";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
import { getAddressFromGoogleMaps } from "@/utils/helpers/general";
// Interfaces & context
import { InsuranceClaim } from "./interfaces";
import { useAcctInsuranceCtx } from "./AcctInsuranceProvider";
import dayjs from "dayjs";
// Photos Modal
import InsuranceClaimPhotosModal from "./InsuranceClaimPhotosModal";
import InsuranceClaimDocumentsModal from "./InsuranceClaimDocumentsModal";
// Theme colors
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";

interface InsuranceClaimFormProps {
  editingClaim: InsuranceClaim | null;
  onClose: () => void;
}

const InsTypeOptions = [
  "CP",
  "Third Party",
];

// Original options with mapping
const TypeOptionsMapping = [
  { label: "Customer", value: "C" },
  { label: "Repo", value: "R" },
];
// Just the labels for the dropdown
const TypeOptions = ["Customer", "Repo"];

const StatOptions = ["Open", "Closed"];

const InsuranceClaimForm: FC<InsuranceClaimFormProps> = ({ editingClaim, onClose }) => {
  const { addInsuranceClaim, updateInsuranceClaim } = useAcctInsuranceCtx((s) => ({
    addInsuranceClaim: s.addInsuranceClaim,
    updateInsuranceClaim: s.updateInsuranceClaim,
  }));

  // State for photos modal
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);


  // Initialize form with either editingClaim or defaults
  const {
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm<InsuranceClaim>({
    defaultValues: editingClaim ?? {
      recId: 0,
      claimDate: null,
      claimNum: null,
      custIns: null,
      claimStatus: null,
      claimNotes: null,
      curPayoff: null,
      setDate: null,
      setAmt: null,
      insCName: null,
      totalLoss: false,
      cpiClaim: false,
      policeRep: false,
      policeRepSent: false,
      driveable: false,
      logRecv: false,
      acDate: null,
      repoDate: null,
      claimMiles: null,
      insCAddr: null,
      insCCity: null,
      insCState: null,
      insEmail: null,
      insCPerson: null,
      insCFax: null,
      insCZip: null,
      insCPhone: "",
      insPhone: "",
      followUpDate: null,
      guaranteedLetter: false,
      repoClaim: null,
    },
  });

  // Determine if this is a new claim (no recId) or editing an existing one
  const isNewClaim = !editingClaim || editingClaim.recId === 0;

  // Reset form whenever editingClaim changes
  useEffect(() => {
    if (editingClaim) {
      reset(editingClaim);
    } else {
      reset({
        recId: 0,
        claimDate: null,
        claimNum: null,
        custIns: null,
        claimStatus: null,
        claimNotes: null,
        curPayoff: null,
        setDate: null,
        setAmt: null,
        insCName: null,
        totalLoss: false,
        cpiClaim: false,
        policeRep: false,
        policeRepSent: false,
        driveable: false,
        logRecv: false,
        acDate: null,
        repoDate: null,
        claimMiles: null,
        insCAddr: null,
        insCCity: null,
        insCState: null,
        insEmail: null,
        insCPerson: null,
        insCFax: null,
        insCZip: null,
        insCPhone: "",
        insPhone: "",
        followUpDate: null,
        guaranteedLetter: false,
        repoClaim: null,
      });
    }
  }, [editingClaim, reset]);

  // Address autocomplete handler
  const handleAddressPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    setValue("insCAddr", fullAddress.address || "");
    setValue("insCCity", fullAddress.city || "");
    setValue("insCState", fullAddress.state || "");
    setValue("insCZip", fullAddress.zip || "");
  };

  // onSubmit: transform the form data to only include required fields and proper ISO dates.
  const onSubmit = async (data: InsuranceClaim) => {
    // Convert Claim Type from label to value if needed
    if (data.repoClaim === "Customer") {
      data.repoClaim = "C";
    } else if (data.repoClaim === "Repo") {
      data.repoClaim = "R";
    }
    
    // Build a payload that only includes the required fields.
    const payload: Partial<InsuranceClaim> = {
      recId: data.recId,
      claimDate: data.claimDate ? dayjs(data.claimDate, "MM/DD/YYYY").isValid() 
                    ? dayjs(data.claimDate, "MM/DD/YYYY").toISOString()
                    : dayjs(data.claimDate).toISOString()
                  : null,
      claimNum: data.claimNum,
      custIns: data.custIns,
      claimStatus: data.claimStatus,
      setDate: data.setDate ? dayjs(data.setDate, "MM/DD/YYYY").isValid() 
                    ? dayjs(data.setDate, "MM/DD/YYYY").toISOString()
                    : dayjs(data.setDate).toISOString()
                  : null,
      setAmt: data.setAmt,
      insCName: data.insCName,
      totalLoss: data.totalLoss,
      cpiClaim: data.cpiClaim,
      policeRep: data.policeRep,
      policeRepSent: data.policeRepSent,
      driveable: data.driveable,
      logRecv: data.logRecv,
      guaranteedLetter: data.guaranteedLetter,
      followUpDate: data.followUpDate ? dayjs(data.followUpDate, "MM/DD/YYYY").isValid() 
                    ? dayjs(data.followUpDate, "MM/DD/YYYY").toISOString()
                    : dayjs(data.followUpDate).toISOString()
                  : null,
      claimMiles: data.claimMiles,
      curPayoff: data.curPayoff,
      acDate: data.acDate ? dayjs(data.acDate, "MM/DD/YYYY").isValid() 
                    ? dayjs(data.acDate, "MM/DD/YYYY").toISOString()
                    : dayjs(data.acDate).toISOString()
                  : null,
      repoDate: data.repoDate ? dayjs(data.repoDate, "MM/DD/YYYY").isValid() 
                    ? dayjs(data.repoDate, "MM/DD/YYYY").toISOString()
                    : dayjs(data.repoDate).toISOString()
                  : null,
      insCAddr: data.insCAddr,
      insCState: data.insCState,
      insCCity: data.insCCity,
      insCZip: data.insCZip,
      insCPhone: data.insCPhone,
      insCPerson: data.insCPerson,
      insCFax: data.insCFax,
      insPhone: data.insPhone,
      insEmail: data.insEmail,
      claimNotes: data.claimNotes,
      repoClaim: data.repoClaim
    };

    try {
      if (payload.recId && payload.recId !== 0) {
        await updateInsuranceClaim(payload as InsuranceClaim);
      } else {
        await addInsuranceClaim(payload as InsuranceClaim);
      }
      onClose();
    } catch (err) {
      console.error("Error saving insurance claim:", err);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Top Buttons Section with Insurance Type for new claims */}
      <Box sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center",
        mb: 3,
        gap: 1
      }}>
        {/* Insurance Type dropdown only for new claims */}
        {isNewClaim && (
          <Box sx={{ width: "500px" }}>
            <Controller
              name="custIns"
              control={control}
              render={({ field }) => (
                <DropdownInput
                  {...field}
                  value={field.value}
                  data={InsTypeOptions}
                  onChange={(event) => field.onChange(event.value)}
                  label="Insurance Type"
                />
              )}
            />
          </Box>
        )}
        
        {/* Spacer when not a new claim */}
        {!isNewClaim && <Box />}

        {/* Action buttons on the right */}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button 
            label="Photos" 
            themeColor="primary" 
            fullWidth={false} 
            onClick={() => setShowPhotosModal(true)}
            disabled={isNewClaim} 
          />
          {/* New button for scanned docs */}
          <Button
            label="Scanned Documents"
            themeColor="primary"
            fullWidth={false}
            onClick={() => setShowDocumentsModal(true)}
            disabled={isNewClaim}
          />          
          <Button label="Print" themeColor="primary" fullWidth={false} />
        </Box>
      </Box>

      {/* Main Form */}
      <Box
        component="form"
        id="insurance-claim-form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: "100%",
          bgcolor: BG_HIGHLIGHT,
          borderRadius: 2.5,
          p: 4,
          mb: 2,
        }}
      >
        {/* Main Container */}
        <Grid container direction="column" spacing={2}>
          {/* Claim Details Section */}
          <Grid item>
            <Box display="flex" alignItems="center" width="100%" mb={1}>
              <SectionHeader title="Claim Details" />
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
          </Grid>
          
          <Grid item>
            <SectionBody
              gap={3}
              sx={{ p: 0 }}
              leftColElems={
                <SectionColumn p={0}>
                  <Controller
                    name="claimNum"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Claim Number" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="claimStatus"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        {...field}
                        value={field.value}
                        data={StatOptions}
                        onChange={(event) => field.onChange(event.value)}
                        label="Status"
                      />
                    )}
                  />
                  
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
                    <Typography sx={{ 
                      fontFamily: 'inter',
                      lineHeight: '1.4375em',
                      position: 'relative',
                      fontWeight: 700,
                      color: 'rgb(17, 24, 39)',
                      fontSize: '14px',
                      padding: '8px',
                      textWrap: 'nowrap',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      flex: 1
                    }}>
                      Driveable
                    </Typography>
                    <Controller
                      name="driveable"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          label=""
                          checked={field.value}
                          onChange={(checked) => field.onChange(checked)}
                        />
                      )}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
                    <Typography sx={{ 
                      fontFamily: 'inter',
                      lineHeight: '1.4375em',
                      position: 'relative',
                      fontWeight: 700,
                      color: 'rgb(17, 24, 39)',
                      fontSize: '14px',
                      padding: '8px',
                      textWrap: 'nowrap',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      flex: 1
                    }}>
                      Guaranteed Letter
                    </Typography>
                    <Controller
                      name="guaranteedLetter"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          label=""
                          checked={field.value}
                          onChange={(checked) => field.onChange(checked)}
                        />
                      )}
                    />
                  </Box>

                  <Controller
                    name="claimMiles"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Mileage at Time of Accident" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="curPayoff"
                    control={control}
                    render={({ field, fieldState }) => (
                      <CurrencyInput {...field} label="Current Payoff" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="repoClaim"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        {...field}
                        value={field.value}
                        data={TypeOptions}
                        onChange={(event) => field.onChange(event.value)}
                        label="Claim Type"
                      />
                    )}
                  />
                </SectionColumn>
              }
              rightColElems={
                <SectionColumn p={0}>
                  <Controller
                    name="acDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateInput {...field} label="Accident Date" errors={!!fieldState.error} />
                    )}
                  />

                  <Controller
                    name="claimDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateInput {...field} label="Claim Date" errors={!!fieldState.error} />
                    )}
                  />
                  
                  <Controller
                    name="repoDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateInput {...field} label="Repair Date" errors={!!fieldState.error} />
                    )}
                  />

                  <Controller
                    name="setDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateInput {...field} label="Settlement Date" errors={!!fieldState.error} />
                    )}
                  />

                  <Controller
                    name="setAmt"
                    control={control}
                    render={({ field, fieldState }) => (
                      <CurrencyInput {...field} label="Settlement Amount" errors={fieldState.error?.message} />
                    )}
                  />

                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
                    <Typography sx={{ 
                      fontFamily: 'inter',
                      lineHeight: '1.4375em',
                      position: 'relative',
                      fontWeight: 700,
                      color: 'rgb(17, 24, 39)',
                      fontSize: '14px',
                      padding: '8px',
                      textWrap: 'nowrap',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      flex: 1
                    }}>
                      Police Report
                    </Typography>
                    <Controller
                      name="policeRep"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          label=""
                          checked={field.value}
                          onChange={(checked) => field.onChange(checked)}
                        />
                      )}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
                    <Typography sx={{ 
                      fontFamily: 'inter',
                      lineHeight: '1.4375em',
                      position: 'relative',
                      fontWeight: 700,
                      color: 'rgb(17, 24, 39)',
                      fontSize: '14px',
                      padding: '8px',
                      textWrap: 'nowrap',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      flex: 1
                    }}>
                      Gap Claim
                    </Typography>
                    <Controller
                      name="cpiClaim"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          label=""
                          checked={field.value}
                          onChange={(checked) => field.onChange(checked)}
                        />
                      )}
                    />
                  </Box>
                </SectionColumn>
              }
            />
          </Grid>

          {/* Claim Insurance Company Section */}
          <Grid item>
            <Box display="flex" alignItems="center" width="100%" mb={1}>
              <SectionHeader title="Claim Insurance Company" />
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
          </Grid>
          
          <Grid item>
            <SectionBody
              gap={3}
              sx={{ p: 0 }}
              leftColElems={
                <SectionColumn p={0}>
                  <Controller
                    name="insCName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Insurance Company" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="insCPhone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Phone" {...field} />
                    )}
                  />

                  <Controller
                    name="insEmail"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Email" errors={fieldState.error?.message} />
                    )}
                  />
                </SectionColumn>
              }
              rightColElems={
                <SectionColumn p={0}>
                  <Controller
                    name="insCAddr"
                    control={control}
                    render={({ field, fieldState }) => (
                      <AddressAutocomplete
                        label="Address"
                        {...field}
                        onPlaceSelected={handleAddressPlaceChange}
                        fullAddress={field.value || ""}
                        errors={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="insCCity"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="City" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="insCState"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="State" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="insCZip"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Zip" errors={fieldState.error?.message} />
                    )}
                  />
                </SectionColumn>
              }
            />
          </Grid>

          {/* Claim Insurance Direct Contact Section */}
          <Grid item>
            <Box display="flex" alignItems="center" width="100%" mb={1}>
              <SectionHeader title="Claim Insurance Direct Contact" />
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
          </Grid>
          
          <Grid item>
            <SectionBody
              gap={3}
              sx={{ p: 0 }}
              leftColElems={
                <SectionColumn p={0}>
                  <Controller
                    name="insCPerson"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput {...field} label="Contact Name" errors={fieldState.error?.message} />
                    )}
                  />

                  <Controller
                    name="insPhone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Contact Phone" {...field} />
                    )}
                  />
                </SectionColumn>
              }
              rightColElems={
                <SectionColumn p={0}>
          

                  <Controller
                    name="followUpDate"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DateInput {...field} label="Follow Up Date" errors={!!fieldState.error} />
                    )}
                  />
                </SectionColumn>
              }
            />
          </Grid>

          {/* Claim Notes Section */}
          <Grid item>
            <Box display="flex" alignItems="center" width="100%" mb={1}>
              <SectionHeader title="Claim Notes" />
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
          </Grid>
          
          <Grid item>
            <SectionBody
              gap={3}
              sx={{ p: 0 }}
              leftColElems={
                <SectionColumn p={0} sx={{ width: "100%" }}>
                  <Controller
                    name="claimNotes"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextInput 
                        {...field} 
                        label="" 
                        errors={fieldState.error?.message}
                      />
                    )}
                  />
                </SectionColumn>
              }
            />
          </Grid>
        </Grid>
      </Box>
      
      {/* Action Buttons at Form Bottom */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
        <Button 
          type="button" 
          themeColor="primary" 
          label="Cancel" 
          fullWidth={false} 
          onClick={onClose} 
          buttonStyle={{ 
            width: "140px",
            borderRadius: "4px",
          }}
        />
        <Button 
          type="submit"
          form="insurance-claim-form"
          themeColor="primary" 
          label="Submit" 
          fullWidth={false}
          buttonStyle={{ 
            width: "140px",
            borderRadius: "4px",
          }}
        />
      </Box>

      {/* Photos Modal */}
      {!isNewClaim && editingClaim && (
        <InsuranceClaimPhotosModal
          isOpen={showPhotosModal}
          onClose={() => setShowPhotosModal(false)}
          claimRecId={editingClaim.recId}
          refreshClaimDetails={() => {
            // Optional: If needed, add logic to refresh parent component data
          }}
        />
      )}

      {/* Documents Modal (new) */}
      {!isNewClaim && editingClaim && (
        <InsuranceClaimDocumentsModal
          isOpen={showDocumentsModal}
          onClose={() => setShowDocumentsModal(false)}
          claimRecId={editingClaim.recId}
          refreshClaimDetails={() => {
            // Optional: If needed, add logic to refresh parent component data
          }}
        />
      )}
    </Box>
  );
};

export default InsuranceClaimForm;