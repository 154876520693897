import { FC } from 'react';
// utils
import { formatCurrency, formatDate } from '@/utils/helpers/general';
// interfaces
import { LedgerPaymentHistory, LedgerPaymentTotals } from './interfaces';
// style
import { ledgerStyles } from './ledgerStyles';

/** ### @deprecated Needs refactor */
const LedgerCardPaymentHistory: FC<{
  paymentHistory: LedgerPaymentHistory[];
  totals: LedgerPaymentTotals;
  showNewPmts: boolean;
  colType: string;
}> = ({ paymentHistory, totals, showNewPmts = false, colType }) => (
  <>
    <div style={ledgerStyles.sectionHeader} className={'testheader'}>
      Payment History
    </div>
    <table style={ledgerStyles.ledgerTable}>
      <thead>
        <tr style={{ ...ledgerStyles.labelRow, ...ledgerStyles.pmtHistoryRow }}>
          <td style={ledgerStyles.labelRowTd}>Date Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Rec #</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>{colType === "LS" ? 'Lease Dep' : "Prin. Paid"}</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>IRent</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>L.C. Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Misc. Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>NSF Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>CP Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Tax Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Total Paid</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Days Late</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Paid By</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Employee</td>
          <td style={{ ...ledgerStyles.labelRowTd, ...ledgerStyles.pullRight }}>Prin. Balance</td>
        </tr>
      </thead>
      <tbody>
        {paymentHistory
          .filter((x) => (showNewPmts ? true : x.entryType !== 'New Pmt Due'))
          .map((pmt, idx) => {
            return (
              <>
                <tr
                  key={idx}
                  style={pmt.entryType === 'New Pmt Due' ? {} : ledgerStyles.pmtHistoryRow}
                >
                  <td style={ledgerStyles.pmtHistoryCell}>{formatDate(pmt.pmtDate)}</td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {pmt.receiptNumber}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.principalPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.interestPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.lcPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.miscPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.nsfPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.cpiPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.taxPaid || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.totalApplied || 0)}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {pmt.daysLate}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {pmt.entryType.trim() === 'Payment' ? pmt.paidBy : pmt.entryType}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {pmt.takenBy}
                  </td>
                  <td style={{ ...ledgerStyles.pmtHistoryCell, ...ledgerStyles.pullRight }}>
                    {formatCurrency(pmt.principalBalance || 0)}
                  </td>
                </tr>
                {pmt.entryType !== 'New Pmt Due' ? (
                  <tr style={ledgerStyles.pmtHistoryRow} key={`${idx}row2`}>
                    <td style={ledgerStyles.pmtHistoryCell} colSpan={6}>
                      Note: {pmt.payNote}
                    </td>
                    <td style={ledgerStyles.pmtHistoryCell} colSpan={3}>
                      Ref. #: {pmt.paidRef}
                    </td>
                    <td style={ledgerStyles.pmtHistoryCell} colSpan={5}>
                      Last 4: {pmt.last4}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        <tr>
          <td colSpan={2}></td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.principal || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.interest || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.lc || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.misc || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.nsf || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.cp || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.tax || 0)}</strong>
          </td>
          <td style={ledgerStyles.pullRight}>
            <strong>{formatCurrency(totals.total || 0)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default LedgerCardPaymentHistory;
