// insuranceClaimsTableColumns.ts
import React from 'react';
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { AccountDb } from '../interfaces';
import { routeBase } from '../..';
import { CurrencyCell } from '../tableCellComponents/CurrencyCell';

const buildDetailRoute = ({ recId, claimRecId }: AccountDb) => {
  if (!claimRecId) {
    return `/${routeBase}/${recId}/insurance/claims`;
  }
  return `/${routeBase}/${recId}/insurance/claims?claimId=${claimRecId}`;
};

export const insuranceClaimsTableColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('totalled', 'Totalled', 'auto'),   
  newColumnDef('buyers', 'Buyers', 150),         
  newColumnDef('claimStatus', 'Claim Status', 120),
  newColumnDef('claimDate', 'Claim Date', 120, 'date'),
  newColumnDef('insuranceCompany', 'Ins Company', 180),
  newColumnDef('custIns', 'Cust Ins', 140),
  newColumnDef('cpiClaim', 'CPI Claim', 100),           
  newColumnDef('deduct', 'Deduct', 'auto', CurrencyCell),  
  newColumnDef('claimSetAmt', 'Settle Amt.', 100, CurrencyCell),
  newColumnDef('claimSetDate', 'Settle Date', 120,'date'),
  newColumnDef('status', 'Act Status', 100),         
  newColumnDef('code3', 'Lot', 'auto'),
];
