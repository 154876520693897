import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
// components
import { Loader } from '@/components/loader/Loader';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modals/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { Icons } from '@/components/icons';
import { TextInput } from '@/components/inputs/text/TextInput';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { salesService } from '@/services/salesService';
import { systemService } from '@/services/systemService';
// interfaces
import { ScannedDocument } from '@/interfaces';

interface DocumentData {
  url: string;
  title: string;
  recId: number;
}

interface DocumentApiResponse extends ScannedDocument {
  recId: number;
}

interface InsuranceClaimDocumentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  claimRecId: number;
  refreshClaimDetails?: () => void;
}

/** A small list component to display each document. */
const DocumentList: FC<{
  documents: DocumentData[];
  onDelete: (docId: number) => void;
}> = ({ documents, onDelete }) => {
  const [deletingDocId, setDeletingDocId] = useState<number | null>(null);

  const handleDelete = async (docId: number) => {
    setDeletingDocId(docId);
    try {
      await onDelete(docId);
    } finally {
      setDeletingDocId(null);
    }
  };

  if (documents.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          p: 5,
          textAlign: 'center',
          bgcolor: '#f5f5f5',
          borderRadius: 1,
          color: '#666',
          fontSize: 14,
        }}
      >
        No scanned documents found for this claim
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {documents.map((doc) => (
        <Box
          key={doc.recId}
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 1,
            boxShadow: 1,
            bgcolor: '#fff',
          }}
        >
          {/* Document title and optional link to open/download */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box sx={{ fontWeight: 500, fontSize: 14 }}>{doc.title}</Box>
            {/* 
              Example link to open in a new tab 
              (disable if you want a different approach, e.g., custom viewer)
            */}
            <Box 
              component="a" 
              href={doc.url} 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ textDecoration: 'underline', fontSize: 12 }}
            >
              View / Download
            </Box>
          </Box>

          {/* Delete icon or loader */}
          {deletingDocId === doc.recId ? (
            <Loader size="small" />
          ) : (
            <Box sx={{ cursor: 'pointer' }} onClick={() => handleDelete(doc.recId)}>
              <Icons.X style={{ color: 'red' }} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const InsuranceClaimDocumentsModal: FC<InsuranceClaimDocumentsModalProps> = ({
  isOpen,
  onClose,
  claimRecId,
  refreshClaimDetails,
}) => {
  const { orgId, compId, locId } = useAuthSelector((s) => s);

  // Local state for listing documents
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const [loading, setLoading] = useState(false);

  // Local state for uploading a new doc
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [docFile, setDocFile] = useState<File | null>(null);
  const [docFileDesc, setDocFileDesc] = useState('');
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescError, setFileDescError] = useState(false);

  /** Fetch read-URLs from storage so that you can download/view the doc. */
  const fetchDocs = async (docList: ScannedDocument[], orgId: number) => {
    if (!docList.length) return [];

    const docsObject = docList.map((file) => ({
      orgId,
      containerName: 'afsdealers',
      blobName: file.fileNameCloud,
    }));

    const docInfoList = await systemService.getBlobReadUrls(docsObject);
    return docInfoList; // Array of { url: string }
  };

  /** Load scanned documents for this claim (non-image files). */
  const loadDocuments = async () => {
    if (!claimRecId || claimRecId < 1) return;
    setLoading(true);
    try {
      const res = (await salesService.getScannedDocsByInsuranceClaim(
        orgId!,
        claimRecId
      )) as DocumentApiResponse[];

      if (res && res.length > 0) {
        const docInfoList = await fetchDocs(res, orgId!);
        const finalDocs = docInfoList.map((docInfo, index) => {
          const scannedDoc = res[index];
          return {
            url: docInfo.url,
            title: scannedDoc?.fileDesc || scannedDoc?.fileCategory || 'Scanned Document',
            recId: scannedDoc?.recId || 0,
          };
        });
        setDocuments(finalDocs);
      } else {
        setDocuments([]);
      }
    } catch (error) {
      console.error('Error loading scanned documents:', error);
    } finally {
      setLoading(false);
    }
  };

  /** Upload a new scanned doc. Similar to photo upload logic. */
  const uploadDocument = async () => {
    if (!docFile) {
      setFileInputError(true);
      return;
    }
    if (!docFileDesc) {
      setFileDescError(true);
      return;
    }

    const payload = {
      file: docFile,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: 0,
      vehRecId: 0,
      filePK: claimRecId,
      fileDesc: docFileDesc,
      fileCategory: 'Insurance Claim Document',
    };

    setUploadingDoc(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadDocuments();
      if (refreshClaimDetails) refreshClaimDetails();

      // Reset form, close modal
      setDocFile(null);
      setDocFileDesc('');
      setUploadModalOpen(false);
    } catch (err) {
      console.error('Error uploading document:', err);
    } finally {
      setUploadingDoc(false);
    }
  };

  /** Delete a scanned document by recId. */
  const deleteDocument = async (docId: number) => {
    try {
      await salesService.deleteScannedDoc(orgId!, docId);
      await loadDocuments();
      if (refreshClaimDetails) refreshClaimDetails();
    } catch (error) {
      console.error('Error deleting scanned doc:', error);
    }
  };

  const handleDocFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setDocFile(file);
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescError(false);
    setDocFileDesc(e.target.value);
  };

  useEffect(() => {
    if (isOpen && claimRecId) {
      loadDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, claimRecId, orgId]);

  return (
    <Modal
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      closeButton
      title="Insurance Claim Scanned Documents"
      centerModal
      panelStyle={{ width: 800, height: '80vh', maxHeight: '80vh' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Top bar with Upload button */}
        <Box sx={{ display: 'flex' }}>
          <Button
            label="Upload Document"
            onClick={() => setUploadModalOpen(true)}
            themeColor="primary"
          />
        </Box>

        <Spacer size={20} />

        {/* Main content area */}
        <Box sx={{ flex: 1, overflow: 'auto', px: 1 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
              <Loader size="large" />
            </Box>
          ) : (
            <DocumentList documents={documents} onDelete={deleteDocument} />
          )}
        </Box>
      </Box>

      {/* Upload modal, very similar to the photos upload */}
      {uploadModalOpen && (
        <Modal
          isOpen
          onCloseButtonClick={() => setUploadModalOpen(false)}
          closeButton
          centerModal
          title="Upload Document"
          panelStyle={{ width: 600 }}
        >
          <Box>
            <Spacer size={5} />
            <FileInput
              onChange={handleDocFileChange}
              fileName={docFile?.name}
              errors={fileInputError}
              accept="*/*" // or more specific if you want only PDF, docx, etc.
            />
            <Spacer size={20} />
            <TextInput
              required
              label="Document Description"
              value={docFileDesc}
              horizontalLabel={false}
              onChange={handleDescChange}
              errors={fileDescError}
            />
            <Spacer size={20} />
            <Box sx={{ width: 100, marginLeft: 'auto' }}>
              <Button
                label="Submit"
                loading={uploadingDoc}
                onClick={uploadDocument}
              />
            </Box>
          </Box>
        </Modal>
      )}
    </Modal>
  );
};

export default InsuranceClaimDocumentsModal;
