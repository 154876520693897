import { FC } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom';
// components
import AuthenticatedContainer from '@/components/authenticatedContainer/AuthenticatedContainer';
import Login from '@/features/RootView/Login';
import { Admin } from '@/features/admin/Admin';
import Reports from '@/features/Reports';
import Sales from '@/features/Sales';
import Settings from '@/features/Settings';
import AccountsRouter from '@/features/Accounts';
import CollectionQueuesRouter from '@/features/collections/CollectionQueuesRouter';
import InventoryContainer from '@/features/inventory/InventoryContainer';
import Dashboard from '@/features/Dashboard';
// state
import AppProviders from '@/AppProviders';
import AuthProvider from '@/AppProviders/AuthProvider';
// style
import 'react-toastify/dist/ReactToastify.css';
import { useAuthSelector } from '@/features/auth/authSlice';
import { isStandalone } from './env';

/** @todo implement according to the documentation:
 * - Move `router` const out of the component render
 * - Use `path`, `Component` and `children` properties of `createHashRouter` accurately
 *
 * ### Additional notes
 *
 * `create*Router` has to be used instead of just wrapping your routes in a <*Router>
 * This is because this gives you a "data router", and we use react-router APIs (maybe just useBlocker?)
 * that only work in a data router
 * https://reactrouter.com/en/main/routers/picking-a-router#data-apis
 *
 * Consider moving all other blocks of <Route>s (e.g. AccountsRouter, etc) into this one router definition
 * Having them all in one place would help give a clearer picture of all navigation in the app
 */

export const createDefaultRoute = () => {
  const { permissions, userId, hasAfsLevel } = useAuthSelector((s) => s);
  if (!userId) return "/"; //Has not yet loaded UserInfo
  if (!isStandalone) return "/accounts";
  if (hasAfsLevel || permissions?.includes("SuperAdmin")) return "/accounts"; //SuperAdmin needs no further verification

  if (permissions?.includes("Accounts")) return "/accounts";
  if (permissions?.includes("Collections")) return "/collections";
  if (permissions?.includes("Reports")) return "/reports";
  if (permissions?.includes("Sales")) return "/sales";
  if (permissions?.includes("Inventory")) return "/inventory";
  if (permissions?.includes("RTD")) return "/dashboard";
  return "/"; //Default landing route
};

const AppRouter: FC = () => {
  const defaultRoute = createDefaultRoute();

  const router = createHashRouter([
    {
      children: createRoutesFromElements(
        <>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/*" element={<Navigate to="/auth/login" replace={true} />} />

          <Route
            path="*"
            element={
              <AppProviders>
                <AuthenticatedContainer />
              </AppProviders>
            }
          >
            <Route path="accounts/*" element={<AccountsRouter />} />
            <Route path="admin/*" element={<Admin />} />
            <Route path="collections/*" element={<CollectionQueuesRouter />} />
            <Route path="reports/*" element={<Reports />} />
            <Route path="sales/*" element={<Sales />} />
            <Route path="inventory/*" element={<InventoryContainer />} />
            <Route path="settings/*" element={<Settings />} />
            <Route path="dashboard/*" element={<Dashboard />} />
            <Route path="" element={<Navigate to={defaultRoute} replace={true} />} />
            <Route path="*" element={<Navigate to={defaultRoute} replace={true} />} />
          </Route>
        </>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

const Router: FC = () => (
  <AuthProvider>
    <AppRouter />
  </AuthProvider>
);

export default Router;
