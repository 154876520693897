import { FC } from "react";
import { Controller } from "react-hook-form";
// components
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TextInput, Checkbox } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { LeasingRateForm } from "@/interfaces/LeaseRate";
import { useLeasingRatesCtx } from "../LeaseRateProvider";

// Unified Styles for Labels
const gridLabelStyle = {
  fontSize: "15px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  fontStyle: "italic",
  paddingBottom: "8px",
  minHeight: "38px", // Makes all label rows uniform in height
};

const TextareaStyle = {
  width: "100%",
  minHeight: "100px",
  padding: "8px",
  border: "1px solid #ccc",
  borderRadius: "4px",
  resize: "vertical" as const
};

const NotesSection: FC = () => {
  const { control } = useLeasingRatesCtx(s => s.leasingRateForm);

  return (
    <Grid container direction="column" flex={1} gap={2}>
      <Grid container spacing={2}>
        <Grid xs={6}>
          <Grid sx={gridLabelStyle}>Header for Used Car Rate Sheet</Grid>
          <Controller
            name="usedHeader"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Enter header text for Used Car Rate Sheet"
              />
            )}
          />
        </Grid>
        <Grid xs={6}>
          <Grid sx={gridLabelStyle}>Header for New Car Rate Sheet</Grid>
          <Controller
            name="newHeader"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                placeholder="Enter header text for New Car Rate Sheet"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={4}>
          <Grid sx={gridLabelStyle}>Notes for Fees</Grid>
          <Controller
            name="notesFees"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                style={TextareaStyle}
                placeholder="Enter notes about fees"
              />
            )}
          />
        </Grid>
        <Grid xs={4}>
          <Grid sx={gridLabelStyle}>General Notes</Grid>
          <Controller
            name="notesGeneral"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                style={TextareaStyle}
                placeholder="Enter general notes"
              />
            )}
          />
        </Grid>
        <Grid xs={4}>
          <Grid sx={gridLabelStyle}>Notes for Due at Inception</Grid>
          <Controller
            name="notesDueInception"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                style={TextareaStyle}
                placeholder="Enter notes about what's due at inception"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid xs={4}>
          <Grid sx={gridLabelStyle}>Guaranteed Buyout</Grid>
          <Controller
            name="guaranteedBuif"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                containerStyles={{
                  height: "36px",
                  display: "flex",
                  alignItems: "center"
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotesSection;