import { FC, useEffect } from "react";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import Grid from '@mui/material/Unstable_Grid2';
// interfaces
// style
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useRequestRewriteCtx } from "./RequestRewriteProvider";
import { RequestForRewriteRes } from "@/interfaces/RequestRewrite";
import LinkButton from "@/mui/components/buttons/LinkButton";
import { getRowId } from "@/mui/components/dataTable/utils";
import { initPage, tableStyle } from "@/features/Settings/default";
import { useNavigate } from "react-router-dom";
import { currencyCellFmt, dateCellDefaultFmt } from "@/mui/components/dataTable/cellFormatUtils";

const AddNewButton: FC = () => {
  const setAddingNew = useRequestRewriteCtx((s) => s.setAddingNewRequestRewrite);

  return (
    <LinkButton to="new" onClick={() => setAddingNew(true)}>Add New +</LinkButton>
  );
};

const RequestForRewritesTable: FC = () => {
  const navigate = useNavigate();
  const rows = useRequestRewriteCtx((s) => s.requestRewritesRows);
  const setSelectedRow = useRequestRewriteCtx((s) => s.setSelectedRequestRewrite);
  const isLoading = useRequestRewriteCtx((s) => s.requestRewrites.isLoading);
  const setAddingNew = useRequestRewriteCtx((s) => s.setAddingNewRequestRewrite);  

  const handleRowClick = (requestRewrite: RequestForRewriteRes) => {
    setSelectedRow(requestRewrite);
    setAddingNew(false);
    navigate(`${requestRewrite.recId}`);
  };

  const columns: GridColDef[] = [
    { field: "accountNumber", headerName: "Account #", flex: 1 },
    { field: "requestedDate", headerName: "Request Date", flex: 1, valueFormatter: dateCellDefaultFmt },
    { field: "pastDue", headerName: "Past Due Amount", flex: 1, valueFormatter: currencyCellFmt },
    { field: "startDate", headerName: "Start Date", flex: 1, valueFormatter: dateCellDefaultFmt },
    { field: "requestedBy", headerName: "Requested By", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <div>{params.value === "0" ? "Pending" : params.value === "1" ? "Declined" : "Approved"}</div>
      },
    }
  ];

  const navTitle = `Request for Rewrites (${isLoading ? '-' : rows.length})`;

  useEffect(() => {
    setAddingNew(false);
  }, []);

  return (
    <AccountsMainPanel navBarTitle={navTitle}
      headerRight={
        <Grid container direction="row" flex={1} alignItems="center" justifyContent="flex-end"> 
          <AddNewButton /> 
        </Grid>
      }
      containerStyle={{ overflow: 'hidden' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationModel={initPage}
        pagination
        autoHeight
        getRowId={getRowId("recId")}
        sx={tableStyle}
        loading={isLoading}
        onRowClick={(params) => handleRowClick(params.row)}
      />
    </AccountsMainPanel>
  );
};

export default RequestForRewritesTable;
