import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridToolbar,
  Loader,
} from '@progress/kendo-react-all';
import { Button } from '@/components';
// components
import TextSearchFilter from '@/components/table/TextSearchFilter';
// state
import { useTableCtx } from './tableState';
import { useAccountsViewCtx } from './accountsViewState';
// utils
import { defaultPageState, handlePageChange, handleSortChange } from '@/interfaces/tableState';
import { GridColumnProps } from '@progress/kendo-react-all';
interface AccountsViewTableProps {
  rows: any[];
  searchFilterPlaceholder?: string;
  disableSearchFilter?: boolean;
  filterGroupComponent?: JSX.Element;
  buttonComponent?: JSX.Element;
  columns?: GridColumnProps[];
}
const AccountsViewTable: FC<AccountsViewTableProps> = ({
  rows,
  searchFilterPlaceholder = 'Search filter',
  disableSearchFilter = false,
  filterGroupComponent,
  buttonComponent,
  columns,
}) => {
  const isLoading = useAccountsViewCtx((s) => s.isLoading);
  const dbCt = useAccountsViewCtx((s) => s.dbCt);
  const contextColumns = useAccountsViewCtx((s) => s.subviewInfo.columns);
  const finalColumns = columns || contextColumns;
  // Table state
  const { sort, setSort, page, setPage, pageSizeValue, setPageSizeValue, ...tableState } =
    useTableCtx((s) => s);

  const tableColumnElems = finalColumns.map((column, idx) => (
      <GridColumn
        field={column.field}
        title={column.title}
        key={`tcol-${idx}]${column.field}`}
        width={column.width}
        cell={column.cell}
        sortable={column.sortable}
      />
    ));

  return (
    <KendoGrid
      data={isLoading ? [] : rows}
      sortable={{ mode: 'single' }}
      sort={sort}
      onSortChange={handleSortChange(setSort)}
      skip={page.skip}
      take={page.take}
      total={dbCt}
      pageable={{ ...defaultPageState, pageSizeValue }}
      onPageChange={handlePageChange(setPageSizeValue, setPage)}
    >
      <GridToolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          flex={1}
        >
          <Grid container direction="row" gap={1} alignItems="center">
            <TextSearchFilter
              tableState={tableState}
              searchFilterPlaceholder={searchFilterPlaceholder}
              disableSearchFilter={disableSearchFilter}
            />
            {filterGroupComponent}
          </Grid>
          {/* @note temporary - kendo header adjusts height when button is not rendered- inconsistent height when switching to non-account tables */}
          {buttonComponent || <Button label="-" style={{ opacity: 0 }} disabled />}
        </Grid>
      </GridToolbar>
      <GridNoRecords>{isLoading ? <Loader size="large" /> : 'No accounts found'}</GridNoRecords>
      {tableColumnElems}
    </KendoGrid>
  );
};

export default AccountsViewTable;
