import { FC } from 'react';
// mui
import Section from '@/mui/components/form/Section';
import SectionHeader from '@/mui/components/form/Section/SectionHeader';
import SectionBody from '@/mui/components/form/Section/SectionBody';
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';

/** ###  */
const FinancialInfoSection: FC = () => {
  const selectedPmt = usePmtReversalDetailCtx((s) => s.selectedPmt);

  return (
    <Section>
      <SectionHeader label="Financial Information" isExpandable={false} />

      <SectionBody
        leftColElems={[
          <CurrencyInput value={selectedPmt?.prinPaid} disabled label="Principal" key={1} />,
          <CurrencyInput value={selectedPmt?.intPaid} disabled label="Interest" key={2} />,
          <CurrencyInput value={selectedPmt?.cpiPaid} disabled label="CPI Paid" key={3} />,
        ]}
        rightColElems={[
          <CurrencyInput value={selectedPmt?.nsfPaid} disabled label="NSF/ACH Fee" key={1} />,
          <CurrencyInput value={selectedPmt?.taxPaid} disabled label="Tax Paid" key={2} />,
          <CurrencyInput value={selectedPmt?.totalPaid} disabled label="Total Paid" key={3} />,
        ]}
      />
    </Section>
  );
};

export default FinancialInfoSection;
