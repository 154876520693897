import { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import Button from "@/mui/components/buttons/MuiKendoButton";
import { DCS_BLUE } from "@/mui/theme/colors";
// components
import WsPaymentsHeaderButtonGroup from "./WsPaymentsView/WsPaymentsHeaderButtonGroup";
// state
import { useAppDispatch } from "@/store/store";
import { useSalesViewCtx } from "../../SalesViewProvider";
import { useSalesDetailCtx } from "../SalesDetailProvider";
import { useWholesaleViewCtx } from "./WholesaleViewProvider";
import { salesActions } from "../../salesSlice";
import { getAvailableVehicles } from "@/features/Sales/salesActionCreator";
// utils
import { salesService } from "@/services/salesService";
import { WholesaleFormTab, tabMap } from "./default";
import { useAuthSelector } from "@/features/auth/authSlice";

// @todo move to separate file
const WsFormHeaderButtonGroup: FC = () => {
  const loc = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { compId } = useAuthSelector((s) => s);

  const setIsSubmitting = useSalesViewCtx((s) => s.setIsLoading);
  const isSubmitting = useSalesViewCtx((s) => s.isLoading);
  const appRecId = useSalesDetailCtx((s) => s.appRecId);

  const navTab = WholesaleFormTab.safeParse(useParams()["*"]).data ?? WholesaleFormTab.options[0];
  const currentTab = tabMap.get(navTab);
  const wsForm = useWholesaleViewCtx((s) => s.wsFormDeprec);
  const wsFormVehicle = useWholesaleViewCtx((s) => s.wsFormVehicle);
  const wholesale = useWholesaleViewCtx((s) => s.wholesale);
  const setWsFormFieldError = useWholesaleViewCtx((s) => s.setWsFormFieldError);

  // Event handlers
  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (!wsForm.salesPrice || parseFloat(wsForm.salesPrice) <= 0) {
      setWsFormFieldError("salesPrice", "Invalid sale price. Must be greater than $0.00");
      setIsSubmitting(false);
      return;
    }

    if (!wsForm.milesOut) {
      setWsFormFieldError("milesOut", "Miles Out must be a number.");
      setIsSubmitting(false);
      return;
    }

    if (wsFormVehicle?.milesIn && parseFloat(wsForm.milesOut) < parseFloat(wsFormVehicle.milesIn)) {
      setWsFormFieldError("milesOut", "Miles Out must be greater than Miles In.");
      setIsSubmitting(false);
      return;
    }

    try {
      if (wsForm.saleStatus === "Pending") {
        if (wsForm === null) return;
        // need the wholesale comp Id
        const availableVehicles = await dispatch(getAvailableVehicles(compId!));
        const vehRecIds = availableVehicles.map((vehicle) => vehicle.recId);
        if (!vehRecIds.includes(wsForm.vehRecId!)) {
          toast.error("The vehicle is no longer available. Please select a new vehicle.");
          setIsSubmitting(false);
          return;
        }
        const res = await salesService.postWholesale(wsForm.toReq());
        if (!appRecId) {
          res?.appRecId && navigate(`../${res.appRecId}`, { relative: "path", replace: true });
        } else {
          wholesale.load();
        }
      } else if (wsForm.saleStatus === "Posted") {
        // Reset just the sale-status and sale-date variables, rather than resetting all fields
        wsForm.appRecId && (await salesService.unpostWholesale(wsForm.appRecId));
        wholesale.load();
      }

      dispatch(salesActions.setSelectedVehicleId(wsFormVehicle?.recId));
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  let buttonLabel = "Submit form";
  if (isSubmitting) buttonLabel = "Submitting";
  else if (!appRecId || wsForm?.saleStatus === "Pending") buttonLabel = "Post Wholesale";
  else if (!!appRecId && wsForm?.saleStatus === "Posted") buttonLabel = "Unpost Wholesale";

  const isWsPaymentsRoute = loc.pathname.includes("wholesale-management/payments");
  const isWsMgmtTab = currentTab?.value === "wholesale-management" && !isWsPaymentsRoute;
  const isPostButtonDisabled = !isWsMgmtTab || isSubmitting;
  const isPaymentButtonDisabled = wsForm?.saleStatus !== "Posted" || isSubmitting || !isWsMgmtTab;

  return (
    <Grid container direction="row" sx={{ opacity: !isWsMgmtTab ? 0 : undefined }} gap={3.5}>
      <Button
        onClick={handleSubmit}
        disabled={isPostButtonDisabled}
        sx={{ px: 6, backgroundColor: DCS_BLUE }}
      >
        {buttonLabel}
      </Button>
      <Button
        disabled={isPaymentButtonDisabled}
        sx={{ px: 6, backgroundColor: DCS_BLUE }}
        onClick={() => navigate("wholesale-management/payments")}
      >
        Payment
      </Button>
    </Grid>
  );
};

/** ### Renders title (current tab) and submit button */
const WsFormHeader: FC = () => {
  const loc = useLocation();
  const navTab = WholesaleFormTab.safeParse(useParams()["*"]).data ?? WholesaleFormTab.options[0];
  const currentTab = tabMap.get(navTab)!;

  // @note payment-route-specific logic will be cleaned up in a future ticket
  const isWsPaymentsRoute = loc.pathname.includes("wholesale-management/payments");
  const headerTitle = !isWsPaymentsRoute ? currentTab.label : "Payments";

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      m={2}
      borderRadius="10px"
      wrap="nowrap"
      sx={{ backgroundColor: "rgb(239, 240, 244)" }}
    >
      <Typography
        noWrap
        flexWrap="nowrap"
        lineHeight="22px"
        sx={{ textWrap: "nowrap", textOverflow: "ellipsis", fontSize: 20, fontWeight: 700 }}
      >
        {headerTitle}
      </Typography>

      {/* @note payment-route-specific logic will be cleaned up in a future ticket */}
      {!isWsPaymentsRoute ? <WsFormHeaderButtonGroup /> : <WsPaymentsHeaderButtonGroup />}
    </Grid>
  );
};

export default WsFormHeader;
