import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { useBlocker, useNavigate } from 'react-router-dom';
// state
import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";
// interfaces
import { RepoCompany } from "@/interfaces/RepoCompany";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
// components
import BackLink from "@/mui/components/buttons/BackLink";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { Button, Loader } from "@/components";
import { TextArea } from '@/components/inputs/textarea/TextArea';
import { Controller } from "react-hook-form";
import NavBlock from '@/utils/forms/NavBlock';
// services
import { companyService } from "@/services/companyService";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";

const CancelButton: FC<{onClick?: () => void}> = ({ onClick = () => null }) => {
  const { reset } = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);
  const activeRepoCompany = useRepoCompanyProviderCtx((s) => s.activeRepoCompany);

  // Event handlers
  const handleClickCancel = () => {
    reset({ ...activeRepoCompany } as RepoCompany);
    onClick && onClick();
  };

  return (
    <Button type="button" label="Cancel" onClick={handleClickCancel} />
  );
};

const SubmitButton: FC = () => {
  const { isDirty, isSubmitting } = useRepoCompanyProviderCtx(
    (s) => s.repoCompanyDetailForm.formState
  );

  return (
    <Button
      type="submit"
      disabled={!isDirty || isSubmitting}
      loading={isSubmitting}
      label={"Submit"}
    />
  );
};

const RepoCompanyDetail: FC<{isNew?: boolean, onSubmit?: () => void, onCancel?: () => void}> = ({ isNew = false, onSubmit = () => null, onCancel = () => null }) => {
  const setActiveRepoCompany = useRepoCompanyProviderCtx((s) => s.setActiveRepoCompany);
  const activeRepoCompany = useRepoCompanyProviderCtx((s) => s.activeRepoCompany);
  const repoCompanies = useRepoCompanyProviderCtx((s) => s.repoCompanies);
  const loadRepoCompanies = useRepoCompanyProviderCtx((s) => s.loadRepoCompanies);
  const resetToDefault = useRepoCompanyProviderCtx((s) => s.resetToDefault);
  const { handleSubmit, control, reset, formState: { isDirty, isValid, isSubmitSuccessful }} = useRepoCompanyProviderCtx((s) => s.repoCompanyDetailForm);  
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });
  
  const handleSubmitForm = async (form: RepoCompany) => {
    try {
      const res = await companyService.upsertRepoCompany(form);

      if (form.recId) {
        reset(form);
        setActiveRepoCompany(form);
        toast.success("Repo Company updated successfully");
      }
      
      onSubmit && onSubmit();
    } catch (error) {
      toast.error("There was an issue saving the repo company")
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (isNew) {
      setActiveRepoCompany(undefined);
      resetToDefault();
    } else {
      !repoCompanies && loadRepoCompanies.load();
    }
  }, [])

  return (
    <>
      {loadRepoCompanies.isLoading 
        ? <Loader size="large"/> 
        : <>
          { !isNew && <BackLink onClick={() => isSubmitSuccessful && loadRepoCompanies.load()} /> }
          <Grid 
            container
            direction="row"
            flex={1}
            component="header"
            width="100%"
            marginTop={isNew ? 3 : 2}
            marginBottom={isNew ? 0 : 2}
            py={2}
            px={4}
            borderRadius={2.5} 
            sx={{ backgroundColor: BG_HIGHLIGHT }}>
            <Grid component="h2" marginTop={0}>
              {isNew ? "Add New Repo Company" : `Repo Company - ${activeRepoCompany?.company ?? ""}`}
            </Grid>
          </Grid>

          <Grid 
            container 
            direction="column" 
            flex={1} 
            marginTop={isNew ? 0 : 2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{
              width: "100%",
              backgroundColor: BG_HIGHLIGHT
            }}
          >
            <Grid
              container
              direction="column"
              onSubmit={handleSubmit(handleSubmitForm)}
              gap={3}
              flex={1}
              paddingY={2}
              component="form"              
            >
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Repo Company Details
                </Grid>

                <Divider
                  sx={{ alignSelf: 'center', flex: 1, maxHeight: '1px', mx: 2, bgcolor: DCS_BLUE }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <LeftContainer />
                <RightContainer />         
              </Grid>

              <Grid container direction="column" gap={1} paddingX={2}>
                <Controller
                  name="notes"
                  control={control}
                render={({ field }) => <TextArea label="Notes" rows={3} {...field} value={field.value ?? ''}/>}
                />
              </Grid>

              <Grid container direction="row" justifyContent="end" gap={1}>
                <SubmitButton />
                <CancelButton onClick={onCancel} />
              </Grid>
            </Grid>

            {!isNew && <NavBlock blocker={blocker} />}
          </Grid>
        </>
      }
    </>
  );
};

export default RepoCompanyDetail;
