import { FC } from "react";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import LgLoader from "@/components/loader/LgLoader";

/** ###
 *
 * ## Usage:
 * ```tsx
 * const DetailViewBodyExample: FC = () => {
 *   const isLoading = useAccountDetailCtx((s) => s.isLoading);
 *
 *   return (
 *     <DetailViewBody direction="row" isLoading={isLoading}>
 *       <Grid>Left Sidebar</Grid>
 *       <Grid>Body Content</Grid>
 *     </DetailViewBody>
 *   );
 * };
 * ```
 */
const DetailViewBody: FC<Grid2Props & { isLoading?: boolean }> = ({
  isLoading = false,
  // MUI Grid props
  children,
  sx,
  ...gridProps
}) => {
  if (isLoading) return <LgLoader />;
  
  // Keep this much closer to the original implementation
  return (
    <Grid 
      container 
      direction="column" 
      wrap="nowrap" 
      sx={{ 
        flex: 1,
        // We only need this one overflow property to prevent the container from scrolling
        overflow: "hidden",
        ...sx 
      }} 
      {...gridProps}
    >
      {children}
    </Grid>
  );
};

export default DetailViewBody;