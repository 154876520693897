import React from 'react';
// components
// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';
import { AccountTabRoutes } from '../AccountDetail/interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}/${AccountTabRoutes.RequestRewrite}`;

export const requestRewriteColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('requestRewriteDate', 'Date Req', 120, 'date'),
  newColumnDef('reqRewriteRequestedBy', 'Req By', 120),
  newColumnDef('reqRewriteStatus', 'Req Status', 120),
  newColumnDef('requestRewritePreviousDate', 'Last Rewrite', 120, 'date'),
  newColumnDef('code3', 'Company', 80),
];
