import { z } from "zod";
// validation
import { ZMpd } from "./paymentSchemas";
// utils
import { UsaStateCode } from "@/general/regions";
import { calcCarPmtEffect } from "./formCalculations/fieldEffects";
// interfaces
import {
  AccountType,
  TransactionType,
  ZCardProcessorName,
  PmtPaidIn,
  PaymentMethod,
  cardProcessorMap,
} from "@/enums/payment";
import { SavedPmtMethod } from "@/interfaces/CreditCard";
import {
  IPaymentProviderConfigRes,
  PaymentDataRes,
  PaymentProviderConfigRes,
} from "@/interfaces/payment";
import { Nullish } from "@/interfaces/utilityTypes";

const conditionalFields = z.object({
  cpiPaid: z.number().catch(0),
  ddPmt: z.number().catch(0),
  lcPaid: z.number().catch(0),
  lcWaived: z.number().catch(0),
  mpd: ZMpd.nullable().catch(null),
  nsfPaid: z.number().catch(0),
  sendB: z.boolean().catch(false),
  sendC: z.boolean().catch(false),
  totalPayment: z.number().catch(0),
  transactionType: TransactionType.catch("Payment"),
  waiveConvFee: z.boolean().catch(false),

  /** saveCardFields: @todo rename to `shouldSavePmtMethod` */
  saveCard: z.boolean().catch(true),

  // @note use conditional: `isNOTSavedPayment`
  billAddress: z.string().min(1).nullable().default(null),
  billCity: z.string().min(1).nullable().default(null),
  billState: UsaStateCode.nullable().default(null),
  billZip: z.string().min(1).nullable().default(null),
  billFirstName: z.string().min(1).nullable().default(null),
  billLastName: z.string().min(1).nullable().default(null),
  billEmail: z.string().email().nullable().default(null),

  // @note use conditional: `isSavedPayment`
  mpdRecId: z.number().nullable().default(null),

  /** unsavedAchBillingFields @todo backend expects a number. but form fields work with strings. convert on backend */
  achAcctType: AccountType.catch("Checking"),

  // Specific to Misc. Category form - from endpoint: `/Payment/MiscCategories`
  mCat: z.string().nullish().default(null),
  payToFrom: z.string().nullish().default(null),
  miscPaid: z.number().nullable().catch(null),
  carPmt: z.number().catch(0), // hidden for down payments
});

const unconditionalFields = z.object({
  /** @todo rename to `pmtAcceptedIn` */
  paidIn: PmtPaidIn.default("In-Person"),
  paidBy: PaymentMethod.default("Cash"),

  /** @todo complete. register on: (!isCC && !isACH) ; MIN: isPrincipalOnly ? val>CarPmt : val<totalPayment - @note conditionally a calculated field */
  totalReceived: z.number().catch(0),

  takenByPassword: z.string().min(1, "Invalid password").default(""),
  paidRef: z.string().nullable().default(""),
  payNote: z.string().nullable().default(""),
  useSavedPaymentMethod: z.boolean().default(false),
  employeeRecId: z.number(),
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'paymentProcessor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  provider: z.preprocess((field) => {
    // Check if variant of applied enum
    if (
      typeof field === "string" &&
      field.trim().toLowerCase() === ZCardProcessorName.enum.REPAY.toLowerCase()
    ) {
      return ZCardProcessorName.enum.REPAY;
    }
    // Payment provider may be provided as an int - it's represented in the database as an `int`
    else if (typeof field === "number") {
      return cardProcessorMap.get(field);
    }

    return field;
  }, ZCardProcessorName.default("REPAY")),
});

export const ExternalFields = z.object({
  achConvenienceFee: z.number().nullable().catch(null),
  buyerEmail: z.string().nullable().catch(null),
  buyerNoEmail: z.boolean().catch(false),
  canWaiveFee: z.boolean().catch(false),
  cobuyerEmail: z.string().nullable().catch(null),
  cobuyerNoEmail: z.boolean().catch(false),
  colType: PaymentDataRes.shape.colType,
  convenienceFee: z.number().nullable().catch(null),
  cpiDueNow: PaymentDataRes.shape.cpiDueNow,
  ddDueNow: PaymentDataRes.shape.ddDueNow,
  defDownBal: z.number().catch(0),
  dmsNextDueAmount: PaymentDataRes.shape.dmsNextDueAmount,
  enabledPmtProviders: z.array(ZCardProcessorName).catch([]),
  isPrincipalOnly: z.boolean().catch(false),
  isDownPmt: z.boolean().catch(false),
  // isWsPmt may need to be deleted
  isWsPmt: z.boolean().catch(false),
  isMiscPmt: z.boolean().catch(false),
  isZeroAuth: z.boolean().catch(false),
  paymentProviders: PaymentProviderConfigRes,
  lcDue: z.number().catch(0),
  maxPayment: z.number().catch(0),
  nsfDue: z.number().catch(0),
  onCpi: z.boolean().catch(false),
  payOff: PaymentDataRes.shape.payOff,
  pmtDue: PaymentDataRes.shape.pmtDue,
  prinBal: z.number().catch(0),
  repayConvFeeProd: z.number().nullable().catch(null),
  repayConvFeeTest: z.number().nullable().catch(null),
  repayTestMode: z.boolean().nullable().catch(null),
  validPmtMethods: z
    .map(ZCardProcessorName, z.array(z.object({ mpdType: z.string().optional() })))
    .catch(new Map()),
  //
});
export type ExternalFields = z.infer<typeof ExternalFields>;

export const BasePmtFormSchema = unconditionalFields.merge(conditionalFields);
export type BasePmtFormSchema = z.infer<typeof BasePmtFormSchema>;

export const mapReqDataToForm = (
  isPrinOnly: boolean,
  isDownPmt: boolean,
  isZeroAuth: boolean,
  employeeRecId: number | undefined,
  paymentData: PaymentDataRes | null,
  paymentProviders: IPaymentProviderConfigRes | null,
  _validPmtMethods: Map<ZCardProcessorName, SavedPmtMethod[]> | null // @note see comment below
): Nullish<z.input<typeof BasePmtFormSchema>> => {
  // Check if it's a lease account
  const isLeaseAccount = paymentData?.colType === 'CD';

  // For lease accounts, use pmtDue instead of dmsNextDueAmount to exclude deferred down
  const totalReceived =
    (isPrinOnly
      ? paymentData?.prinBal
      : isDownPmt
      ? paymentData?.tOfPBal
      : isLeaseAccount
      ? paymentData?.pmtDue
      : paymentData?.nextDueAmount) ?? 0;
      
  const totalPayment =
    (isPrinOnly
      ? paymentData?.prinBal
      : isDownPmt
      ? paymentData?.tOfPBal
      : isLeaseAccount
      ? paymentData?.pmtDue  // Use pmtDue for lease accounts
      : paymentData?.dmsNextDueAmount) ?? 0;

  const cpiPaid = isPrinOnly ? 0 : paymentData?.cpiDueNow ?? 0;
  const lcPaid = isPrinOnly ? 0 : paymentData?.lcDue ?? 0;
  // Set ddPmt to 0 for lease accounts
  const ddPmt = (isPrinOnly || isLeaseAccount) ? 0 : paymentData?.ddDueNow ?? 0;
  const nsfPaid = isPrinOnly ? 0 : paymentData?.nsfDue ?? 0;

  const paidBy = isZeroAuth ? PaymentMethod.enum["Credit Card"] : PaymentMethod.enum.Cash

  /** @todo Implement default via passed in form params
   * const enabledPmtMethods = getEnabledPmtMethods(paymentProviders, validPmtMethods ?? null, { provider: form.provider,paidBy: form.paidBy });
   * const selectedPmtMethod = null; // enabledPmtMethods && enabledPmtMethods.length > 0 && enabledPmtMethods[0];
   */

  return {
    carPmt: calcCarPmtEffect({ form: { totalPayment, cpiPaid, lcPaid, ddPmt, nsfPaid } }),
    cpiPaid: isPrinOnly ? 0 : paymentData?.cpiDueNow,
    ddPmt: (isPrinOnly || isLeaseAccount) ? 0 : paymentData?.ddDueNow,
    lcPaid: isPrinOnly ? 0 : paymentData?.lcDue,
    nsfPaid: isPrinOnly ? 0 : paymentData?.nsfDue,
    sendB: !!paymentData?.buyerEmail && !paymentData?.buyerNoEmail,
    sendC: !!paymentData?.cobuyerEmail && !paymentData?.cobuyerNoEmail,
    totalReceived,
    totalPayment,
    // useSavedPaymentMethod: !!selectedPmtMethod,
    // mpdRecId: !selectedPmtMethod ? null : selectedPmtMethod.recId,
    billAddress: paymentData?.buyerAddr,
    billCity: paymentData?.buyerCity,
    billState: paymentData?.buyerState,
    billZip: paymentData?.buyerZip,
    billFirstName: paymentData?.buyerFirstName,
    billLastName: paymentData?.buyerLastName,
    billEmail: paymentData?.buyerEmail,
    provider: paymentProviders?.preferredPaymentProvider,
    paidBy,
    employeeRecId,
  };
};
