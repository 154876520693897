import { FC, useState } from 'react';
// mui
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
// kendo
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
// state
import { useTableCtx } from '../tableState';
// interfaces
import { Filter } from '@/interfaces/requests';
import {
  allowedCategoryFiltersMap as allowedAcctCategoryFiltersMap,
  AllowedCategoryFilterKey as AllowedAcctCategoryFilterKey,
  allowedFilterFields as allowedAcctFilterFields,
} from '../accountsSubviews/default';
import { useAccountsViewCtx } from '../accountsViewState';

interface CategoryFilterProps {
  field: AllowedAcctCategoryFilterKey;
  options?: string[]; // Add this prop
}

const CategoryFilterOption: FC<{
  field: AllowedAcctCategoryFilterKey;
  label: string;
  value: string;
}> = ({ field, label, value }) => {
  const filter = useTableCtx((s) => s.filter);
  const addFilter = useTableCtx((s) => s.addFilter);
  const removeFilter = useTableCtx((s) => s.removeFilter);

  const values = filter.map((f) => f.value);
  const [isChecked, setIsChecked] = useState(values.includes(value));

  const filterValue: Filter = { field, operator: 'IN', value };

  const handleCheck = () => {
    isChecked ? removeFilter(filterValue) : addFilter(filterValue);
    setIsChecked(!isChecked);
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleCheck} role={undefined} dense>
        <ListItemIcon>
          <Checkbox checked={isChecked} edge="start" disableRipple sx={{ py: 0 }} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            letterSpacing: '-0.20px',
            lineHeight: '20px',
            fontSize: '14px',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const CategoryFilter: FC<CategoryFilterProps> = ({ field, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const categoryFilterOptions = options || useAccountsViewCtx((s) => s.subviewInfo.categoryFilterOptions);

  const { label: filterLabel, optionsMap } = allowedAcctCategoryFiltersMap.get(field) || {
    label: '',
    optionsMap: new Map(),
  };

  if (!categoryFilterOptions || categoryFilterOptions.length === 0) {
    return <></>;
  }

  const filterOptionElems = categoryFilterOptions.map((o, idx) => {
    const { value, label } = optionsMap.get(o) || { value: o, label: o }; // Fallback to raw value if mapping is missing
    return <CategoryFilterOption field={field} value={value} label={label} key={`c-f-o${idx}`} />;
  });

  return (
    <DropDownButton
      label={`${filterLabel} Filter`}
      open={isOpen}
      setOpen={setIsOpen}
      buttonProps={{ secondary: true }}
    >
      <List component={Paper}>{filterOptionElems}</List>
    </DropDownButton>
  );
};

export default CategoryFilter;