// interfaces
import { PmtFieldValues, PmtFormCb, PmtFormCbParams, PmtFormEffectCb } from "./interfaces";

type CarPmtEffectParams = Omit<Partial<PmtFormCbParams>, "form"> & {
  form: Pick<PmtFormCbParams["form"], "totalPayment" | "cpiPaid" | "lcPaid" | "ddPmt" | "nsfPaid">;
};

// Helper function
// if the value is less than the min it returns min
// if the value is greater than the max it returns max
// otherwise it returns the value in between
const limitWithinRange = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export const calcCarPmtEffect = ({
  form: { totalPayment, cpiPaid, lcPaid, ddPmt, nsfPaid },
}: CarPmtEffectParams): PmtFieldValues["carPmt"] => totalPayment - (cpiPaid + lcPaid + ddPmt + nsfPaid);

export const calcTotalReceived: PmtFormCb = ({ form, calculated, external }) => {
  const { totalPayment, totalReceived, carPmt } = form;
  const { isPrincipalOnly, isMiscPmt } = external;

  if (isMiscPmt) return totalPayment;
  if (isPrincipalOnly) return carPmt + (calculated.isCcOrAch ? (calculated.convFee ?? 0) : 0);

  return calculated.isCcOrAch
    ? totalPayment + (calculated.convFee ?? 0)
    : totalPayment > totalReceived
    ? totalPayment
    : totalReceived;
};

export const calcTotalReceivedOnTotalPaymentChange: PmtFormCb = (params) => {
  const { form, calculated, external } = params;

  return !external.isMiscPmt && !external.isPrincipalOnly && !calculated.isCcOrAch
    ? form.totalPayment
    : calcTotalReceived(params);
};

const calcLcPaidInLcPaidEffect: PmtFormCb = ({ form, external }) => {
  if (external.isPrincipalOnly) return form.lcPaid;
  return limitWithinRange(form.lcPaid, 0, external.lcDue);
};

const calcLcWaivedInLcPaidEffect: PmtFormCb = ({ form, external }) => {
  if (external.isPrincipalOnly) return form.lcWaived;

  const remainingLateCharge = external.lcDue - form.lcPaid;
  return limitWithinRange(form.lcWaived, 0, remainingLateCharge);
};

export const lcPaidEffect: PmtFormEffectCb = (params) => {
  if (params.external.isPrincipalOnly) return {} as Partial<PmtFieldValues>;

  return {
    carPmt: calcCarPmtEffect(params),
    lcPaid: calcLcPaidInLcPaidEffect(params),
    lcWaived: calcLcWaivedInLcPaidEffect(params),
    totalReceived: calcTotalReceived(params),
  } as Partial<PmtFieldValues>;
};

export const lcWaivedEffect: PmtFormEffectCb = ({ form, external, calculated }) => {
  if (external.isPrincipalOnly) return {};

  const { lcWaived, lcPaid } = form;
  const maxLateCharge = external.lcDue;

  let updatedLcPaid = lcPaid;
  let updatedLcWaived = lcWaived;

  if (lcWaived > maxLateCharge) {
    updatedLcPaid = 0;
    updatedLcWaived = maxLateCharge;
  } else if (lcWaived < 0) {
    updatedLcPaid = maxLateCharge;
    updatedLcWaived = 0;
  } else if (lcPaid + lcWaived > maxLateCharge) {
    updatedLcPaid = maxLateCharge - lcWaived;
  }

  return {
    lcPaid: updatedLcPaid,
    lcWaived: updatedLcWaived,
    carPmt: calcCarPmtEffect({ form: { ...form, lcPaid: updatedLcPaid }, external }),
    totalReceived: calcTotalReceived({ form: { ...form, lcPaid: updatedLcPaid }, external, calculated }),
  };
};

export const transactionTypeEffect: PmtFormEffectCb = ({ form, external }) => {
  const { transactionType } = form;
  const {
    cpiDueNow,
    ddDueNow,
    dmsNextDueAmount,
    lcDue,
    maxPayment,
    nsfDue,
    payOff,
    pmtDue,
    colType,
  } = external;

  const isPayoff = transactionType === "Payoff";
  const isLeaseAccount = colType === 'CD';
  
  const adjustedDmsNextDueAmount = isLeaseAccount ? pmtDue : dmsNextDueAmount;
  const adjustedMaxPayment = isLeaseAccount ? payOff : maxPayment;
  
  const adjustedDdPmt = isLeaseAccount ? 0 : ddDueNow;

  return {
    carPmt: isPayoff ? payOff : pmtDue,
    cpiPaid: cpiDueNow,
    lcPaid: lcDue,
    ddPmt: adjustedDdPmt, // Set ddPmt to 0 for lease accounts
    nsfPaid: nsfDue,
    totalReceived: isPayoff ? adjustedMaxPayment : adjustedDmsNextDueAmount,
    totalPayment: isPayoff ? adjustedMaxPayment : adjustedDmsNextDueAmount,
  };
};