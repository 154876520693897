import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LoadingButton from "@mui/lab/LoadingButton";
import Section from "@/mui/components/form/Section";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
// kendo
import { BackLink } from "@/components/backLink/BackLink";
import { TextInput } from "@/components/inputs/text/TextInput";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
// components
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { PrintButton } from "./PrintButton";
// state
import { useSidenoteCtx } from "./SidenoteProvider";

const textStyle = { marginTop: "1px", marginBottom: "1px" };

/** ###  */
const SidenoteForm: FC = () => {
  const activeSidenote = useSidenoteCtx((s) => s.activeSidenote);
  const isValid = useSidenoteCtx((s) => s.formState.isValid);
  const pmtAmtError = useSidenoteCtx((s) => s.pmtAmtError);
  const isDirty = useSidenoteCtx((s) => s.formState.isDirty);
  const isReqValid = useSidenoteCtx((s) => s.reqBody.isValid);
  const isNew = useSidenoteCtx((s) => s.isNew);
  const plan1 = useSidenoteCtx((s) => s.plan1);
  const plan2 = useSidenoteCtx((s) => s.plan2);
  const handleCancel = useSidenoteCtx((s) => s.handleCancel);
  const handleDelete = useSidenoteCtx((s) => s.handleDelete);
  const handleSubmit = useSidenoteCtx((s) => s.handleSubmit);
  const isSubmitting = useSidenoteCtx((s) => s.isSubmitting);
  const isDisabled = !isDirty || !isValid || !isReqValid || !!pmtAmtError;

  return (
    <AccountsMainPanel navBarTitle="Sidenotes" loading={false}>
      <Section expanded={true}>
        <Grid
          container
          direction="row"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Grid>
            <BackLink to="../" title="Back to list" />
          </Grid>
          <Grid>{activeSidenote?.appRecId && <PrintButton isDisabled={isNew} />}</Grid>
        </Grid>
        <SectionBody
          sx={{ py: 0 }}
          leftColElems={<LeftContainer />}
          rightColElems={<RightContainer />}
        />
        <SectionBody>
          <SectionColumn>
            <Grid sx={{ p: "8px 0 8px 0", color: "#111827", fontSize: 14, fontWeight: 700 }}>
              Repayment Details
            </Grid>
            <TextInput value={plan1} style={textStyle} required disabled />
            <TextInput value={plan2} style={textStyle} required disabled />
          </SectionColumn>

          <Grid container direction="row" flex={1} justifyContent="flex-end" gap={1}>
            {isNew ? (
              <>
                <LoadingButton onClick={handleSubmit} loading={isSubmitting} disabled={isDisabled}>
                  Submit
                </LoadingButton>
                <LoadingButton onClick={handleCancel} loading={isSubmitting}>
                  Cancel
                </LoadingButton>
              </>
            ) : (
              <LoadingButton onClick={handleDelete} loading={isSubmitting}>
                Delete
              </LoadingButton>
            )}
          </Grid>
        </SectionBody>
      </Section>
    </AccountsMainPanel>
  );
};

export default SidenoteForm;
