import { FC, PropsWithChildren, createContext, useState, useEffect } from "react";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { companyService } from "@/services/companyService";
import { useAuthSelector } from "@/features/auth/authSlice";
import { LeasingRate, LeasingRateForm } from "@/interfaces/LeaseRate";
import useReq from "@/utils/useReq";
import { useForm } from "react-hook-form";

const defaultValues: LeasingRateForm = {
  recId: 0,
  
  // General Rate Sheet - 12 Months
  yR1_Main: null,
  yR1_GAP: null,
  yR1_LW: null,
  yR1_CrRep: null,
  yR1_Tracker: null,
  yR1_BankFee: null,
  yR1_Total: null,
  
  // General Rate Sheet - 24 Months
  yR2_Main: null,
  yR2_GAP: null,
  yR2_LW: null,
  yR2_CrRep: null,
  yR2_Tracker: null,
  yR2_BankFee: null,
  yR2_Total: null,
  
  // General Rate Sheet - 36 Months
  yR3_Main: null,
  yR3_GAP: null,
  yR3_LW: null,
  yR3_CrRep: null,
  yR3_Tracker: null,
  yR3_BankFee: null,
  yR3_Total: null,
  
  // General Rate Sheet - 42 Months
  yR35_Main: null,
  yR35_GAP: null,
  yR35_LW: null,
  yR35_CrRep: null,
  yR35_Tracker: null,
  yR35_BankFee: null,
  yR35_Total: null,
  
  // General Rate Sheet - 48 Months
  yR4_Main: null,
  yR4_GAP: null,
  yR4_LW: null,
  yR4_CrRep: null,
  yR4_Tracker: null,
  yR4_BankFee: null,
  yR4_Total: null,
  
  // Used Car Rate Sheet Fields
  yR1_UsedMilesPercent: null,
  yR1_UsedMilesPer: null,
  yR1_UsedDisp: null,
  yR1_UsedELT: null,
  yR1_UsedPurOpt: null,
  
  yR2_UsedMilesPercent: null,
  yR2_UsedMilesPer: null,
  yR2_UsedDisp: null,
  yR2_UsedELT: null,
  yR2_UsedPurOpt: null,
  
  yR3_UsedMilesPercent: null,
  yR3_UsedMilesPer: null,
  yR3_UsedDisp: null,
  yR3_UsedELT: null,
  yR3_UsedPurOpt: null,
  
  yR35_UsedMilesPercent: null,
  yR35_UsedMilesPer: null,
  yR35_UsedDisp: null,
  yR35_UsedELT: null,
  yR35_UsedPurOpt: null,
  
  yR4_UsedMilesPercent: null,
  yR4_UsedMilesPer: null,
  yR4_UsedDisp: null,
  yR4_UsedELT: null,
  yR4_UsedPurOpt: null,
  
  // New Car Rate Sheet Fields
  yR1_NewMilesPercent: null,
  yR1_NewMilesPer: null,
  yR1_NewDisp: null,
  yR1_NewELT: null,
  yR1_NewPurOpt: null,
  
  yR2_NewMilesPercent: null,
  yR2_NewMilesPer: null,
  yR2_NewDisp: null,
  yR2_NewELT: null,
  yR2_NewPurOpt: null,
  
  yR3_NewMilesPercent: null,
  yR3_NewMilesPer: null,
  yR3_NewDisp: null,
  yR3_NewELT: null,
  yR3_NewPurOpt: null,
  
  yR35_NewMilesPercent: null,
  yR35_NewMilesPer: null,
  yR35_NewDisp: null,
  yR35_NewELT: null,
  yR35_NewPurOpt: null,
  
  yR4_NewMilesPercent: null,
  yR4_NewMilesPer: null,
  yR4_NewDisp: null,
  yR4_NewELT: null,
  yR4_NewPurOpt: null,
  
  // Additional Fields
  securityDeposit: null,
  docFee: null,
  
  // Notes
  notesFees: "",
  notesGeneral: "",
  notesDueInception: "",
  
  // Headers
  usedHeader: "",
  newHeader: "",
  
  // Guaranteed Buyout
  guaranteedBuif: false,
};

// Helper function to safely add numbers (handles null/undefined)
const safeAdd = (numbers: (number | null | undefined)[]): number => {
  let total = 0;
  for (const num of numbers) {
    if (typeof num === 'number') {
      total += num;
    }
  }
  return total;
};

const useCtxState = () => {
  const compId = useAuthSelector((s) => s.compId);
  
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const leasingRateForm = useForm<LeasingRateForm>({
    defaultValues,
    mode: "onChange",
  });

  const { reset, setValue, getValues, watch } = leasingRateForm;

  // Update all totals based on current form values
  const updateTotals = () => {
    const formValues = getValues();

    // Calculate year 1 total
    const yr1Total = safeAdd([
      formValues.yR1_Main,
      formValues.yR1_GAP,
      formValues.yR1_LW,
      formValues.yR1_CrRep,
      formValues.yR1_Tracker,
      formValues.yR1_BankFee
    ]);
    setValue('yR1_Total', yr1Total, { shouldDirty: true });

    // Calculate year 2 total
    const yr2Total = safeAdd([
      formValues.yR2_Main,
      formValues.yR2_GAP,
      formValues.yR2_LW,
      formValues.yR2_CrRep,
      formValues.yR2_Tracker,
      formValues.yR2_BankFee
    ]);
    setValue('yR2_Total', yr2Total, { shouldDirty: true });

    // Calculate year 3 total
    const yr3Total = safeAdd([
      formValues.yR3_Main,
      formValues.yR3_GAP,
      formValues.yR3_LW,
      formValues.yR3_CrRep,
      formValues.yR3_Tracker,
      formValues.yR3_BankFee
    ]);
    setValue('yR3_Total', yr3Total, { shouldDirty: true });

    // Calculate year 3.5 total
    const yr35Total = safeAdd([
      formValues.yR35_Main,
      formValues.yR35_GAP,
      formValues.yR35_LW,
      formValues.yR35_CrRep,
      formValues.yR35_Tracker,
      formValues.yR35_BankFee
    ]);
    setValue('yR35_Total', yr35Total, { shouldDirty: true });

    // Calculate year 4 total
    const yr4Total = safeAdd([
      formValues.yR4_Main,
      formValues.yR4_GAP,
      formValues.yR4_LW,
      formValues.yR4_CrRep,
      formValues.yR4_Tracker,
      formValues.yR4_BankFee
    ]);
    setValue('yR4_Total', yr4Total, { shouldDirty: true });
  };

  // Watch for changes to calculate totals
  useEffect(() => {
    // Get the form subscription
    const subscription = watch((value, { name }) => {
      // Only update totals if a relevant field changed
      if (name && [
        'yR1_Main', 'yR1_GAP', 'yR1_LW', 'yR1_CrRep', 'yR1_Tracker', 'yR1_BankFee',
        'yR2_Main', 'yR2_GAP', 'yR2_LW', 'yR2_CrRep', 'yR2_Tracker', 'yR2_BankFee',
        'yR3_Main', 'yR3_GAP', 'yR3_LW', 'yR3_CrRep', 'yR3_Tracker', 'yR3_BankFee',
        'yR35_Main', 'yR35_GAP', 'yR35_LW', 'yR35_CrRep', 'yR35_Tracker', 'yR35_BankFee',
        'yR4_Main', 'yR4_GAP', 'yR4_LW', 'yR4_CrRep', 'yR4_Tracker', 'yR4_BankFee'
      ].includes(name)) {
        updateTotals();
      }
    });
    
    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, [watch]);

  // Load the leasing rate for the current company
  const leasingRatesData = useReq(async () => {
    if (!compId) return null;
    const data = await companyService.getLeasingRateSettings(compId);
    
    // If we got an array with at least one item, return the first one
    if (data && Array.isArray(data) && data.length > 0) {
      return data[0];
    }
    
    return null;
  });

  // Load data into the form
  const loadFormData = () => {
    // If there's no data, reset to defaults
    if (!leasingRatesData.value) {
      reset(defaultValues);
      return;
    }
    
    // We have data, populate the form
    const rate = leasingRatesData.value;
    
    // Process the data before setting the form
    const processedRate = { ...rate };
    
    // Handle special cases for string fields that should be empty strings not null
    if (processedRate.notesFees === null) processedRate.notesFees = "";
    if (processedRate.notesGeneral === null) processedRate.notesGeneral = "";
    if (processedRate.notesDueInception === null) processedRate.notesDueInception = "";
    if (processedRate.usedHeader === null) processedRate.usedHeader = "";
    if (processedRate.newHeader === null) processedRate.newHeader = "";
    
    // Reset the form with the processed data
    reset(processedRate as LeasingRateForm);
    
    // Calculate all totals after loading data
    setTimeout(() => {
      updateTotals();
    }, 0);
  };

  // Load data when component mounts or compId changes
  useEffect(() => {
    if (compId) {
      leasingRatesData.load();
    }
  }, [compId]);

  // Process data when it arrives from API
  useEffect(() => {
    loadFormData();
  }, [leasingRatesData.value]);

  return {
    isLoading: leasingRatesData.isLoading,
    resetToDefault: () => {
      reset(defaultValues);
      setTimeout(() => updateTotals(), 0);
    },
    leasingRateForm,
    isConfirmOpen,
    setIsConfirmOpen,
    isSubmitting,
    setIsSubmitting,
    leasingRatesData,
    loadLeasingRatesData: leasingRatesData,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type LeasingRatesCtx = ICtx;
export const useLeasingRatesCtx = useCtxFactory(Ctx);

const LeasingRatesProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default LeasingRatesProvider;