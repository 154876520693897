// components
import AmtDueCell from '../tableCellComponents/AmtDueCell';
import ScCell from '../tableCellComponents/ScCell';
import GPSCell from '../tableCellComponents/GPSCell';
// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const paidoffColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('accountNum', 'Account', 110, 'link', true, true, buildDetailRoute),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('dateSold', 'Date Sold', 120, 'date'),
  newColumnDef('saleType', 'Sale Type', 120),
  newColumnDef('lastPmtPaid', 'Last Paid', 120, 'date'),
  newColumnDef('code3', 'Lot', 80),
];
