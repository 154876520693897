import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
// components
import { Loader } from '@/components/loader/Loader';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modals/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { Icons } from '@/components/icons';
import { TextInput } from '@/components/inputs/text/TextInput';
import { FileInput } from '@/components/inputs/fileInput/FileInput';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { salesService } from '@/services/salesService';
import { systemService } from '@/services/systemService';
// interfaces
import { ScannedDocument } from '@/interfaces';

interface Photo {
  url: string;
  title: string;
  recId: number;
}

interface PhotoApiResponse extends ScannedDocument {
  recId: number;
}

interface InsuranceClaimPhotosModalProps {
  isOpen: boolean;
  onClose: () => void;
  claimRecId: number;
  refreshClaimDetails?: () => void;
}

const PhotoGallery: FC<{
  photos: Photo[];
  onDelete: (photoId: number) => void;
}> = ({ photos, onDelete }) => {
  const [deletingPhotoId, setDeletingPhotoId] = useState<number | null>(null);

  const handleDelete = async (photoId: number) => {
    setDeletingPhotoId(photoId);
    try {
      await onDelete(photoId);
    } finally {
      setDeletingPhotoId(null);
    }
  };

  if (photos.length === 0) {
    return (
      <Box
        sx={{
          width: '100%',
          p: 5,
          textAlign: 'center',
          bgcolor: '#f5f5f5',
          borderRadius: 1,
          color: '#666',
          fontSize: 14,
        }}
      >
        No photos available for this claim
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: 2,
        width: '100%',
      }}
    >
      {photos.map((photo) => (
        <Box
          key={photo.recId}
          sx={{
            position: 'relative',
            borderRadius: 1,
            overflow: 'hidden',
            boxShadow: 1,
          }}
        >
          <Box
            component="img"
            src={photo.url}
            alt={photo.title || 'Insurance claim photo'}
            sx={{
              width: '100%',
              height: 180,
              objectFit: 'cover',
            }}
            onError={(e: any) => {
              e.target.src = '/assets/placeholder-image.jpg';
            }}
          />
          <Box
            sx={{
              p: '8px 12px',
              bgcolor: '#f8f8f8',
              borderTop: '1px solid #eee',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {photo.title || 'Insurance claim photo'}
            </Box>
            {deletingPhotoId === photo.recId ? (
              <Loader size="small" />
            ) : (
              <Box
                onClick={() => handleDelete(photo.recId)}
                sx={{ cursor: 'pointer' }}
              >
                <Icons.X style={{ color: 'red' }} />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const InsuranceClaimPhotosModal: FC<InsuranceClaimPhotosModalProps> = ({
  isOpen,
  onClose,
  claimRecId,
  refreshClaimDetails,
}) => {
  const { orgId, compId, locId } = useAuthSelector((s) => s);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [photosList, setPhotosList] = useState<ScannedDocument[]>([]);
  const [photoFileDesc, setPhotoFileDesc] = useState('');
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPhotos = async (
    photosList: ScannedDocument[],
    orgId: number
  ): Promise<{ url: string }[]> => {
    if (!photosList.length) return [];

    const photosObject = photosList.map((file) => ({
      orgId,
      containerName: 'afsdealers',
      blobName: file.fileNameCloud,
    }));

    const photoInfoList = await systemService.getBlobReadUrls(photosObject);
    return photoInfoList;
  };

  const loadClaimPhotos = async () => {
    if (!claimRecId || claimRecId < 1) return;
    setLoading(true);
    try {
      const res = (await salesService.getPhotosByInsuranceClaim(
        orgId!,
        claimRecId
      )) as PhotoApiResponse[];

      if (res && res.length > 0) {
        setPhotosList(res);
        const photoInfoList = await fetchPhotos(res, orgId!);

        const newPhotos: Photo[] = photoInfoList.map((photo, index) => {
          const foundDoc = res[index];
          return {
            url: photo.url,
            title:
            foundDoc?.fileDesc || foundDoc?.fileCategory || 'Insurance Claim Photo',
            recId: foundDoc?.recId || 0,
          };
        });

        setPhotos(newPhotos);
      } else {
        setPhotosList([]);
        setPhotos([]);
      }
    } catch (error) {
      console.error('Error loading insurance claim photos:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadPhoto = async () => {
    if (!photoFile) {
      setFileInputError(true);
      return;
    }
    if (!photoFileDesc) {
      setFileDescriptionError(true);
      return;
    }

    const payload = {
      file: photoFile,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: 0,
      vehRecId: 0, // Store claim ID in vehRecId
      filePK: claimRecId,
      fileDesc: photoFileDesc,
      fileCategory: 'Insurance Claim Photo',
    };

    setUploadingPhoto(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadClaimPhotos();
      if (refreshClaimDetails) refreshClaimDetails();

      setPhotoFile(null);
      setPhotoFileDesc('');
      setUploadModalOpen(false);
    } catch (error) {
      console.error('Error uploading photo:', error);
    } finally {
      setUploadingPhoto(false);
    }
  };

  const deletePhoto = async (photoId: number) => {
    try {
      await salesService.deleteScannedDoc(orgId!, photoId);
      await loadClaimPhotos();
      if (refreshClaimDetails) refreshClaimDetails();
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleFileDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFileDescriptionError(false);
    setPhotoFileDesc(e.target.value);
  };

  const handlePhotoInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setPhotoFile(file);
  };

  useEffect(() => {
    if (isOpen && claimRecId) {
      loadClaimPhotos();
    }
  }, [isOpen, claimRecId, orgId]);

  return (
    <Modal
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      closeButton
      title="Insurance Claim Photos"
      centerModal
      panelStyle={{
        width: 800,
        height: '80vh',
        maxHeight: '80vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Button
            label="Upload New Photo"
            onClick={() => setUploadModalOpen(true)}
            themeColor="primary"
          />
        </Box>

        <Spacer size={20} />

        <Box sx={{ flex: 1, overflow: 'auto', px: 1 }}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 5,
              }}
            >
              <Loader size="large" />
            </Box>
          ) : (
            <PhotoGallery photos={photos} onDelete={deletePhoto} />
          )}
        </Box>
      </Box>

      {uploadModalOpen && (
        <Modal
          isOpen
          onCloseButtonClick={() => setUploadModalOpen(false)}
          closeButton
          centerModal
          title="Upload New Photo"
          panelStyle={{ width: 600 }}
        >
          <Box>
            <Spacer size={5} />
            <FileInput
              onChange={handlePhotoInputChange}
              fileName={photoFile?.name}
              errors={fileInputError}
              accept="image/*"
            />
            <Spacer size={20} />
            <TextInput
              required
              label="Photo Description"
              value={photoFileDesc}
              horizontalLabel={false}
              onChange={handleFileDescriptionChange}
              errors={fileDescriptionError}
            />
            <Spacer size={20} />
            <Box sx={{ width: 100, marginLeft: 'auto' }}>
              <Button
                label="Submit"
                loading={uploadingPhoto}
                onClick={uploadPhoto}
              />
            </Box>
          </Box>
        </Modal>
      )}
    </Modal>
  );
};

export default InsuranceClaimPhotosModal;
