import { FC, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// mui
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2";
import { getRowId } from "@/mui/components/dataTable/utils";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// state
import { useAcctInsuranceCtx } from "./AcctInsuranceProvider";
// utils
import { tableColumns } from "./tableColumns";
import { initPage, tableStyle } from "@/features/Settings/default";
// interfaces
import { InsuranceClaim } from "./interfaces";
import InsuranceClaimForm from "./InsuranceClaimForm";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";

const InsuranceClaims: FC = () => {
  const navigate = useNavigate();

  const { accountId } = useParams();

  const { 
    insuranceClaims,
    selectedClaimId,
    selectedClaim,
    isNewClaim,
    setSelectedClaimId,
    setIsNewClaim
  } = useAcctInsuranceCtx((state) => ({
    insuranceClaims: state.insuranceClaims ?? [],
    selectedClaimId: state.selectedClaimId,
    selectedClaim: state.selectedClaim,
    isNewClaim: state.isNewClaim,
    setSelectedClaimId: state.setSelectedClaimId,
    setIsNewClaim: state.setIsNewClaim
  }));
  const gridWrapperRef = useRef<HTMLDivElement>(null);
  
  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageModel, setPageModel] = useState(initPage);

  const showForm = isNewClaim || !!selectedClaimId;

  useEffect(() => {
    const handleEditEvent = (e: Event) => {
      const customEvent = e as CustomEvent;
      if (customEvent.detail && customEvent.detail.recId) {
        handleEditClaim(customEvent.detail.recId);
      }
    };

    const currentRef = gridWrapperRef.current;
    if (currentRef) {
      currentRef.addEventListener('edit-claim', handleEditEvent);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('edit-claim', handleEditEvent);
      }
    };
  }, [insuranceClaims]); // Re-attach when claims change

  const handleNewClaim = () => {
    setSelectedClaimId(null);
    setIsNewClaim(true);

    if (accountId) {
      navigate(`/accounts/${accountId}/insurance/claims`);
    }
  };

  const handleEditClaim = (claimId: number) => {
    setSelectedClaimId(claimId);
    setIsNewClaim(false);
    if (accountId) {
      navigate(`/accounts/${accountId}/insurance/claims?claimId=${claimId}`);
    }
  };

  const handleCloseForm = () => {
    setSelectedClaimId(null);
    setIsNewClaim(false);
    if (accountId) {
      navigate(`/accounts/${accountId}/insurance/claims`);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      {/* Inline form if showForm is true */}
      {showForm && (
        <Grid>
          <InsuranceClaimForm
            editingClaim={selectedClaim}
            onClose={handleCloseForm}
          />
        </Grid>
      )}

      {/* Claims Table Section */}
      {!showForm && (
        <Grid>
          <Box>
            <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
              {/* optional heading here */}
            </Typography>
            
            {/* New Claim Button - Positioned outside the table */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Button 
                variant="contained" 
                sx={{ 
                  bgcolor: DCS_BLUE,
                  "&:hover": {
                    bgcolor: "#1e457b"
                  },
                  borderRadius: 4,
                  textTransform: "none"
                }} 
                onClick={handleNewClaim}
              >
                New Claim
              </Button>
            </Box>

            {/* DataGrid Table */}
            <Box sx={{ 
              bgcolor: BG_HIGHLIGHT, 
              borderRadius: 2, 
              p: 2, 
              width: '100%',
              boxShadow: 2
            }}>
              {insuranceClaims.length > 0 ? (
                <div ref={gridWrapperRef}>
                  <DataGrid
                    rowHeight={60}
                    rows={insuranceClaims}
                    columns={[...tableColumns, {
                      field: 'actions',
                      headerName: 'Actions',
                      renderCell: (params) => {
                        const handleClick = (e: React.MouseEvent) => {
                          e.stopPropagation();
                          if (params.row) {
                            handleEditClaim(params.row.recId);
                          }
                        };
                      
                        return (
                          <Button 
                            variant="outlined" 
                            size="small" 
                            onClick={handleClick}
                            sx={{
                              borderColor: DCS_BLUE,
                              color: DCS_BLUE,
                              textTransform: "none",
                              "&:hover": {
                                borderColor: "#1e457b",
                                bgcolor: "rgba(41, 98, 255, 0.04)"
                              }
                            }}
                          >
                            Edit
                          </Button>
                        );
                      },
                      align: 'center',
                      disableColumnMenu: true,
                      sortable: false,
                      filterable: false,
                      minWidth: 100,
                    }]}
                    getRowId={getRowId("recId")}
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    paginationModel={pageModel}
                    onPaginationModelChange={setPageModel}
                    disableColumnFilter
                    disableColumnSelector 
                    disableDensitySelector
                    disableRowSelectionOnClick
                    sx={{
                      ...tableStyle,
                      '& .MuiDataGrid-toolbarContainer': {
                        display: 'none', // Hide toolbar completely
                      },
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          recId: false, // Hides the recId column
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <Box sx={{ textAlign: "center", py: 4 }}>
                  <Typography>No insurance claims found.</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default InsuranceClaims;