import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
// kendo
import { Loader } from '@progress/kendo-react-all';
// interfaces
import { StateNavTab } from './interfaces';
// style
import styles from './AccountsMainPanel.module.scss';
import Box from '@mui/material/Box';

interface AccountsMainPanelProps {
  children: ReactNode;
  navBarTitle: string;
  navBarItems?: StateNavTab[];
  navBarItemsStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  headerRight?: ReactNode;
  loading?: boolean;
}

/** @deprecated avoid using going forward. This will be removed. */
export const AccountsMainPanel: FC<AccountsMainPanelProps> = ({
  children,
  navBarItems,
  navBarTitle,
  headerRight,
  navBarItemsStyle = {},
  loading,
  containerStyle = {},
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const navBarRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(0);
  const [panelWidth, setPanelWidth] = useState(0);
  const [navBarHeight, setNavBarHeight] = useState(0);

  // @todo remove. CSS handles this.
  useEffect(() => {
    if (!panelRef?.current || !navBarRef?.current) return;
    // Resize when panel size changes
    // TODO: This properly handles when the window size increases, but does not when it decreases

    const handleResize = () => {
      if (panelRef?.current?.clientHeight) {
        setPanelHeight(panelRef.current.clientHeight);
        setPanelWidth(panelRef.current.clientWidth);
      }
      if (navBarRef?.current?.clientHeight) {
        setNavBarHeight(navBarRef.current.clientHeight);
      }
    };

    handleResize(); // Run once on initial render

    const debouncedHandleResize = debounce(handleResize, 250); // Debounce so it doesn't get triggered rapidly during resizing

    const resizeObserver = new ResizeObserver(debouncedHandleResize);

    resizeObserver.observe(panelRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [panelRef?.current, navBarRef?.current]);

  return (
    <Box 
      className={styles.panelContainer} 
      sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        ...containerStyle
      }} 
      ref={panelRef}
    >
      <Box className={styles.body} sx={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Box 
          className={styles.navBar} 
          sx={{ 
            width: '100%', 
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            flexWrap: 'wrap',
            padding: '10px',
            borderBottom: '1px solid #e0e0e0'
          }} 
          ref={navBarRef}
        >
          <Box className={styles.navBarTitle} sx={{ mr: 2, fontWeight: 'bold' }}>{navBarTitle}</Box>
          
          {!!navBarItems && !!navBarItems.length && (
            <Box 
              className={styles.navBarItemsContainer} 
              sx={{ 
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                flex: 1,
                overflow: 'hidden',
                justifyContent: 'space-around !important',
                // ...navBarItemsStyle
              }}
            >
              {navBarItems.map((item) => (
                <Box
                  className={item.isActive ? styles.itemActive : styles.item}
                  key={item.title}
                  onClick={item.onClick}
                  sx={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    padding: '4px 12px',
                    borderRadius: '4px',
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <div>{item.title}</div>
                </Box>
              ))}
            </Box>
          )}
          
          {!!headerRight && (
            <Box sx={{ ml: 'auto' }}>
              {headerRight}
            </Box>
          )}
        </Box>
        
        <Box
          className={styles.mainContents}
          sx={{
            flex: 1,
            width: '100%',
            paddingY: '10px',
            height: `calc(100% - ${navBarHeight}px)`,
            maxHeight: `calc(100% - ${navBarHeight}px)`,
            overflowY: 'hidden',
            overflowX: 'hidden',
          }}
        >
          {loading ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%' 
            }}>
              <Loader size="large" />
            </Box>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Box>
  );
};