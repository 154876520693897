import { FC } from "react";
import { Controller } from "react-hook-form";
// kendo
import { SocialSecurityInput } from "@/components/inputs/socialSecurityInput/SocialSecurityInput";
import { Button } from "@/components/button/Button";
import { Checkbox } from "@/components/checkbox/Checkbox";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { PhoneInput } from "@/components/inputs/phoneInput/PhoneInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { ZipInput } from "@/components/inputs/zipInput/ZipInput";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { MultipleInputRow } from "@/features/Sales/components/multipleInputRow/MultipleInputRow";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
// state
import { useApplicationBuyer } from "./useApplicationBuyer";
// utils
import { optionalNotGreaterThanTodayValidation } from "@/utils/helpers/formValidation";

import { usaStateCodes } from "@/general/regions";
// interfaces
import { BuyerType } from "@/enums/general";
// style
import styles from "./ApplicationBuyer.module.scss";
import applicationTabStyle from "../ApplicationTabs.module.scss";

/** @deprecated needs refactor */
export const CashBuyer: FC<{ buyerOrCobuyer: BuyerType; isCobuyer?: boolean }> = ({
  buyerOrCobuyer,

  isCobuyer = true,
}) => {
  const {
    control,
    updateBuyerApplicationLoading,
    handleBuyerApplicationUpdate,
    handleCoBuyerApplicationUpdate,
    handleSubmit,
    showMailingAddress,
    setShowMailingAddress,
    errors,
    handlePlaceSelected,
    handleMailingPlaceSelected,
    fullAddress,
    fullMailingAddress,
    isDisabled,
  } = useApplicationBuyer(buyerOrCobuyer);
  const appRecId = 0;

  // @todo move nested components to separate files
  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit((data) => {
            const updatedData = {
              ...data,
              appRecId,
            };
            if (buyerOrCobuyer === "Buyer") {
              return handleBuyerApplicationUpdate(updatedData);
            } else {
              return handleCoBuyerApplicationUpdate(updatedData);
            }
          })}
        >
          <section className={applicationTabStyle.section}>
            <SectionHeader title="General Information" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="First Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Middle Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Last Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Nickname" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="socialSecurityNumber"
                  control={control}
                  render={({ field }) => (
                    <SocialSecurityInput
                      label="Social Security #"
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                    name="dob"
                    control={control}
                    rules={optionalNotGreaterThanTodayValidation}
                    render={({ field }) => (
                      <DateInput
                        label="Date of Birth"
                        errors={!!errors.dob?.message}
                        readOnly={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Email" readOnly={isDisabled} {...field} />
                    )}
                  />
              </div>
              <div className={`${applicationTabStyle.bodyChild} ${styles.rightGeneralContainer}`}>
                <div className={styles.rightContainerInfo}>
                <Controller
                    name="driversLicenseNumber"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Driver's License #" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="expirationDate"
                    control={control}
                    render={({ field }) => (
                      <DateInput label="Expiration Date" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="State"
                        data={usaStateCodes}
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="cellPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Cell Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="homePhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Home Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="otherPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Other Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  
                  
                </div>
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <SectionHeader title="Residency" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <AddressAutocomplete
                      label="Address"
                      onPlaceSelected={handlePlaceSelected}
                      fullAddress={fullAddress}
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="aptOrSuite"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Apt/Suite" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="City" readOnly={isDisabled} {...field} />
                  )}
                />
              </div>
              <div className={`${applicationTabStyle.bodyChild} ${styles.rightGeneralContainer}`}>
                <div className={styles.rightContainerInfo}>
                  <div className={applicationTabStyle.inputRow}>
                    <label className={applicationTabStyle.inputLabel}>State/Zip</label>
                    <div className={applicationTabStyle.inputRowChild}>
                      <Controller
                        name="addressState"
                        control={control}
                        render={({ field }) => (
                          <DropdownInput
                            suggest
                            data={usaStateCodes}
                            disabled={isDisabled}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="addressZip"
                        control={control}
                        render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                      />
                    </div>
                  </div>
                  <Controller
                    name="county"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="County" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Checkbox
                    label="Different Mailing Address?"
                    boldLabel
                    defaultChecked={showMailingAddress}
                    disabled={isDisabled}
                    onChange={() => setShowMailingAddress((prev) => !prev)}
                  />
                  {showMailingAddress && (
                    <>
                      <Controller
                        name="mailingAddress"
                        control={control}
                        render={({ field }) => (
                          <AddressAutocomplete
                            label="Mailing Address"
                            {...field}
                            onPlaceSelected={handleMailingPlaceSelected}
                            fullAddress={fullMailingAddress}
                            readOnly={isDisabled}
                          />
                        )}
                      />
                      <Controller
                        name="mailingCity"
                        control={control}
                        render={({ field }) => (
                          <TextInput label="Mailing City" readOnly={isDisabled} {...field} />
                        )}
                      />
                      <MultipleInputRow label="Mailing State/Zip">
                        <Controller
                          name="mailingState"
                          control={control}
                          render={({ field }) => (
                            <DropdownInput data={usaStateCodes} disabled={isDisabled} {...field} />
                          )}
                        />
                        <Controller
                          name="mailingZip"
                          control={control}
                          render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                        />
                      </MultipleInputRow>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          <div className={applicationTabStyle.submitButton}>
            <div className={applicationTabStyle.sectionBar}></div>
            <Button
              label="Submit"
              buttonStyle={{ width: "140px" }}
              loading={updateBuyerApplicationLoading}
            />
          </div>
        </form>
      </div>
    </>
  );
};
