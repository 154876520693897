import { FC, useEffect } from "react";
import { Outlet, Route, Routes, Navigate, useLocation } from "react-router-dom";
// components
import Header from "./Header";
import Grid from "@mui/material/Unstable_Grid2";
import InventoryDetailsContainer from "./inventoryDetails/InventoryDetailsContainer";
import InventoryTable from "./Tables/InventoryTable";
// state
import { useAuthSelector } from "../auth/authSlice";
import InventoryProvider, { useInventoryViewCtx } from "./state";
import InventoryDetailProvider from "./inventoryDetails/InventoryDetailProvider";
// utils
import { systemService } from "@/services/systemService";
// style
import styles from "./InventoryContainer.module.scss";

const ParentContainer: FC = () => {
  const location = useLocation();
  const compId = useAuthSelector((s) => s.compId);
  const setMappedCompaniesCt = useInventoryViewCtx((s) => s.setMappedCompaniesCt);

  const fetchMappedCompanies = async () => {
    try {
      const res = await systemService.mappedCompanies();
      setMappedCompaniesCt(res.length);
    } catch (err) {
      console.error(err); // Handle error appropriately
    }
  };

  useEffect(() => {
    fetchMappedCompanies();
  }, [compId]);

  // Check if the current route is for InventoryDetailsContainer
  const isInventoryDetails =
    location.pathname.includes("/inventory/") && location.pathname.match(/\/\d+/);

  return (
    <div className={isInventoryDetails ? undefined : styles.container}>
      <Outlet />
    </div>
  );
};

const TableView: FC = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.startsWith('/inventory/') && !location.pathname.match(/\/\d+/)) {
      sessionStorage.setItem('lastInventoryPath', location.pathname);
    }
  }, [location.pathname]);
  
  return (
    <>
      <Header />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

const InventoryContainer: FC = () => {
  return (
    <Routes>
      <Route element={<ParentContainer />}>
        <Route element={<TableView />}>
          <Route path="/unsold" element={<InventoryTable />} />
          <Route path="/sold" element={<InventoryTable />} />
          <Route path="/iop" element={<InventoryTable />} />
          <Route path="/all" element={<InventoryTable />} />
          <Route path="/alllotsretail" element={<InventoryTable />} />
          <Route path="/notReceived" element={<InventoryTable />} />
          <Route path="/titlelogs" element={<InventoryTable />} />
          <Route path="/disposal" element={<InventoryTable />} />
        </Route>
        <Route path="/:invRecId/*" element={<InventoryDetailsContainer />} />
      </Route>
      <Route path="*" element={<Navigate to="/inventory/unsold" replace={true} />} />
    </Routes>
  );
};

export default () => (
  <InventoryProvider>
    <InventoryDetailProvider>
      <InventoryContainer />
    </InventoryDetailProvider>
  </InventoryProvider>
);
