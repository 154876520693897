import dayjs, { Dayjs } from 'dayjs';
// interfaces
import { IPayment } from '@/interfaces';
import { PaymentMethod, PaidIn, PaymentProcessor, ReversalType } from '@/enums/payment';

export type IReversiblePaymentRes = Pick<
  IPayment,
  | 'acctNum'
  | 'cpiPaid'
  | 'inDaysLate'
  | 'intPaid'
  | 'lcPaid'
  // | 'paidBy'
  // | 'paidIn'
  | 'paidRef'
  | 'payNote'
  | 'pmtTime'
  | 'prinPaid'
  | 'recId'
  | 'totalApplied'
  | 'totalReceived'
  | 'taxPaid'
  | 'nsfPaid'
> & {
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'paymentProcessor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  paymentProcessor: PaymentProcessor | null;
  fee: IPayment['nsfPaid'];
  paidIn: PaidIn;
  /** @deprecated rename to `pmtMethod` */
  paidBy: PaymentMethod;
};

/** ## Functional model for 'reversible payments' to be used throughout the app
 * - Derived from the API response
*/
export class ReversiblePayment implements Omit<IReversiblePaymentRes, 'pmtTime'> {
  /** string which represents a number */
  acctNum: string;
  cpiPaid: number;
  inDaysLate: number;
  intPaid: number;
  lcPaid: number;
  fee: number;
  /** @deprecated rename to `pmtMethod` */
  paidBy: PaymentMethod;
  paidIn: PaidIn;
  paidRef: string;
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'paymentProcessor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  paymentProcessor: PaymentProcessor | null;
  payNote: string;
  pmtTime: Dayjs;
  prinPaid: number;
  recId: number;
  totalApplied: number;
  taxPaid: number;
  nsfPaid:number;
  totalReceived: number;
  readonly totalPaid: number;

  constructor(newPmt: Required<IReversiblePaymentRes>) {
    this.acctNum = newPmt.acctNum;
    this.cpiPaid = newPmt.cpiPaid;
    this.inDaysLate = newPmt.inDaysLate;
    this.intPaid = newPmt.intPaid;
    this.lcPaid = newPmt.lcPaid;
    this.fee = newPmt.fee;
    this.paidBy = newPmt.paidBy;
    this.paidIn = newPmt.paidIn;
    this.paidRef = newPmt.paidRef;
    this.paymentProcessor = newPmt.paymentProcessor;
    this.payNote = newPmt.payNote;
    this.pmtTime = dayjs(newPmt.pmtTime);
    this.prinPaid = newPmt.prinPaid;
    this.recId = newPmt.recId;
    this.totalApplied = newPmt.totalApplied;
    this.taxPaid = newPmt.taxPaid || 0; 
    this.totalReceived = newPmt.totalReceived;
    this.nsfPaid = newPmt.nsfPaid || 0; 

    this.totalPaid = 
      newPmt.prinPaid + 
      newPmt.intPaid + 
      newPmt.lcPaid + 
      (newPmt.taxPaid || 0) + 
      newPmt.cpiPaid + 
      (newPmt.nsfPaid || 0); 
  }
}

/** ## Model which represents the form fields */
export class ReversiblePaymentForm {
  fee: IReversiblePaymentRes['fee'];
  reason: string;
  /** Lookup key which modifies the SQL query */
  reversalType: ReversalType;
  /** Should the reversal ONLY be updated in our database.
   * - If true, payment-reversal updates ONLY made in our database (no request to payment-providers).
   * - If false, reversal request is sent to payment-providers AND updates are made in our database.
   *
   * Defaults to true */
  systemOnly: boolean;
  /** Form field = dropdown */
  userRecId: number | null;
  password: string;

  constructor(pmtForm?: Partial<ReversiblePaymentForm>) {
    this.fee = pmtForm?.fee || 0;
    this.reason = pmtForm?.reason || '';
    this.reversalType = pmtForm?.reversalType || 'reversal';
    this.systemOnly = true;
    this.userRecId = pmtForm?.userRecId || 0;
    this.password = pmtForm?.password || '';
  }
  static new(
    selectedPmt: ReversiblePayment | undefined,
    reversalValueOptions: ReversalType[],
    employeeRecId?: number
  ): ReversiblePaymentForm {
    return new ReversiblePaymentForm({
      fee: selectedPmt?.fee,
      reversalType: reversalValueOptions[0],
      userRecId: employeeRecId || null,
    });
  }
}

type ReqParams = { colRecId: number; pmtRecId: number };

export class ReversiblePaymentPostReq extends ReversiblePaymentForm {
  colRecId: number;
  pmtRecId: number;
  acctNum: ReversiblePayment['acctNum'];
  /** Employee recId */
  override userRecId: number;

  constructor(
    pmtForm: ReversiblePaymentForm,
    pmtInfo: ReversiblePayment | undefined,
    params: Partial<ReqParams>
  ) {
    super(pmtForm);

    this.validateReqData(pmtForm, pmtInfo, params);

    this.reason = pmtForm.reason;
    this.reversalType = pmtForm.reversalType;
    this.colRecId = params.colRecId!;
    this.pmtRecId = params.pmtRecId!;
    this.systemOnly = pmtForm.systemOnly!;
    this.userRecId = pmtForm.userRecId!;
    this.acctNum = pmtInfo?.acctNum!;
  }

  private validateReqData(
    pmtForm: ReversiblePaymentForm,
    pmtInfo: ReversiblePayment | undefined,
    params: Partial<ReqParams>
  ) {
    const reqDataErrorMsg = JSON.stringify({ pmtForm, pmtInfo, params }, null, 2);
    if (!pmtForm.reason || pmtForm.reason === '')
      throw new Error(`Invalid 'pmtForm.reason': "${pmtForm.reason}" ${reqDataErrorMsg}`);
    if (!pmtForm.userRecId || pmtForm.userRecId < 1)
      throw new Error(`Invalid 'pmtForm.userRecId': "${pmtForm.userRecId}" ${reqDataErrorMsg}`);
    if (!ReversalType.options.includes(pmtForm.reversalType))
      throw new Error(
        `Invalid 'pmtForm.reversalType': "${pmtForm.reversalType}" ${reqDataErrorMsg}`
      );
    if (!params.colRecId || params.colRecId < 1)
      throw new Error(`Invalid 'params.colRecId': ${params.colRecId} - ${reqDataErrorMsg}`);
    if (!params.pmtRecId || params.colRecId < 1)
      throw new Error(`Invalid 'params.pmtRecId': ${params.pmtRecId} - ${reqDataErrorMsg}`);
    if (!pmtForm.password) throw new Error(`Invalid 'pmtForm.password'`);
  }
}
