import { z } from "zod";
import dayjs from "dayjs";
// utils
import { zDayjs } from "@/utils/forms/fieldSchemas";
import { PaymentInterval } from "@/enums/payment";

export const SidenoteForm = z.object({
  snNumber: z.string().trim().min(1, { message: "Required" }).default(""),
  snDate: zDayjs.default(dayjs().local()),
  snType: z.string().trim().min(1),
  vendor: z.number().positive().nullable().default(null),
  addOn1: z.number().default(0),
  addOn2: z.number().default(0),
  addOn3: z.number().default(0),
  addOn4: z.number().default(0),
  addOn5: z.number().default(0),
  addOn6: z.number().default(0),
  addOn7: z.number().default(0),
  addOn8: z.number().default(0),
  addOn9: z.number().default(0),
  addOn10: z.number().default(0),

  snDown: z.number().default(0),
  intRate: z.number().default(0),
  pmtDue: zDayjs.default(dayjs()),
  pmtSched: PaymentInterval.default("Weekly"),
  snDetail: z.string().trim().min(1, { message: "Required" }).default(""),

  // calculated fields
  nextDueDate: zDayjs.default(dayjs()),
  pmtAmt: z.number().gt(0).default(100),
  snTotal: z.number().gt(0).default(0),
  snAmtFin: z.number().gt(0).default(0),
  snFinChg: z.number().gte(0, "Finance charge is less than 0").default(0),
  numPmts: z.number().gte(0, "Number of Payments is less than 0").default(0),
  finalAmt: z.number().gte(0),
  finalDate: zDayjs.default(dayjs()),
});
export type SidenoteForm = z.infer<typeof SidenoteForm>;

export const SidenoteReq = SidenoteForm.extend({
  vin: z.string().default(""),
  vehRecId: z.number().positive().nullable().default(null),
  colRecId: z.number().positive().nullable().default(null),
  orgId: z.number().positive().nullable().default(null),
  locId: z.number().positive().nullable().default(null),
  compId: z.number().positive(),
  acctAppRecId: z.number().nullable().default(null), // account app rec that the sidenote is attatched to
  appRecId: z.number().nullable().default(null), // app rec id of sidenote that doesn't exist yet
  snEmp: z.string().default(""),
  ro_RecId: z.number().default(0),
  ro_Number: z.number().default(0),
  snTotalPaid: z.number().default(0),

  addLab1: z.string().default(""),
  addLab2: z.string().default(""),
  addLab3: z.string().default(""),
  addLab4: z.string().default(""),
  addLab5: z.string().default(""),
  addLab6: z.string().default(""),
  addLab7: z.string().default(""),
  addLab8: z.string().default(""),
  addLab9: z.string().default(""),
  addLab10: z.string().default(""),
});
export type SidenoteReq = z.infer<typeof SidenoteReq>;
