// CollectionQueuesProvider.tsx
import { FC, PropsWithChildren, createContext, useEffect, useState, useCallback, useMemo } from "react";
import dayjs from "dayjs";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
// utils
import {
  type CollectionQueue,
  collectionsService,
  getQueueFromIndex,
} from "@/services/collectionsService";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
// interfaces
import { CollectionsQueueForm, QueueType } from "./QueueDetailForm/interfaces";

const useCtxState = () => {
  // Combine auth selectors into one subscription
  const { locId, orgId, compId } = useAuthSelector((s) => ({
    locId: s.locId,
    orgId: s.orgId,
    compId: s.compId,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [queues, setQueues] = useState<CollectionQueue[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeQueue, setActiveQueueInternal] = useState<CollectionQueue | null>(null);

  // Lazy initialization for queueForm to avoid re-parsing on every render
  const [queueForm, setQueueForm] = useState<CollectionsQueueForm>(() =>
    CollectionsQueueForm.parse({})
  );
  const queueFormValidation = CollectionsQueueForm.safeParse(queueForm);

  // Wrap setActiveQueue so that its reference is stable
  const setActiveQueue = useCallback((newQueue: CollectionQueue | null) => {
    setActiveQueueInternal(newQueue);
    const defaultForm = CollectionsQueueForm.parse({});
    const queueType = newQueue?.queueType
      ? QueueType.options[newQueue.queueType]!
      : defaultForm.queueType;
    const newFormParsed: CollectionsQueueForm = {
      queueType,
      visibility: newQueue?.visibility ?? defaultForm.visibility,
      queueName: newQueue?.queueName ?? defaultForm.queueName,
      daysFrom: newQueue?.daysFrom ?? defaultForm.daysFrom,
      daysTo: newQueue?.daysTo ?? defaultForm.daysTo,
      alphaFrom: newQueue?.alphaFrom ?? defaultForm.alphaFrom,
      alphaTo: newQueue?.alphaTo ?? defaultForm.alphaTo,
      sortColumn: newQueue?.sortColumn ?? defaultForm.sortColumn,
      sortDirection: newQueue?.sortDirection ?? defaultForm.sortDirection,
      autoTTP: newQueue?.autoTTP ?? defaultForm.autoTTP,
      autoTTPLocalTime: newQueue?.autoTTPLocalTime
        ? dayjs(newQueue.autoTTPLocalTime).toDate()
        : defaultForm.autoTTPLocalTime,
      autoTTPTemplate: newQueue?.autoTTPTemplate ?? defaultForm.autoTTPTemplate,
      includeOutForRepo: newQueue?.includeOutForRepo ?? defaultForm.includeOutForRepo,
      includeInOurPossession: newQueue?.includeInOurPossession ?? defaultForm.includeInOurPossession,
      includeFieldCall: newQueue?.includeFieldCall ?? defaultForm.includeFieldCall,
      includePaymentArrangements:
        newQueue?.includePaymentArrangements ?? defaultForm.includePaymentArrangements,
      includeAppointments: newQueue?.includeAppointments ?? defaultForm.includeAppointments,
      includeFinanced: newQueue?.includeFinanced ?? defaultForm.includeFinanced,
      includeLease: newQueue?.includeLease ?? defaultForm.includeLease,
      includeSidenotes: newQueue?.includeSidenotes ?? defaultForm.includeSidenotes,
      includeDepositDown: newQueue?.includeDepositDown ?? defaultForm.includeDepositDown,
    };
    setQueueForm(newFormParsed);
  }, []);

  // Wrap setField and setFields to keep their references stable
  const setField = useCallback(
    (field: keyof CollectionsQueueForm, value: CollectionsQueueForm[keyof CollectionsQueueForm] | null) => {
      setQueueForm((s) => ({ ...s, [field]: value }));
    },
    []
  );
  const setFields = useCallback((fields: Partial<CollectionsQueueForm>) => {
    setQueueForm((s) => ({ ...s, ...fields }));
  }, []);

  // Wrap loadQueues in useCallback
  const loadQueues = useCallback(async (locId: number | undefined, orgId: number | undefined, compId: number | undefined) => {
    if (!locId || !orgId || !compId) return;
    setIsLoading(true);
    try {
      const colQueueRes = await collectionsService.getCollectionsQueue(locId, orgId, compId);
      const newCollectionQueues = colQueueRes.data?.map((cq) => ({
        ...cq,
        queueTypeString: getQueueFromIndex(cq.queueType),
      }));
      setQueues(newCollectionQueues || []);
    } catch (error) {
      console.error("Error loading collection queue data", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadQueues(locId, orgId, compId);
  }, [locId, orgId, compId, loadQueues]);

  // Memoize the returned context value so it only changes when necessary.
  return useMemo(
    () => ({
      isLoading,
      setIsLoading,
      isModalOpen,
      setIsModalOpen,
      queues,
      setQueues,
      loadQueues,
      activeQueue,
      setActiveQueue,
      queueForm,
      setQueueForm,
      queueFormValidation,
      setField,
      setFields,
    }),
    [
      isLoading,
      isModalOpen,
      queues,
      activeQueue,
      queueForm,
      queueFormValidation,
      setField,
      setFields,
      loadQueues,
    ]
  );
};

type ICtx = ReturnType<typeof useCtxState>;
export type ICollectionQueuesCtx = ICtx;
const Ctx = createContext<ICtx | null>(null);

export const useCollectionQueuesCtx = useCtxFactory(Ctx);
const CollectionQueuesProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default CollectionQueuesProvider;
