import { FC, useCallback, useEffect, useState, useRef } from "react";
import { debounce } from "lodash-es";
// kendo
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  FilterOperator,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { Loader } from "@progress/kendo-react-all";
// components
import { PageState } from "@/components/table/TableInterface";
// utils
import { columnMappings } from "./tableColumns";
import { TableFilters, useInventoryViewCtx } from "../state";
import { InventoryListRow } from "@/interfaces";
import { inventoryService } from "@/services/inventoryService";
import InventoryTableLayout from "./InventoryTableLayout";
import {
  FilterValue,
  FilterValueFmt,
  GetInventoryPayload,
  IModalFilterParams,
  ServerSideFiltering,
} from "./interfaces";
import { Sort } from "@/interfaces/requests";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";

const filterFieldNames: (keyof InventoryListRow)[] = [
  "stockNum",
  "vin",
  "year",
  "make",
  "model",
  "color",
  "code3",
  "shortName",
  "invType",
];

const sortTranslationDict = {
  stockNum: "StockNum",
  buyers: "Buyers",
  vehicle: "Vehicle",
  saleType: "SaleType",
  dateSold: "DateSold",
  amtDue: "AmtDue",
  daysLate: "DaysLate",
  sc: "SC",
  shortName: "ShortName",
};

const buildFilters: FilterDescriptor[] = filterFieldNames.map((n) => ({
  field: n,
  operator: FilterOperator.Contains,
  value: "",
  ignoreCase: true,
}));

const filter: CompositeFilterDescriptor = { logic: "or", filters: buildFilters };

const ROW_HEIGHT = 36; // Average height of a row in pixels

const InventoryTable: FC = () => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default value
  const searchFilter = useInventoryViewCtx((s) => s.searchFilter);
  const isLoading = useInventoryViewCtx((s) => s.isLoading);
  const setIsLoading = useInventoryViewCtx((s) => s.setIsLoading);
  const setListLength = useInventoryViewCtx((s) => s.setListLength);
  const tableDataMap = useInventoryViewCtx((s) => s.tableDataMap);
  const setTableDataMap = useInventoryViewCtx((s) => s.setTableDataMap);
  const tableFilters = useInventoryViewCtx((s) => s.tableFilters);
  const page = useInventoryViewCtx((s) => s.page);
  const sort = useInventoryViewCtx((s) => s.sort);
  const filterText = useInventoryViewCtx((s) => s.filterText);
  const modalFilterParams = useInventoryViewCtx((s) => s.modalFilterParams);
  const subview = useInventoryViewCtx((s) => s.subview);

  /** Calculate rows per page based on viewport height */
  const calculateRowsPerPage = () => {
    const totalHeight = window.innerHeight; // Full viewport height (100vh)
    const tableOffset = 300; // Adjust for headers, margins, etc.
    const availableHeight = totalHeight - tableOffset;
    return Math.floor(availableHeight / ROW_HEIGHT);
  };

  useEffect(() => {
    setRowsPerPage(calculateRowsPerPage());
    // Recalculate on window resize
    const handleResize = () => setRowsPerPage(calculateRowsPerPage());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchData = async (
    sort: SortDescriptor[] | null,
    page: PageState,
    filter: TableFilters,
    search: string,
    modalFilterParams: IModalFilterParams | null
  ) => {
    setIsLoading(true);
    const pg = page.skip! / page.take! + 1;
    const filterArray: FilterValue[] = [];
    const filterArrayFmt: FilterValueFmt[] = [];
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        const value = filter[key as keyof TableFilters];
        if (value) {
          const newFilter = new FilterValue(key, value);
          if (value.length > 0) {
            filterArray.push(newFilter);
            filterArrayFmt.push({ ...newFilter, value: newFilter.valueFmt });
          }
        }
      }
    }
    const apiTableParams = ServerSideFiltering.fromTableFilter(
      pg,
      page.take,
      sort === null
        ? null
        : sort.map((sort) => ({
            field: sortTranslationDict[sort.field as keyof typeof sortTranslationDict],
            direction: sort.dir!.toUpperCase() as Sort["direction"],
          })),
      filterArray
    );

    if (subview === "sold" && !modalFilterParams) {
      console.warn("Expected modalFilterParams to be available for 'sold' subview, but it is null.");
    }

    if (subview === "sold" && !modalFilterParams) {
      Sentry.captureMessage("modalFilterParams is null in 'sold' subview", {
        level: "warning",
        extra: { subview, modalFilterParams },
      });
    }

    //Ensure the data is never null because null throws an Axios Error
    const beginDate =
      subview === "sold" && modalFilterParams?.beginDate
        ? modalFilterParams.beginDate
        : dayjs().startOf("month").format("YYYY-MM-DD")
    
    const endDate =
      subview === "sold" && modalFilterParams?.endDate
        ? modalFilterParams.endDate
        : dayjs().format("YYYY-MM-DD");

    const payload: GetInventoryPayload = {
      page: apiTableParams.page,
      perPage: apiTableParams.perPage,
      sorts: apiTableParams.sorts === null ? null : apiTableParams.sorts,
      filters: filterArrayFmt.length > 0 ? filterArrayFmt : null,
      listType: subview,
      search: search,
      soldDateBegin: beginDate,
      soldDateEnd: endDate,
    };

    try {
      const res = await inventoryService.getInventoryList(payload);
      setTableDataMap({ ...tableDataMap, [subview]: res.data });
      setListLength(res.total);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  // Debounced handler for filter changes
  const handleFilterChange = useCallback(
    debounce(
      (
        sort: SortDescriptor[] | null,
        page: PageState,
        tableFilters: TableFilters,
        newSearch: string,
        modalFilterParams: IModalFilterParams | null
      ) => fetchData(sort, page, tableFilters, newSearch, modalFilterParams),
      400
    ),
    [subview]
  );

  // Ref to guard the initial fetch
  const initialFetchDone = useRef(false);

  // Initial fetch on mount and when non-search dependencies change
  useEffect(() => {
    // Only call fetchData if it hasn't been called yet
    if (!initialFetchDone.current) {
      fetchData(sort, page, tableFilters, filterText, modalFilterParams);
      initialFetchDone.current = true;
    }
  }, [sort, page, tableFilters, modalFilterParams, rowsPerPage]);

  // Debounced fetch for filter text changes (won't run on initial load)
  useEffect(() => {
    // Skip if initial fetch is not done (i.e. on first render)
    if (!initialFetchDone.current) return;
    handleFilterChange(sort, page, tableFilters, filterText, modalFilterParams);
  }, [filterText, sort, page, tableFilters, modalFilterParams, handleFilterChange]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1 }}>
        <InventoryTableLayout
          data={tableDataMap[subview]}
          columns={columnMappings[subview]}
          pageable={true}
          sortable={true}
          filter={{
            ...filter,
            filters: filter.filters.map((f) => ({
              ...f,
              value: searchFilter,
            })),
          }}
          initPageSize={rowsPerPage}
          noResultsComponent={isLoading ? <Loader size="large" /> : <div>No records found</div>}
        />
      </div>
    </div>
  );
};

export default InventoryTable;
