// PhotoGallery.tsx
import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Skeleton, IconButton } from '@mui/material';
import { Loader } from '@/components/loader/Loader';
import { Icons } from '@/components/icons';

interface Photo {
  url: string;
  title: string;
  recId?: number; // Optional to maintain backward compatibility
}

interface PhotoGalleryProps {
  photos: Photo[];
  onDelete?: (photoId: number) => Promise<void>; // Optional to maintain backward compatibility
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onDelete }) => {
  const [deletingPhotoId, setDeletingPhotoId] = useState<number | null>(null);

  const handleDelete = async (photoId: number) => {
    if (!onDelete) return;
    
    setDeletingPhotoId(photoId);
    try {
      await onDelete(photoId);
    } finally {
      setDeletingPhotoId(null);
    }
  };

  return (
    <ImageList sx={{ width: '100%', height: 'auto', justifyItems: 'center' }} cols={3} rowHeight={164}>
      {photos.reverse().map((photo, index) => (
        <ImageListItem key={photo.recId || index} sx={{
              width: 210,
              height: 118,
            }}>
          {photo ? (
            <img
              style={{
                width: 210,
                height: 118,
                alignSelf: 'center',
              }}
              src={photo.url}
              alt={photo.title}
              loading="lazy"
              onError={(e) => {
                (e.target as HTMLImageElement).src = 'path_to_placeholder_image.jpg'; // Provide a placeholder image URL
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )}
          <ImageListItemBar
            title={photo.title}
            actionIcon={
              onDelete && photo.recId && (
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={`delete ${photo.title}`}
                  disabled={deletingPhotoId === photo.recId}
                  onClick={() => handleDelete(photo.recId!)}
                >
                  {deletingPhotoId === photo.recId ? (
                    <Loader size="small" />
                  ) : (
                    <Icons.X style={{ color: 'white' }} />
                  )}
                </IconButton>
              )
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default PhotoGallery;