import { FC, PropsWithChildren, createContext, useState, useEffect } from "react";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { companyService } from "@/services/companyService";
import { useAuthSelector } from "@/features/auth/authSlice";
import useReq from "@/utils/useReq";
import { useForm } from "react-hook-form";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { Lienholders } from "@/interfaces/Lienholders";

const defaultValues: Lienholders = {
  recId: null,
  orgId: null,
  locId: null,
  compId: null,
  assCont: null,
  active: false,
  inHouse: false,
  email: null,
  contact: null,
  fax: null,
  phone: null,
  state: null,
  city: null,
  zip: null,
  address: null,
  abrName: null,
  name: null,
  defAprLh: null,
  defaultLh: false,
  discount: null,
  lhDocCode: null,
  lhFedId: null,
  lhId: null,
  resRateLh: null,
  snIntRateLh: null,
  withRecourse: false,
};

const useCtxState = () => {
  const navigate = useNavigate();
  const compId = useAuthSelector((s) => s.compId);
  const [lienholders, setLienholders] = useState<Lienholders[] | undefined>(undefined);
  const [activeLienholder, setActiveLienholder] = useState<Lienholders | undefined>(undefined);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const match = useMatch({ path: "/:root/:viewRoot/:currentView/:id" });
  const lienholderId =
    match?.params?.id && (match.params.id === "new" ? 0 : Number.parseInt(match.params.id));
  const reloadTrigger = useMatch({
    path: `/settings/company-management/lien-holders`,
  })?.pathname;

  const lienholdersForm = useForm<Lienholders>({
    defaultValues, // Pass the default values for the form
    mode: "onChange", // Set the validation mode to "onChange" or any other mode as required
  });

  const { reset, getValues } = lienholdersForm;

  const lienholdersRows = useReq(async () => {
    if (!compId) return;
    return await companyService.getLienholdersSettings(compId);
  });

  const loadLienholdersList = async () => {
    const lienholdersData = lienholdersRows.value?.sort((a, b) => a.recId! - b.recId!);
    if (!lienholdersData || !Array.isArray(lienholdersData)) {
      setLienholders([]);
      setActiveLienholder(undefined);
      return;
    }
    setLienholders(lienholdersData);

    if (lienholderId) {
      var lienholder = lienholdersData.find((lienholder) => lienholder.recId == lienholderId);
      setActiveLienholder(lienholder);
      reset(lienholder);
    }

    if (lienholdersData.length > 0 && lienholdersData[0]) {
      const currentActiveRow =
        lienholdersData.find((s) => activeLienholder?.recId === s.recId) ?? lienholdersData[0];
      setActiveLienholder(currentActiveRow);
    } else {
      setActiveLienholder(undefined);
    }
  };

  const handleRowClick = (recId: number | null) => {
    if (!lienholders) return;

    const selectedLienholder = lienholders.find((lienholder) => lienholder.recId === recId);

    if (selectedLienholder) {
      // Dynamically pick only the fields in CompanyAddOnsTaxForm
      const limitedLienholder = Object.keys(defaultValues).reduce((acc, key) => {
        const fieldKey = key as keyof Lienholders;

        // Check if field exists in selectedProfile and only assign if present
        if (fieldKey in selectedLienholder && selectedLienholder[fieldKey] !== undefined) {
          //@ts-ignore
          acc[fieldKey] = selectedLienholder[fieldKey] as Lienholders[keyof Lienholders]; // Type assertion
        }

        return acc;
      }, {} as Partial<Lienholders>); // Explicitly typing acc as Partial<CompanyAddOnsTaxForm>

      // Safely reset with the limited profile and update activeProfile
      reset(limitedLienholder);
      setActiveLienholder(limitedLienholder as Lienholders); // Safely cast the result
      navigate(`${recId}`);
    } else {
      console.warn(`Profile with recId ${recId} not found`);
    }
  };

  useEffect(() => {
    lienholdersRows.load();
  }, [compId, reloadTrigger]);

  useEffect(() => {
    loadLienholdersList();
  }, [lienholdersRows.value]);

  useEffect(() => {
    if (lienholdersRows.value) {
      var lienholder = lienholdersRows.value.find((lienholder) => lienholder.recId == lienholderId);
      if (lienholder) {
        setActiveLienholder(lienholder);
        reset(lienholder);
        return;
      }
    }

    setActiveLienholder(undefined);
    reset(defaultValues);
  }, [lienholderId]);

  return {
    isLoading: lienholdersRows.isLoading,
    resetToDefault: () => reset(defaultValues),
    lienholders,
    setLienholders,
    activeLienholder,
    setActiveLienholder,
    lienholdersForm,
    handleRowClick,
    isConfirmOpen,
    setIsConfirmOpen,
    isSubmitting,
    setIsSubmitting,
    lienholdersRows,
    loadLienholdersList,
    loadLienHolders: lienholdersRows,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type LienholdersCtx = ICtx;
export const useLienholdersCtx = useCtxFactory(Ctx);

const LienHolderProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default LienHolderProvider;
