import { FC } from 'react';
import { Call } from '@/services/customerService';
import { DateFormat, extractTextFromHtmlString, formatDate } from '@/utils/helpers/general';
import { Icons } from '@/components/icons';
import StandardizedActivityCard from '../StandardizedActivityCard';
import styles from '../activityPanel.module.scss';

const LogCallActivityCard: FC<{activity:Call, callerPhoneNumber:string}> = ({
  activity, 
  callerPhoneNumber
}) => {
  const phone = activity.phoneNumber || "Unknown Phone Number";

  return (
    <StandardizedActivityCard
      icon={<Icons.Message />}
      timestamp={formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
    >
      <div className={styles.messageHeader}>
        <div className={styles.type}>
          {`Call from ${activity.employeeName} to ${activity.toName}${phone}`}
        </div>
      </div>
      <div>{extractTextFromHtmlString(activity.body)}</div>
    </StandardizedActivityCard>
  );
};

export default LogCallActivityCard;