import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// services
import { accountsService } from "@/services/accountsService";
import { inventoryService } from "@/services/inventoryService";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useValidatedForm from "@/utils/forms/useValidatedForm";
import validateReqBody from "@/utils/forms/validateReqBody";
import { useSNReCalc } from "./utils";
import { getNumber } from "@/utils/helpers/general";
import useReq from "@/utils/useReq";
// interfaces
import { VendorListItem } from "@/interfaces/Vendors";
import { SidenoteDetail } from "@/interfaces/Accounts";
import { SidenoteForm, SidenoteReq } from "./forms";
import { PaymentInterval } from "@/enums/payment";

type AddLabelKey = `addLab${number}`;

const useCtxState = () => {
  const navigate = useNavigate();
  const sidenoteMatch = useMatch({ path: "/accounts/:colRecId/sidenotes/:sidenoteRecId" });

  const sidenoteRecId = getNumber(sidenoteMatch?.params?.sidenoteRecId);
  const colRecId = getNumber(sidenoteMatch?.params?.colRecId);
  const isNew = sidenoteMatch?.params?.sidenoteRecId === "new";

  const accountInfo = useAccountSelector((s) => s.accountInformation);
  const appRecId = getNumber(accountInfo?.appRecId);
  const compId = getNumber(accountInfo?.compId);
  const stockNum = accountInfo?.stockNum;
  const [pmtAmtError, setPmtAmtError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sidenoteRes, setSidenoteRes] = useState<SidenoteDetail[]>([]);
  /** Uses interface `SidenoteAddOns` */
  const sidenoteAddOnReq = useReq(async () => await accountsService.getSidenoteAddOns(compId));
  const sidenoteAddOns = sidenoteAddOnReq.value;
  const [sidenoteTypeOptions, setSidenoteTypeOptions] = useState<string[]>([]);
  const [vendorsList, setVendorsList] = useState<VendorListItem[]>([]);

  const origActiveSn = sidenoteRes.find((r) => sidenoteRecId === r.recId) || null;
  const isPastDue = origActiveSn?.pmtDue?.isAfter(origActiveSn?.nextDueDate);
  const nextDueDate = isPastDue ? origActiveSn?.pmtDue : origActiveSn?.nextDueDate;
  const activeSidenote = { ...origActiveSn, nextDueDate };

  const newSnNumber =
    sidenoteRes.length > 0 ? `S${sidenoteRes.length + 1}-${stockNum}` : `SN-${stockNum}`;
  // @todo memoize large object
  const defaultValues = {
    ...activeSidenote,
    addOn1: activeSidenote?.addOn1 ?? sidenoteAddOns?.price1,
    addOn2: activeSidenote?.addOn2 ?? sidenoteAddOns?.price2,
    addOn3: activeSidenote?.addOn3 ?? sidenoteAddOns?.price3,
    addOn4: activeSidenote?.addOn4 ?? sidenoteAddOns?.price4,
    addOn5: activeSidenote?.addOn5 ?? sidenoteAddOns?.price5,
    addOn6: activeSidenote?.addOn6 ?? sidenoteAddOns?.price6,
    addOn7: activeSidenote?.addOn7 ?? sidenoteAddOns?.price7,
    addOn8: activeSidenote?.addOn8 ?? sidenoteAddOns?.price8,
    addOn9: activeSidenote?.addOn9 ?? sidenoteAddOns?.price9,
    addOn10: activeSidenote?.addOn10 ?? sidenoteAddOns?.price10,
    //
    intRate: activeSidenote?.intRate ?? sidenoteAddOns?.snIntRate,
    snNumber: activeSidenote?.snNumber ?? newSnNumber,
    pmtSched: activeSidenote?.pmtSched as PaymentInterval, // @note activeSidenote?.pmtSched has incorrect type `string`
  };
  const formState = useValidatedForm(SidenoteForm, defaultValues);
  const form = formState.form;

  const addOnLabels: { [k: AddLabelKey]: string | undefined } = {
    addLab1: activeSidenote?.addLab1 ?? sidenoteAddOns?.add1 ?? "",
    addLab2: activeSidenote?.addLab2 ?? sidenoteAddOns?.add2 ?? "",
    addLab3: activeSidenote?.addLab3 ?? sidenoteAddOns?.add3 ?? "",
    addLab4: activeSidenote?.addLab4 ?? sidenoteAddOns?.add4 ?? "",
    addLab5: activeSidenote?.addLab5 ?? sidenoteAddOns?.add5 ?? "",
    addLab6: activeSidenote?.addLab6 ?? sidenoteAddOns?.add6 ?? "",
    addLab7: activeSidenote?.addLab7 ?? sidenoteAddOns?.add7 ?? "",
    addLab8: activeSidenote?.addLab8 ?? sidenoteAddOns?.add8 ?? "",
    addLab9: activeSidenote?.addLab9 ?? sidenoteAddOns?.add9 ?? "",
    addLab10: activeSidenote?.addLab10 ?? sidenoteAddOns?.add10 ?? "",
  };
  const reqBody = validateReqBody(SidenoteReq, {
    ...form,
    ...addOnLabels,
    pmtDue: form?.nextDueDate,
    colRecId,
    compId,
    acctAppRecId: appRecId,
  });

  // @todo single use - move to `SidenoteForm`
  const handleSubmit = async () => {
    if (!reqBody.reqBody|| pmtAmtError) {
      console.warn("Invalid payload:", { reqBody: reqBody.validation.error, form });
      return;
    }
    try {
      setIsSubmitting(true);
      const snRecId = await accountsService.postSidenote(reqBody.reqBody);
      if (typeof snRecId === "number") {
        sidenoteAddOnReq.load();
        loadSidenoteTypeOptions();
        await loadSidenoteHistory();
        navigate(`${snRecId}`);
      } else {
        console.error("Error posting sidenote", snRecId);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  // @todo single use - move to `SidenoteForm`
  const handleCancel = async () => formState.resetToDefault({}, true);
  // @todo single use - move to `SidenoteForm`
  const handleDelete = async () => {
    if (!activeSidenote?.appRecId) return;
    try {
      setIsSubmitting(true);
      const snRecId = await accountsService.deleteSidenote(activeSidenote.appRecId);
      if (snRecId) {
        sidenoteAddOnReq.load(); // @todo remove - unnecessary. Value only changes when `compId` changes.
        loadSidenoteTypeOptions();
        await loadSidenoteHistory();
        navigate("./");
      } else {
        console.error("Error deleting sidenote", activeSidenote.appRecId);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  /** @deprecated convert to useReq */
  const loadSidenoteHistory = async () => {
    if (!appRecId) return;
    try {
      const res = await accountsService.getSidenotes(appRecId);
      const sidenoteList = res?.map((v) => {
        return {
          ...v,
          pmtDue: dayjs(v.pmtDue),
          snDate: dayjs(v.snDate),
          finalDate: dayjs(v.finalDate),
          nextDueDate: dayjs(v.nextDueDate),
        };
      });
      setSidenoteRes(sidenoteList ?? []);
    } catch {}
  };
  /** @deprecated convert to useReq */
  const getVendorsList = async () => {
    try {
      const res = await inventoryService.getVendors();
      setVendorsList(res ?? []);
    } catch {}
  };
  /** @deprecated convert to useReq */
  const loadSidenoteTypeOptions = async () => {
    if (!compId) return;
    try {
      const res = await accountsService.getSidenoteTypeOptions(compId);
      setSidenoteTypeOptions(res ?? []);
    } catch {}
  };

  // prettier-ignore
  const { amountFinanced, final, finalduedate, financeCharge, firstpmtdue, numpmts, plan1, plan2, pmtamt, tsp } = useSNReCalc(form, isNew);

  // @note these vars are only used for dep. arr.
  // prettier-ignore
  const { addOn1, addOn2, addOn3, addOn4, addOn5, addOn6, addOn7, addOn8, addOn9, addOn10, snDown, intRate, pmtDue, pmtSched, pmtAmt } = form;
  // @todo move to separate hook
  useEffect(
    () => {
      if (isNew) {
        formState.setField("nextDueDate", dayjs(firstpmtdue));
        formState.setField("snTotal", tsp);
        formState.setField("numPmts", numpmts);
        
        const calculatedPmtAmt = pmtamt;
        const calculatedAmountFinanced = amountFinanced;
        
        if (!form.pmtAmt) {
          formState.setField("pmtAmt", pmtamt);
        }
        
        formState.setField("snFinChg", financeCharge);
        formState.setField("finalDate", finalduedate);
        formState.setField("finalAmt", final);
        formState.setField("snAmtFin", amountFinanced);
      }
    },
    // prettier-ignore
    [ addOn1, addOn2, addOn3, addOn4, addOn5, addOn6, addOn7, addOn8, addOn9, addOn10, snDown, intRate, pmtDue, pmtSched, pmtAmt ]
  );

  useEffect(() => {
    if (isNew && form.pmtAmt && form.snAmtFin > 0) {
      if (form.pmtAmt > form.snAmtFin) {
        setPmtAmtError("Payment cannot exceed amount financed");
      } else {
        setPmtAmtError(null);
      }
    } else {
      setPmtAmtError(null);
    }
  }, [form.pmtAmt, form.snAmtFin, isNew]);

  useEffect(() => {
    getVendorsList();
    loadSidenoteHistory();
  }, [appRecId, compId]);
  useEffect(() => {
    loadSidenoteTypeOptions();
    sidenoteAddOnReq.load();
  }, [compId]);
  // @todo remove effect after useValidatedForm - defaultValues init logic is fixed
  useEffect(() => {
    !isNew && formState.resetToDefault({ ...form, ...defaultValues });

    isNew && !activeSidenote.recId && formState.resetToDefault(defaultValues, true);
  }, [activeSidenote.recId, isNew]);

  return {
    // Layout states
    isSubmitting,
    setIsSubmitting,
    // Req. states
    sidenoteRes,
    vendorsList,
    sidenoteAddOns,
    sidenoteTypeOptions,
    setSidenoteTypeOptions,
    pmtAmtError,
    //
    formState,
    reqBody,
    //
    /** @deprecated single use - move to `SidenoteForm` */
    handleSubmit,
    /** @deprecated single use - move to `SidenoteForm` */
    handleCancel,
    /** @deprecated single use - move to `SidenoteForm` */
    handleDelete,

    // Getters
    plan1,
    plan2,
    activeSidenote,
    isNew,
    addOnLabels,
  };
};

export type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);
export const useSidenoteCtx = useCtxFactory(Ctx);
const SidenoteProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default SidenoteProvider;
