import { FC } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, PhoneInput, TextInput, DropdownInput, NumberInput } from "@/components";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import { usaStateCodes } from "@/general/regions";
import { useLienholdersCtx } from "../LienHolderProvider";
import AddressFields from "./AddressFields";
import { Lienholders } from "@/interfaces/Lienholders";
import { getAddressFromGoogleMaps } from "@/utils/helpers/general";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";

const buildFullAddress = (
  lienHolder: Pick<Lienholders, "address" | "city" | "state" | "zip"> | undefined
): string | null => {
  if (!lienHolder?.address) return null;

  return `${lienHolder?.address}, ${lienHolder?.city}, ${lienHolder?.state} ${lienHolder?.zip}`;
};

const LeftContainer: FC = () => {
  const { control, formState, setValue, watch } = useLienholdersCtx((s) => s.lienholdersForm);
  const address = watch("address");
  const city = watch("city");
  const state = watch("state");
  const zip = watch("zip");

  const fullAddress = buildFullAddress({ address, city, state, zip }) ?? "";
  const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);

    setValue("address", fullAddress.address);
    setValue("city", fullAddress.city);
    setValue("state", fullAddress.state);
    setValue("zip", fullAddress.zip);
  };

  return (
    <SectionColumn p={0}>
      <Controller
        name={"name"}
        control={control}
        rules={{ required: "Name is required" }}
        render={({ field }) => (
          <TextInput {...field} label="Name" required errors={formState.errors?.name?.message} />
        )}
      />
      <Controller
        name={"abrName"}
        control={control}
        render={({ field }) => <TextInput label="Short Name" {...field} />}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressAutocomplete
            label="Address"
            onPlaceSelected={handlePlaceSelected}
            fullAddress={fullAddress}
            {...field}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => <TextInput label="City" {...field} />}
      />
      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <DropdownInput suggest id="state" label="State" data={usaStateCodes} {...field} />
        )}
      />
      <Controller
        name="zip"
        control={control}
        render={({ field }) => <TextInput label="Zip Code" {...field} />}
      />
      <Controller
        name={"email"}
        control={control}
        render={({ field }) => <TextInput label="Email Address" {...field} />}
      />
      <Controller
        name={"defAprLh"}
        control={control}
        render={({ field }) => <NumberInput label="Default APR" {...field} isPercentageLarge={true} />}
      />
      <Controller
        name={"discount"}
        control={control}
        render={({ field }) => <CurrencyInput label="Discount" {...field} />}
      />
      <Controller
        name={"lhDocCode"}
        control={control}
        render={({ field }) => <TextInput label="Doc Code" {...field} />}
      />
      <Controller
        name={"lhFedId"}
        control={control}
        render={({ field }) => <TextInput label="Federal ID" {...field} />}
      />
      <Controller
        name={"lhId"}
        control={control}
        render={({ field }) => <TextInput label="Lienholder ID" {...field} />}
      />
      <Controller
        name={"resRateLh"}
        control={control}
        render={({ field }) => 
        <NumberInput label="Residual Rate" {...field} isPercentageLarge={true} />}
      />
      <Controller
        name={"snIntRateLh"}
        control={control}
        render={({ field }) => <NumberInput label="Sidenote Interest Rate" {...field} isPercentageLarge={true} />}
      />
    </SectionColumn>
  );
};

export default LeftContainer;
