import { FC } from 'react';
import dayjs from 'dayjs';
// utils
import { formatCurrency } from '@/utils/helpers/general';
// interfaces
import { LeasingRateForm } from '@/interfaces/LeaseRate';

const formatPercentage = (value: number | null | undefined): string => {
  if (value === null || value === undefined) return '';
  return `${value.toFixed(2)}%`;
};

interface LeasingRatesPdfLayoutProps {
  leasingRateData: LeasingRateForm;
  companyName?: string; // Pass the company name as a prop
  showNewCars?: boolean;
}

const LeasingRatesPdfLayout: FC<LeasingRatesPdfLayoutProps> = ({ 
  leasingRateData, 
  companyName = 'Company Name', // Default value
  showNewCars = true // Default to true for backward compatibility
}) => {  
  const currentDate = dayjs().format('MM/DD/YYYY');
  
  return (
    <>
      <style>{`
        body {
          font-size: 13px;
          font-family: Arial, Helvetica, sans-serif;
        }
        .demo {
          width: 100%;
          height: 25%;
          border: none;
        }
        .demo th {
          border: none;
        }
        .demo td {
          border: none;
        }
        td {
          vertical-align: top;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 15px;
        }
        th, td {
          padding: 5px;
        }
      `}</style>
      <body>
        <br />
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '16pt' }}><strong>{companyName || 'Company Name'}</strong></span>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '12pt' }}><strong>DEALER CONTROLLED LEASING RATES SHEET</strong></span>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '12px' }}><strong>{currentDate}</strong></span>
        </div>
        <br />

        {/* FEES Table */}
        <table style={{ borderCollapse: 'collapse', width: '100%', height: '148px', borderWidth: '0px' }} border={1}>
          <colgroup>
            <col style={{ width: '23.484%' }} />
            <col style={{ width: '12.5659%' }} />
            <col style={{ width: '13.895%' }} />
            <col style={{ width: '16.6483%' }} />
            <col style={{ width: '16.6483%' }} />
            <col style={{ width: '16.6483%' }} />
          </colgroup>
          <tbody>
            <tr style={{ height: '22px', borderStyle: 'none' }}>
              <td style={{ textAlign: 'center', height: '22px', borderWidth: '0px', backgroundColor: '#cacaca' }} colSpan={6}>
                <span style={{ fontSize: '14pt' }}><strong>FEES</strong></span>
              </td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}>&nbsp;</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>12 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>24 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>36 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>42 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>48 Months</strong></td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Vehicle Maintenance Plan</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_Main)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_Main)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_Main)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_Main)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_Main)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Limited Warranty</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_LW)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_LW)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_LW)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_LW)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_LW)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>GAP</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_GAP)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_GAP)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_GAP)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_GAP)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_GAP)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Credit Education</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_CrRep)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_CrRep)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_CrRep)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_CrRep)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_CrRep)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Tracker</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_Tracker)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_Tracker)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_Tracker)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_Tracker)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_Tracker)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Bank Fee</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_BankFee)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_BankFee)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_BankFee)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_BankFee)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_BankFee)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Total Fees</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_Total)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_Total)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_Total)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_Total)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_Total)}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Notes About Fees:</span>
        <br />
        <span>{leasingRateData.notesFees}</span>
        <br />

        {/* USED CAR Rate Sheet */}
        <table style={{ borderCollapse: 'collapse', width: '100%', height: '148px', borderWidth: '0px' }} border={1}>
          <colgroup>
            <col style={{ width: '23.484%' }} />
            <col style={{ width: '12.5659%' }} />
            <col style={{ width: '13.895%' }} />
            <col style={{ width: '16.6483%' }} />
            <col style={{ width: '16.6483%' }} />
            <col style={{ width: '16.6483%' }} />
          </colgroup>
          <tbody>
            <tr style={{ height: '22px', borderStyle: 'none' }}>
              <td style={{ textAlign: 'center', height: '22px', borderWidth: '0px', backgroundColor: '#cacaca' }} colSpan={6}>
                <span style={{ fontSize: '14pt' }}><strong>{leasingRateData.usedHeader || 'USED CAR RATE SHEET'}</strong></span>
              </td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}>&nbsp;</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>12 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>24 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>36 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>42 Months</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>48 Months</strong></td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Residual Percentage</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR1_UsedMilesPercent)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR2_UsedMilesPercent)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR3_UsedMilesPercent)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR35_UsedMilesPercent)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR4_UsedMilesPercent)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Excess Mileage Charge</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_UsedMilesPer)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_UsedMilesPer)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_UsedMilesPer)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_UsedMilesPer)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_UsedMilesPer)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Disposition Fee</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_UsedDisp)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_UsedDisp)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_UsedDisp)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_UsedDisp)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_UsedDisp)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Early Payoff or Termination Fee</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_UsedELT)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_UsedELT)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_UsedELT)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_UsedELT)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_UsedELT)}</td>
            </tr>
            <tr style={{ height: '18px' }}>
              <td style={{ height: '18px', borderWidth: '0px' }}><strong>Purchase Option at Lease End</strong></td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_UsedPurOpt)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_UsedPurOpt)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_UsedPurOpt)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_UsedPurOpt)}</td>
              <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_UsedPurOpt)}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />

        {/* NEW CAR Rate Sheet - Only shown if showNewCars is true */}
        {showNewCars && (
          <table style={{ borderCollapse: 'collapse', width: '100%', height: '148px', borderWidth: '0px' }} border={1}>
            <colgroup>
              <col style={{ width: '23.484%' }} />
              <col style={{ width: '12.5659%' }} />
              <col style={{ width: '13.895%' }} />
              <col style={{ width: '16.6483%' }} />
              <col style={{ width: '16.6483%' }} />
              <col style={{ width: '16.6483%' }} />
            </colgroup>
            <tbody>
              <tr style={{ height: '22px', borderStyle: 'none' }}>
                <td style={{ textAlign: 'center', height: '22px', borderWidth: '0px', backgroundColor: '#cacaca' }} colSpan={6}>
                  <span style={{ fontSize: '14pt' }}><strong>{leasingRateData.newHeader || 'NEW CAR RATE SHEET'}</strong></span>
                </td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}>&nbsp;</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>12 Months</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>24 Months</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>36 Months</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>42 Months</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}><strong>48 Months</strong></td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}><strong>Residual Percentage</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR1_NewMilesPercent)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR2_NewMilesPercent)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR3_NewMilesPercent)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR35_NewMilesPercent)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatPercentage(leasingRateData.yR4_NewMilesPercent)}</td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}><strong>Excess Mileage Charge</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_NewMilesPer)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_NewMilesPer)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_NewMilesPer)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_NewMilesPer)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_NewMilesPer)}</td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}><strong>Disposition Fee</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_NewDisp)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_NewDisp)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_NewDisp)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_NewDisp)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_NewDisp)}</td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}><strong>Early Payoff or Termination Fee</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_NewELT)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_NewELT)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_NewELT)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_NewELT)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_NewELT)}</td>
              </tr>
              <tr style={{ height: '18px' }}>
                <td style={{ height: '18px', borderWidth: '0px' }}><strong>Purchase Option at Lease End</strong></td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR1_NewPurOpt)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR2_NewPurOpt)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR3_NewPurOpt)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR35_NewPurOpt)}</td>
                <td style={{ height: '18px', borderWidth: '0px', textAlign: 'right' }}>{formatCurrency(leasingRateData.yR4_NewPurOpt)}</td>
              </tr>
            </tbody>
          </table>
        )}

        <br />
        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Due at Inception Requirements:</span>
        <br />
        <span>{leasingRateData.notesDueInception}</span>
        <br />
        <br />
        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>General Notes:</span>
        <br />
        <span>{leasingRateData.notesGeneral}</span>
      </body>
    </>
  );
};

export default LeasingRatesPdfLayout;