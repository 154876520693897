import { FC } from 'react';
import { Outlet } from 'react-router-dom';
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from '@mui/material/Box';
// kendo
import { ListView, ListViewItemProps } from '@progress/kendo-react-all';
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
// components
import UserInfo from './UserInfo';
// state
import { useAppDispatch } from '@/store/store';
import { accountActions, useAccountSelector } from '@/features/Accounts/accountSlice';
import useAccountDetails from '../../useAccountDetails';
import { useAcctDetailsCtx } from "../../AcctDetailProvider";
// interfaces
import { QuickActions } from "@/enums/general";
// style
import styles from '../../AccountDetail.module.scss';
import { DCS_BLUE } from "@/mui/theme/colors";

const QuickActionItemView: FC<ListViewItemProps> = ({ dataItem }) => (
  <div onClick={dataItem.onClick} className={styles.customerAdminItem}>
    {dataItem.text}
  </div>
);

/** ###  */
const RightContainer: FC = () => {
  const dispatch = useAppDispatch();

  const { quickActionList } = useAccountDetails();
  const quickActionsListOpen = useAccountSelector((s) => s.quickActionsListOpen);
  const optStatus = useAcctDetailsCtx((s) => s.optStatus);
  const optInAuthorization = useAcctDetailsCtx((s) => s.optInAuthorization);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const accountHasCoBuyer = accountInformation?.coBuyer && accountInformation?.coBuyer?.appBuyerRecId && accountInformation?.coBuyer?.firstName ? true : false;


  const filterQuickActions = (actionList: { text: QuickActions; onClick: () => void }[]) => {
    if (optInAuthorization) {
      if (optStatus === 'accepted') {
        return actionList.filter(
          (item: { text: QuickActions; onClick: () => void }) => item.text !== 'Send Text to Opt In'
        );
      } else if (optStatus === 'unsent') {
        return actionList.filter(
          (item: { text: QuickActions; onClick: () => void }) => item.text !== 'Send Text to Pay'
        );
      } else {
        return actionList.filter(
          (item: { text: QuickActions; onClick: () => void }) =>
            item.text !== 'Send Text to Pay' && item.text !== 'Send Text to Opt In'
        );
      }
    } else {
      return actionList.filter(
        (item: { text: QuickActions; onClick: () => void }) => item.text !== 'Send Text to Opt In'
      );
    }
  };

  return (
    <Grid 
      container 
      direction="column" 
      wrap="nowrap" 
      sx={{ 
        flex: 1, 
        gap: "20px",
        display: 'flex',
        height: '100%',
      }}
    >
      {/* Fixed header with user info */}
      <div className={styles.userCard}>
        <div className={styles.users}>
          <UserInfo buyerType="buyer" setStyleToggle={accountHasCoBuyer} />
          <UserInfo buyerType="coBuyer" setStyleToggle={accountHasCoBuyer} />
        </div>
        <div>
          <DropDownButton
            label="Quick Actions"
            buttonStyles={{
              borderRadius: '16px',
              boxShadow: '0px 2px 4px 0px rgba(41, 70, 149, 0.50)',
            }}
            popUpProps={{
              popupAlign: { vertical: 'top', horizontal: 'right' },
              anchorAlign: { vertical: 'bottom', horizontal: 'right' },
            }}
            setOpen={(open: boolean) =>
              dispatch(accountActions.setQuickActionsListOpen(open))
            }
            open={quickActionsListOpen}
          >
            <Grid
              sx={{
                backgroundColor: "#fff",
                border: `1px solid ${DCS_BLUE}`,
                borderRadius: "0.75em",
                p: "0.7em 0",
              }}
            >
              <ListView data={filterQuickActions(quickActionList)} item={QuickActionItemView} />
            </Grid>
          </DropDownButton>
        </div>
      </div>

      {/* Scrollable content area - just wrap the Outlet in a scrollable Box */}
      <Box
        sx={{
          flex: 1,
          overflow: 'auto', // This makes only this part scrollable
          // The important part: this will prevent it from expanding beyond its container
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </Box>
    </Grid>
  );
};

export default RightContainer;