import { FC } from "react";
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { Icons } from "@/components/icons";
import StandardizedActivityCard from '../StandardizedActivityCard';
import styles from "../activityPanel.module.scss";
import { ActivityLog } from "@/interfaces";

const LogActivityCard: FC<{
  activity: ActivityLog;
  setSelectedLog: Function;
}> = ({ activity, setSelectedLog }) => {
  const logBody = activity.detailLong ? (
    <div className={styles.link} onClick={() => setSelectedLog(activity)}>
      {activity.detailShort}
    </div>
  ) : (
    <div>{activity.detailShort}</div>
  );
  
  return (
    <StandardizedActivityCard
      icon={<Icons.Message />}
      timestamp={formatDate(activity.contactOn, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
    >
      <div className={styles.messageHeader}>
        <div>{activity.transType}</div>
      </div>
      {logBody}
    </StandardizedActivityCard>
  );
};

export default LogActivityCard;