// mui
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
// utils
import { dateCellDefaultFmt, datetimeCellGetter } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { InsuranceClaim } from './interfaces';

// Custom currency formatter
const currencyFormatter = (params: any) => {
  if (params.value === null || params.value === undefined) return '';
  return `$${params.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
};

export const tableColumns: MuiColumnDef<InsuranceClaim>[] = [
  {
    field: 'claimNum',
    headerName: 'Claim Number',
    flex: 1,
    minWidth: 130,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'claimDate',
    headerName: 'Claim Date',
    minWidth: 120,
    type: 'date',
    valueFormatter: dateCellDefaultFmt,
    valueGetter: datetimeCellGetter,
  },
  {
    field: 'claimStatus',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'insCName',
    headerName: 'Ins. Company',
    flex: 1,
    minWidth: 150,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'custIns',
    headerName: 'Cust Ins.',
    flex: 1,
    minWidth: 120,
    valueFormatter: (p) => (p.value?.length > 0 ? p.value : '-') ?? '-',
  },
  {
    field: 'setDate',
    headerName: 'Settlement Date',
    minWidth: 140,
    type: 'date',
    valueFormatter: dateCellDefaultFmt,
    valueGetter: datetimeCellGetter,
  },
  {
    field: 'setAmt',
    headerName: 'Settlement Amount',
    minWidth: 150,
    valueFormatter: currencyFormatter,
    align: 'right',
  },
];