import { FC, useRef, useLayoutEffect } from 'react';
import dayjs from 'dayjs';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { formatCurrency } from '@/utils/helpers/general';
import { Loader } from "@/components/loader/Loader";
// style
import styles from './LeftContainer.module.scss';

const AcctMiscCard: FC = () => {
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const accountInformation = useAccountSelector((s) => s.accountInformation)!;
  
  // Function to resize text to fit container
  useLayoutEffect(() => {
    const resizeText = () => {
      if (!textRef.current || !containerRef.current) return;
      
      const container = containerRef.current;
      const textElement = textRef.current;
      
      // Reset font size to starting point
      textElement.style.fontSize = '16px';
      
      // Always measure and adjust the text size
      const containerWidth = container.offsetWidth - 16; // Account for padding
      const textWidth = textElement.scrollWidth;
      
      // Calculate the scaling ratio needed
      const ratio = containerWidth / textWidth;
      
      // Only scale down, not up
      if (ratio < 1) {
        // Apply the calculated font size (with a small buffer for safety)
        const newSize = Math.max(Math.floor(16 * ratio * 0.95), 5); // Minimum size of 5px
        textElement.style.fontSize = `${newSize}px`;
      }
    };
    
    resizeText();
    
    // Add resize listener
    window.addEventListener('resize', resizeText);
    return () => window.removeEventListener('resize', resizeText);
  }, [accountInformation]);

  if (!accountInformation) 
    return (
    <div
      className={styles.accountMiscCard}
      style={{ 
        backgroundColor: '#eff0f4',
        height: '40px',
        minHeight: '40px',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{paddingTop: '2px', paddingBottom: '2px'}}><Loader size='medium' /></div>
    </div>
  );
  
  const dueDateUtc = dayjs.utc(accountInformation.nextRegularPaymentDueDate).startOf('day');
  const todayUtc = dayjs.utc().startOf('day');
  const daysLate = todayUtc.diff(dueDateUtc, 'days');

  const isLate = daysLate > 0;
  const isLegal = !!accountInformation.legalStatus;
  const isOneDay = Math.abs(daysLate) == 1 ? "Day" : "Days"
  const dueInDays = daysLate == 0 ? 'Today' : `In ${Math.abs(daysLate)} ${isOneDay}`
  const accountMisc = [
    accountInformation.code3,
    accountInformation.status,
    isLate ? `${daysLate} ${isOneDay} Late` : `Due ${dueInDays} ${"$" + accountInformation.amountDue}`,
    accountInformation.legalStatus ? 'Legal' : '',
    accountInformation.iopStatus ? 'IOP' : '',
  ]
    .filter((misc) => !!misc)
    .join(' - ');
  const hasAmountDue = (accountInformation.amountDue ?? 0) > 0;

  const lateDue = (isLate && hasAmountDue) ? `- ${formatCurrency(accountInformation.amountDue)} Due` : "";

  return (
    <div
      ref={containerRef}
      className={styles.accountMiscCard}
      style={{ 
        backgroundColor: isLate || isLegal ? '#FFC3C3' : '#C2ECCD',
        height: '40px',
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
    >
      <div 
        ref={textRef}
        style={{
          width: '100%',
          overflow: 'hidden',
          textAlign: 'center',
          whiteSpace: 'nowrap'
        }}
      >
        {accountMisc} {lateDue}
      </div>
    </div>
  );
};

export default AcctMiscCard;