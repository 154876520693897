import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { COLOR_INACTIVE } from "@/mui/theme/colors";
// kendo
import { Tooltip } from "@progress/kendo-react-all";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// interfaces
import { AccountInformation } from "@/interfaces/Accounts";
import { BuyerTypeUnion } from "@/enums/general";
// style
import styles from "../../AccountDetail.module.scss";

// Typesafe object-key lookup
type BuyerTypeKey = keyof Pick<AccountInformation, "buyer" | "coBuyer">;

const buyerTypeLabelMap = new Map<BuyerTypeKey, BuyerTypeUnion>([
  ["buyer", "Buyer"],
  ["coBuyer", "Co-Buyer"],
]);

const UserName: FC<{ buyerType: BuyerTypeKey }> = ({ buyerType }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const buyerTypeFmt = buyerTypeLabelMap.get(buyerType);
  
  // Handle case when account information is missing
  const firstName = accountInformation?.[buyerType]?.firstName ?? "--";
  const lastName = accountInformation?.[buyerType]?.lastName ?? "--";

  return (
    <div>
      <div style={{ fontSize: "14px", color: COLOR_INACTIVE }}>{buyerTypeFmt}</div>
      {firstName} {lastName}
    </div>
  );
};

/** ### Style component for items that render in the body of the user-info header */
const BodyItem: FC<{ label: string; value: string | number }> = ({ label, value }) => {
  // Use placeholder if value is empty
  const displayValue = value !== undefined && value !== null && value !== "" ? value : "--";
  
  return (
    <div>
      <span className={styles.bold}>{label}</span> {displayValue}
    </div>
  );
};

const Body: FC<{ buyerType: BuyerTypeKey }> = ({ buyerType }) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const userInfo = accountInformation?.[buyerType] ?? null;
  const buyerTypeFmt = buyerTypeLabelMap.get(buyerType);
  
  // Use nullish coalescing to handle missing data
  const city = userInfo?.city ?? "--";
  const state = userInfo?.state ?? "--";
  const zip = userInfo?.zip ?? "--";
  
  // Only build the second address line if we have real data (not placeholders)
  const hasRealAddressData = 
    city !== "--" || 
    state !== "--" || 
    zip !== "--";
    
  const secondAddressLine = hasRealAddressData 
    ? `${city !== "--" ? city : ""}, ${state !== "--" ? state : ""} ${zip !== "--" ? zip : ""}`.trim()
    : "--";

  // @note `email` row has unique style
  const infoItems: { label: string; value: string | number }[] = [
    { label: "Cell:", value: userInfo?.cellPhone ?? "--" },
    { label: "SSN:", value: userInfo?.ssn ? `***-**-${userInfo.ssn}` : "--" },
    { label: "Address:", value: userInfo?.address ?? "--" },
    { label: "", value: secondAddressLine },
  ];

  const infoElems = infoItems.map(({ label, value }, idx) => (
    <BodyItem label={label} value={value} key={`bi-${idx}`} />
  ));

  return (
    <div className={styles.userInfo}>
      <Tooltip anchorElement="target" position="bottom">
        <div
          className={userInfo?.isEmailBlacklisted ? styles.emailBlacklist : undefined}
          title={
            userInfo?.isEmailBlacklisted
              ? userInfo.emailBlacklistReason
                ? `Email Blacklisted: ${userInfo.emailBlacklistReason}`
                : "Email is blacklisted"
              : undefined
          }
        >
          <BodyItem label="Email:" value={userInfo?.email ?? "--"} />
        </div>
      </Tooltip>
      {infoElems}
    </div>
  );
};

/** ### Layout component for top nav of account-detail - displays user info
 */
const UserInfo: FC<{ buyerType: BuyerTypeKey; setStyleToggle: boolean }> = ({
  buyerType,
  setStyleToggle,
}) => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);

  // Don't render co-buyer section if accountInformation doesn't exist
  if (buyerType === "coBuyer" && !accountInformation) {
    return <></>;
  }

  // For coBuyer, check if we should render it
  if (buyerType === "coBuyer" && accountInformation) {
    const coBuyer = accountInformation[buyerType];
    const hasCoBuyer = coBuyer?.appBuyerRecId && coBuyer?.firstName;
    if (!hasCoBuyer) return <></>;
  }

  const useCobuyerStyle = setStyleToggle ? styles.hasCobuyer : styles.default;
  const directionToggle = setStyleToggle ? "column" : "row";

  return (
    <Grid container direction={directionToggle} className={useCobuyerStyle}>
      <UserName buyerType={buyerType} />
      <Body buyerType={buyerType} />
    </Grid>
  );
};

export default UserInfo;