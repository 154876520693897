import { FC, useEffect } from 'react';
import AccountsViewTable from '../AccountsViewTable';
import TableFilters from './TableFilters';
import { useTableCtx } from '../tableState';
import { useAccountsViewCtx } from '../accountsViewState';
import { useAcctSubviewData } from './useAcctSubviewData';
import { useAcctSubviewCtx } from './acctSubviewState';
import { AcctsSubviewKey } from '../viewInfo';
import { insuranceClaimsTableColumns } from './tableColumns/insuranceclaims';
import { insuranceTableColumns } from './tableColumns/insuranceInfo';
import { accountsService } from '@/services/accountsService';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@/mui/components/buttons/MuiKendoButton';
import { Box, Tabs, Tab } from '@mui/material';

// Define the color constants used in the InsuranceTabs component
const DCS_BLUE = '#294695';
const COLOR_INACTIVE = '#757575';
const HOVER_TRANSITION_SPEED = '0.3s';

const AcctSubviewList: FC = () => {
  const accountList = useAcctSubviewData();
  const accountDataState = useAcctSubviewCtx((state) => state);
  const viewState = useAccountsViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const { initSortFields, initDateFilterFields } = viewState.subviewInfo;

  const showClaims = viewState.subview === AcctsSubviewKey.insuranceClaims;
  const navigate = useNavigate();
  const { '*': subviewPath } = useParams();
  const isInsuranceView = subviewPath === AcctsSubviewKey.insurance || 
                         subviewPath === AcctsSubviewKey.insuranceClaims;

  // Save current subview path to sessionStorage
  useEffect(() => {
    if (subviewPath) {
      sessionStorage.setItem('lastActiveSubview', subviewPath);
    }
  }, [subviewPath]);

  const getColumns = () => {
    if (isInsuranceView) {
      return showClaims ? insuranceClaimsTableColumns : insuranceTableColumns;
    }
    return viewState.subviewInfo.columns;
  };

  const getCategoryFilterOptions = () => {
    if (isInsuranceView && showClaims) {
      return ["Open", "Closed"]; // ClaimStatusKey values
    }
    return viewState.subviewInfo.categoryFilterOptions;
  };

  const getCategoryFilterField = () => {
    if (isInsuranceView && showClaims) {
      return 'claimStatus' as const;
    }
    return undefined;
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    const showClaimsValue = newValue === 1;
    const newSubview = showClaimsValue ? AcctsSubviewKey.insuranceClaims 
                               : AcctsSubviewKey.insurance;
    navigate(`/accounts/${newSubview}`);
  };

  // Reset table state when the global subview changes.
  useEffect(() => {
    tableState.resetTableState({
      sortFields: initSortFields,
      dateFilterFields: initDateFilterFields,
    });
  }, [viewState.subview]);

  return (
    <div style={{ position: 'relative' }}>
      <AccountsViewTable 
        rows={accountList} 
        columns={getColumns()}
        filterGroupComponent={
          <TableFilters 
            categoryFilterOptions={getCategoryFilterOptions()}
            categoryFilterField={getCategoryFilterField()}
          />
        } 
      />

      {isInsuranceView && (
        <div 
          className="tabs-group" 
          style={{
            position: 'absolute',
            top: '8px',
            right: '16px',
            zIndex: 1,
          }}
        >
          <Tabs
            value={showClaims ? 1 : 0}
            onChange={handleTabChange}
            sx={{
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            <Tab
              label="Coverage"
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: !showClaims ? 700 : 500,
                color: !showClaims ? DCS_BLUE : COLOR_INACTIVE,
                borderTop: '2px solid transparent',
                borderBottom: !showClaims 
                  ? `2px solid ${DCS_BLUE}` 
                  : '2px solid transparent',
                transitionProperty: 'border-bottom-color, font-weight',
                transitionDuration: HOVER_TRANSITION_SPEED,
                '&:hover': {
                  borderBottom: `2px solid ${DCS_BLUE}`,
                  cursor: 'pointer',
                },
              }}
            />
            <Tab
              label="Claims"
              sx={{
                textTransform: 'none',
                fontSize: 16,
                fontWeight: showClaims ? 700 : 500,
                color: showClaims ? DCS_BLUE : COLOR_INACTIVE,
                borderTop: '2px solid transparent',
                borderBottom: showClaims 
                  ? `2px solid ${DCS_BLUE}` 
                  : '2px solid transparent',
                transitionProperty: 'border-bottom-color, font-weight',
                transitionDuration: HOVER_TRANSITION_SPEED,
                '&:hover': {
                  borderBottom: `2px solid ${DCS_BLUE}`,
                  cursor: 'pointer',
                },
              }}
            />
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default AcctSubviewList;