import { ScannedDocument } from '@/interfaces';
import { FC, useEffect, useState } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';
// kendo
import { Loader } from '@/components/loader/Loader';
import { Button } from '@/components/button/Button';
import { Modal } from '@/components/modals/Modal';
import { Spacer } from '@/components/spacer/Spacer';
import { TextInput } from '@/components/inputs/text/TextInput';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
// components
import { FileInput } from '@/components/inputs/fileInput/FileInput';
import PhotoGallery from './ImageGallery';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
import { useAuthSelector } from '@/features/auth/authSlice';
// utils
import { salesService } from '@/services/salesService';
import { systemService } from '@/services/systemService';
import { useInventoryDetailCtx } from '../../InventoryDetailProvider';
// interfaces
// style
import inventoryPageStyles from '../InventoryPageStyles.module.scss';
import scannedStyles from './ScannedDocuments.module.scss';

// Same interface as in PhotoGallery.tsx
interface Photo {
  url: string;
  title: string;
  recId: number;
}

// Extension for API response
interface PhotoApiResponse extends ScannedDocument {
  recId: number;
}

/** @deprecated When implemented, pull scanned-documents state from `useScannedDocumentsCtx` - see components/hooks [PrintDocuments, Photos, ScannedDocuments, useSalesDocuments, useDocumentsForm] */
const Photos: FC = () => {
  const { accountInformation } = useAccountSelector((s) => s);
  const vehicle = useInventoryDetailCtx((s) => s.inventoryVehicle);
  const { orgId, compId, locId } = useAuthSelector((s) => s);
  const [scannedDocModalOpen, setScannedDocModalOpen] = useState(false);
  const [scannedDocsList, setScannedDocsList] = useState<ScannedDocument[]>([]);
  const [scannedFileDesc, setScannedFileDesc] = useState('');
  const [scannedFile, setScannedFile] = useState<File | null>(null);
  const [uploadingScannedFile, setUploadingScannedFile] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchPhotos = async (photosList: ScannedDocument[],orgId: number
  ): Promise<{ url: string }[]> => {
    if (!photosList.length) return [];

    const photosObject = photosList.map((file) => {
      return {
        orgId: orgId!,
        containerName: 'uploads',
        blobName: file.fileNameCloud,
      };
    });
    const photoInfoList = await systemService.getBlobReadUrls(photosObject);
    return photoInfoList;
  };

  const loadScannedDocs = async () => {
    if (!vehicle) {
      return;
    }
    setLoading(true);
    try {
      if (Number(vehicle?.recId) < 1) {
        return;
      }
      // Cast the response to PhotoApiResponse[]
      const res = (await salesService.getPhotosByVehicle(
        orgId!,
        Number(vehicle?.recId)
      )) as PhotoApiResponse[];

      if (res && res.length > 0) {
        setScannedDocsList(res);
        const photoInfoList = await fetchPhotos(res, orgId!);

        // Map photos with recId
        const newPhotos: Photo[] = photoInfoList.map((photo, index) => {
          const foundDoc = res[index];
          return {
            url: photo.url,
            title: foundDoc?.fileDesc || 'Vehicle Photo',
            recId: foundDoc?.recId || 0,
          };
        });

        setPhotos(newPhotos);
      } else {
        setScannedDocsList([]);
        setPhotos([]);
      }
    } catch (error) {
      console.error('Error loading vehicle photos:', error);
    } finally {
      setLoading(false);
    }
  };

  const uploadScannedFile = async () => {
    if (!scannedFile) {
      setFileInputError(true);
      return;
    }
    if (!scannedFileDesc) {
      setFileDescriptionError(true);
      return;
    }

    const payload = {
      file: scannedFile!,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId: accountInformation?.appRecId ?? 0,
      vehRecId: vehicle?.recId ?? 0,
      fileDesc: scannedFileDesc,
    };

    setUploadingScannedFile(true);
    try {
      await salesService.uploadScannedDocument(payload);
      await loadScannedDocs(); // Ensure the list is refreshed after upload
      setScannedFile(null);
      setScannedFileDesc('');
      setScannedDocModalOpen(false);
    } catch (error) {
      console.error('Error uploading photo:', error);
    } finally {
      setUploadingScannedFile(false);
    }
  };

  // Delete function that will be passed to PhotoGallery
  const deletePhoto = async (photoId: number) => {
    try {
      await salesService.deleteScannedDoc(orgId!, photoId);
      await loadScannedDocs();
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleFileDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileDescriptionError(false);
    setScannedFileDesc(e.target.value);
  };

  const handleScannedDocInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.target.files?.[0];
    if (!file) return;
    setFileInputError(false);
    setScannedFile(file);
  };

  useEffect(() => {
    loadScannedDocs();
  }, [orgId, vehicle?.recId]);

  return (
    <section className={inventoryPageStyles.multipleSectionsChild}>
      <Grid container className={scannedStyles.container}>
        <Grid container direction="column" flex={1} className={scannedStyles.body}>
          <section className={scannedStyles.section}>
            <SectionHeader showBar title="Photos" />
            <div className={scannedStyles.buttonContainer}>
              <Button label="Upload New" onClick={() => setScannedDocModalOpen(true)} />
            </div>
            <Spacer size={25} />
            <Grid container direction="row" wrap="wrap">
              {loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    p: 5,
                  }}
                >
                  <Loader size="large" />
                </Box>
              ) : (
                <PhotoGallery photos={photos} onDelete={deletePhoto} />
              )}
            </Grid>
          </section>
        </Grid>
        {scannedDocModalOpen && (
          <Modal
            isOpen={true}
            onCloseButtonClick={() => setScannedDocModalOpen(false)}
            closeButton
            centerModal
            title="Upload New Photo"
            panelStyle={{ width: 600 }}
          >
            <div>
              <Spacer size={5} />
              <FileInput
                onChange={handleScannedDocInputChange}
                fileName={scannedFile?.name}
                errors={fileInputError}
                accept="image/*"
              />
              <Spacer size={20} />
              <TextInput
                required
                label="Photo Description"
                value={scannedFileDesc}
                horizontalLabel={false}
                onChange={handleFileDescriptionChange}
                errors={fileDescriptionError}
              />
              <Spacer size={20} />
              <div style={{ width: 100, marginLeft: 'auto' }}>
                <Button label="Submit" loading={uploadingScannedFile} onClick={uploadScannedFile} />
              </div>
            </div>
          </Modal>
        )}
      </Grid>
    </section>
  );
};

export default Photos;