import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
// utils
// interfaces
import { getRouteParamNum } from "@/utils/routing/formatting";
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import dayjs from "dayjs";
import useReq from "@/utils/useReq";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { requestRewriteService } from "@/services/requestRewriteService";
import { PreviousAmendments, RequestForRewriteForm, RequestForRewriteRes } from "@/interfaces/RequestRewrite";
import useValidatedForm from "@/utils/forms/useValidatedForm";
import { useAuthCtx } from "@/AppProviders/AuthProvider";
import { useParams } from "react-router-dom";

const defaultValues: RequestForRewriteForm = {
  requestedDate: dayjs().utc(),
  accountNumber: "",
  buyerName: "",
  previousAmendments: "No",
  previousDate: null,
  pastDue: 0,
  reason: "",
  schedule: "Weekly",
  paymentAmount: 0,
  startDate: dayjs().add(7, "days").utc(),
  requestedBy: "",
  appRecId: 0,
  colRecId: 0
};

const useCtxState = () => {
  const appRecId = useAccountSelector((s) => getRouteParamNum(s.accountInformation?.appRecId));
  const params = useParams();
  const colRecId = Number(params.colRecId);
  const [requestRewritesRows, setRequestRewritesRows] = useState<RequestForRewriteRes[]>([]);
  const [selectedRequestRewrite, setSelectedRequestRewrite] = useState<RequestForRewriteRes | null>(null);
  const [previousAmendments, setPreviousAmendments] = useState<PreviousAmendments | null>(null);
  const [addingNewRequestRewrite, setAddingNewRequestRewrite] = useState(false);
  const { userInfo } = useAuthCtx((s) => s);
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const formState = useValidatedForm(RequestForRewriteForm, defaultValues);
  const form = formState.form;

  const requestRewrites = useReq(async () => {
    if (!appRecId) return;
    return await requestRewriteService.getRequestsForRewrite(appRecId);
  });

  const previousAmendmentsReq = useReq(async () => {
    if (!appRecId) return;
    return await requestRewriteService.getPreviousAmendments(appRecId);
  });

  const loadRequestRewrites = async () => {
    if (!requestRewrites.value) return;

    const requestedRewrites = requestRewrites.value?.sort((a, b) => a.recId! - b.recId!);
    if (!requestedRewrites || !Array.isArray(requestedRewrites)) {
      setRequestRewritesRows([]);
      setSelectedRequestRewrite(null);
      return;
    }
    setRequestRewritesRows(requestedRewrites);    
  };

  const loadPreviousAmendment = async () => {
    if (!previousAmendmentsReq.value) return;
    setPreviousAmendments(previousAmendmentsReq?.value);
  };

  useEffect(() => {
    requestRewrites.load();
    previousAmendmentsReq.load();
  }, [appRecId]);

  useEffect(() => {
    loadRequestRewrites();
    loadPreviousAmendment();
  }, [requestRewrites.value]);

  useEffect(() => {
    loadPreviousAmendment();
  }, [previousAmendmentsReq.value]);

  useEffect(() => {
    if (addingNewRequestRewrite) {
        if (accountInformation) {
          formState.resetToDefault({
            ...defaultValues,
            accountNumber: accountInformation.accountNum,
            buyerName: `${accountInformation.buyer?.lastName ?? ""}, ${accountInformation.buyer.firstName ?? ""}`,
            previousAmendments: previousAmendments?.previousAmendments ? "Yes" : "No",
            previousDate: dayjs(previousAmendments?.previousDate).startOf("day"),
            pastDue: accountInformation.amountDue ?? 0,
            requestedBy: `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`,
            appRecId: appRecId,
            colRecId: colRecId,
          }, true);
        } 
    } else {
      if (selectedRequestRewrite) {
        formState.resetToDefault({
          ...defaultValues,
          accountNumber: selectedRequestRewrite?.accountNumber,
          buyerName: selectedRequestRewrite?.buyerName,
          previousAmendments: selectedRequestRewrite?.previousAmendments,
          previousDate: dayjs(selectedRequestRewrite?.previousDate).startOf("day"),
          pastDue: selectedRequestRewrite?.pastDue ?? 0,
          requestedBy: selectedRequestRewrite?.requestedBy,
          requestedDate: dayjs(selectedRequestRewrite?.requestedDate).startOf("day"),
          startDate: dayjs(selectedRequestRewrite?.startDate).startOf("day"),
          reason: selectedRequestRewrite?.reason,
          paymentAmount: selectedRequestRewrite?.paymentAmount,
          schedule: selectedRequestRewrite?.schedule,
          appRecId: selectedRequestRewrite?.appRecId ?? appRecId,
          colRecId: selectedRequestRewrite?.colRecId ?? colRecId,
        }, true);
      }
    }
  }, [addingNewRequestRewrite, selectedRequestRewrite]);

  return {
    appRecId,
    requestRewrites,
    requestRewritesRows,
    setRequestRewritesRows,
    selectedRequestRewrite,
    setSelectedRequestRewrite,
    form,
    formState,
    addingNewRequestRewrite,
    setAddingNewRequestRewrite,
  };
};

type ICtx = ReturnType<typeof useCtxState>;

const Ctx = createContext<ICtx | null>(null);
export type IRequestRewriteCtx = ICtx;
export const useRequestRewriteCtx = useCtxFactory(Ctx);

const RequestRewriteProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default RequestRewriteProvider;
