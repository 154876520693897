import { FC, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";
import { Button } from "@/components";
import { useLeasingRatesCtx } from "@/features/Settings/CompanyManagement/LeaseRate/LeaseRateProvider";
import LeasingRatesPdfLayout from "@/features/Settings/CompanyManagement/LeaseRate/RatePdfLayout";
import { salesService } from "@/services/salesService";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useCompanyMgmtCtx } from "../../CompanyMgmtProvider";
import { printOptionsState } from "../LeaseRateDetail";

const PrintButton: FC = () => {
  const { getValues } = useLeasingRatesCtx((s) => s.leasingRateForm);
  const { companyDetails } = useCompanyMgmtCtx(s => s);
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = async () => {
    try {
      setIsPrinting(true);
      
      // Get current form values
      const leasingRateData = getValues();
      
      // Generate HTML markup
      const htmlMarkup = ReactDOMServer.renderToStaticMarkup(
        <LeasingRatesPdfLayout 
          leasingRateData={leasingRateData}
          companyName={companyDetails?.shortName}
          showNewCars={printOptionsState.showNewCars} // Use the shared state
        />
      );
      
      // Use salesService to generate PDF
      const pdfData = await salesService.printApplicationPdf(htmlMarkup);
      const fileURL = URL.createObjectURL(pdfData);
      const newWindow = window.open(fileURL, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.error("Error printing leasing rate settings:", error);
      toast.error("There was an issue printing the leasing rate settings.");
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <Button
      type="button"
      onClick={handlePrint}
      disabled={isPrinting}
      loading={isPrinting}
      label="Print"
    />
  );
};

export default PrintButton;