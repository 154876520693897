import { FC, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
// local MUI / style references
import SectionBody from '@/mui/components/form/Section/SectionBody';
import { BG_HIGHLIGHT, DCS_BLUE } from '@/mui/theme/colors';
import styles from '../accountsMainPanel/AccountsMainPanel.module.scss';
// local components
import InsuranceLeftColumn from './InsuranceLeftColumn';
import InsuranceRightColumn from './InsuranceRightColumn';
import PhysicalDamageColumn from './PhysicalDamageColumn';
import SubmitButtonGroup from './SubmitButtonGroup';
import InsuranceClaims from './InsuranceClaims';
import AcctInsuranceProvider, { useAcctInsuranceCtx } from './AcctInsuranceProvider';

// The same color constants you used in your NavTab snippet
const COLOR_INACTIVE = '#757575';
const HOVER_TRANSITION_SPEED = '0.3s';

const AppInsurance: FC = () => (
  <AcctInsuranceProvider>
    <InsuranceTabs />
  </AcctInsuranceProvider>
);

export default AppInsurance;

const InsuranceTabs: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get claim-related state from context
  const { 
    selectedClaim, 
    isNewClaim,
    setSelectedClaimId,
    setIsNewClaim
  } = useAcctInsuranceCtx((s) => ({
    selectedClaim: s.selectedClaim,
    isNewClaim: s.isNewClaim,
    setSelectedClaimId: s.setSelectedClaimId,
    setIsNewClaim: s.setIsNewClaim
  }));

  useEffect(() => {
    // Only parse if we’re on the /insurance/claims route
    if (location.pathname.includes('/insurance/claims')) {
      const searchParams = new URLSearchParams(location.search);
      const claimId = searchParams.get('claimId');
      if (claimId) {
        setSelectedClaimId(Number(claimId));
        setIsNewClaim(false);
      }
    }
  }, [location.pathname, location.search, setSelectedClaimId, setIsNewClaim]);

  // Determine active tab: coverage = 0, claims = 1
  let activeTab = 0;
  if (location.pathname.includes('/insurance/claims')) {
    activeTab = 1;
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      navigate('');
      // Clear any selected claim when switching to Coverage tab
      setSelectedClaimId(null);
      setIsNewClaim(false);
    } else {
      // claims => /insurance/claims
      navigate('claims');
    }
  };

  // Determine the heading text based on the active tab and claim state
  let headingText = "Insurance Information";
  
  if (activeTab === 1) {
    if (selectedClaim) {
      headingText = `Insurance Claim - Claim Number: ${selectedClaim.claimNum}`;
    } else if (isNewClaim) {
      headingText = "Insurance Claim - Claim Number: New";
    } else {
      headingText = "Insurance Claims";
    }
  }

  return (
    <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Typography variant="h6" component="h3" sx={{ m: 0, fontWeight: "bold", color: DCS_BLUE }}>
          {headingText}
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            '& .MuiTabs-indicator': { display: 'none' },
          }}
        >
          <Tab
            label="Coverage"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              fontWeight: activeTab === 0 ? 700 : 500,
              color: activeTab === 0 ? DCS_BLUE : COLOR_INACTIVE,
              borderTop: '2px solid transparent',
              borderBottom: activeTab === 0 
                ? `2px solid ${DCS_BLUE}` 
                : '2px solid transparent',
              transitionProperty: 'border-bottom-color, font-weight',
              transitionDuration: HOVER_TRANSITION_SPEED,
              '&:hover': {
                borderBottom: `2px solid ${DCS_BLUE}`,
                cursor: 'pointer',
              },
            }}
          />
          <Tab
            label="Claims"
            sx={{
              textTransform: 'none',
              fontSize: 16,
              fontWeight: activeTab === 1 ? 700 : 500,
              color: activeTab === 1 ? DCS_BLUE : COLOR_INACTIVE,
              borderTop: '2px solid transparent',
              borderBottom: activeTab === 1 
                ? `2px solid ${DCS_BLUE}` 
                : '2px solid transparent',
              transitionProperty: 'border-bottom-color, font-weight',
              transitionDuration: HOVER_TRANSITION_SPEED,
              '&:hover': {
                borderBottom: `2px solid ${DCS_BLUE}`,
                cursor: 'pointer',
              },
            }}
          />
        </Tabs>
      </Grid>

      {/* Nested Routes */}
      <Routes>
        {/* index => /insurance => Coverage */}
        <Route
          index
          element={
            <>
              <SectionBody
                gap={3}
                sx={{ p: 0 }}
                leftColElems={
                  <>
                    <InsuranceLeftColumn />
                    <PhysicalDamageColumn />
                  </>
                }
                rightColElems={<InsuranceRightColumn />}
              />
              <div className={styles.footer}>
                <SubmitButtonGroup />
              </div>
            </>
          }
        />

        {/* /insurance/claims => show the InsuranceClaims table */}
        <Route path="claims" element={<InsuranceClaims />} />

        {/* Fallback => if unknown path, redirect back to /insurance */}
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </Grid>
  );
};