import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";

const INTERCOM_APP_ID = "w348itf7"; // Replace with your actual App ID

interface IntercomProviderProps {
  user: {
    id: string | undefined;
    name: string | undefined;
    email: string;
    createdAt: number;
    phone?: string;
    companies?: { id: number; name: string }[];
  };
}

const IntercomProvider = ({ user }: IntercomProviderProps) => {
  useEffect(() => {
    try {
      if (!user.id || !user.email || !user.name) {
        return;
      }

      Intercom({
        command: "boot",
        app_id: INTERCOM_APP_ID,
        user_id: user.id,
        name: user.name,
        email: user.email,
        created_at: user.createdAt,
        phone: user.phone,
        companies: user.companies as any,
      });

    } catch (error) {
      console.error("Intercom: Failed to initialize", error);
    }

    return () => {
      try {
        if (typeof window !== "undefined" && typeof window.Intercom === "function") {
          window.Intercom("shutdown");
        }
      } catch (error) {
        console.error("Intercom: Failed to shutdown", error);
      }
    };
  }, [user.id, user.email, user.name]);

  return null;
};

export default IntercomProvider;