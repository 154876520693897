import { FC, useState } from 'react';
import { useBlocker, useParams } from 'react-router-dom';
// mui
import Grid from '@mui/material/Unstable_Grid2';
import ConfirmationModal from '@/mui/components/ConfirmationModal';
// components
import GeneralInfoSection from './GeneralInfoSection';
import FinancialInfoSection from './FinancialInfoSection';
import ReversalInfoSection from './ReversalInfoSection';
import FormSubmitButtonGroup from './SubmitButtonGroup';
// state
import { usePmtReversalDetailCtx } from '../PmtReversalDetailProvider';
import { usePmtReversalCtx } from '../../PmtReversalProvider';
import { useAppDispatch } from '@/store/store';
import { accountActions } from '@/features/Accounts/accountSlice';
// utils
import { paymentService } from '@/services/paymentService';
import { accountsService } from '@/services/accountsService';
import NavBlock from '@/utils/forms/NavBlock';
import { useNavUp } from '@/utils/routing/useNavUpEvent';
// interfaces
import {
  ReversiblePayment,
  ReversiblePaymentForm,
  ReversiblePaymentPostReq,
} from '../../interfaces';

/** ###  */
const PmtReversalForm: FC = () => {
  const dispatch = useAppDispatch();
  const navUp = useNavUp();
  const colRecId = Number(useParams().colRecId);
  const pmtRecId = Number(useParams().pmtRecId);
  const selectedPmt = usePmtReversalDetailCtx((s) => s.selectedPmt);
  const setRows = usePmtReversalCtx((s) => s.setRows);
  const isConfirmOpen = usePmtReversalDetailCtx((s) => s.isConfirmOpen);
  const setIsConfirmOpen = usePmtReversalDetailCtx((s) => s.setIsConfirmOpen);
  const resetFormState = usePmtReversalDetailCtx((s) => s.resetFormState);

  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = usePmtReversalDetailCtx((s) => s.pmtForm);
  const isSystemOnly = usePmtReversalDetailCtx((s) => s.pmtForm.watch().systemOnly);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return !isSubmitted && (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });

  // Event handlers
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);
  
  const refreshAccountData = async () => {
    try {
      const refreshedAccountInfo = await accountsService.getAccountInformation(colRecId);
      if (refreshedAccountInfo) {
        dispatch(accountActions.setAccountInformation(refreshedAccountInfo));
      }
    } catch (error) {
      console.error('Error refreshing account data:', error);
    }
  };

  const handleFormSubmit = async (pmtFormBody: ReversiblePaymentForm) => {
    // Validate
    const payload = new ReversiblePaymentPostReq(pmtFormBody, selectedPmt, { colRecId, pmtRecId });

    try {
      // Mark form as submitted to prevent blocker
      setIsSubmitted(true);
      
      // Close the modal immediately to prevent it from being clickable
      handleCloseConfirmationModal();
      
      // Submit the reversal
      const pmtReversalRes = await paymentService.submitPaymentReversal(payload);
      setRows(pmtReversalRes.map((pmt) => new ReversiblePayment(pmt)));
      
      // Refresh the account data
      await refreshAccountData();
      
      // Reset form state to clear dirty flags
      resetFormState();
      
      // Navigate up
      navUp();
    } catch (error) {
      console.error('Payment reversal error:', error);
      // If there was an error, reset submitted state
      setIsSubmitted(false);
    }
  };

  const refundMsg = "The FULL AMOUNT will be refunded to the customer's card.";
  const systemOnlyMsg =
    selectedPmt?.paidBy === 'Credit Card'
      ? "Money will NOT be put back onto the customer's card."
      : '';
  const modalMsg = isSystemOnly ? systemOnlyMsg : refundMsg;

  return (
    <Grid
      container
      direction="column"
      onSubmit={handleSubmit(handleFormSubmit)}
      gap={3}
      flex={1}
      component="form"
    >
      <Grid container direction="column" gap={4} flex={1}>
        <GeneralInfoSection />
        <FinancialInfoSection />
        <ReversalInfoSection />
      </Grid>

      <FormSubmitButtonGroup />

      <NavBlock blocker={blocker} />
      <ConfirmationModal
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        title="Confirm Payment Reversal"
        message={modalMsg}
        onConfirm={handleSubmit(handleFormSubmit)}
        isSubmitting={isSubmitting}
      />
    </Grid>
  );
};

export default PmtReversalForm;