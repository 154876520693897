import { FC, useEffect, useRef, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";

// mui components
import Box from "@mui/material/Box";

// kendo & UI components
import { Button } from "@/components";
import CommonModal from "@/mui/components/CommonModal";
import EmailModal from "./emailActivity/emailModal";
import EmailContentsModal from "./emailActivity/EmailContentsModal";
import { AccountsMainPanel } from "@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel";
import { LogCallModal } from "./logCallActivity/LogCallModal";
import { ChatBox } from "./chatBox";
import { NotesTextBox } from "./noteActivity/notesTextBox";

// State and hooks
import { useAccountSelector } from "@/features/Accounts/accountSlice";
import useReq from "@utils/useReq";

// Services, utils, and formatting
import { notesService } from "@/services/notesService";
import { accountsService } from "@/services/accountsService";
import { Email, Note, Payment, Messages, customerService, Call } from "@/services/customerService";
import { companyService } from "@/services/companyService";
import { formatDate } from "@/utils/helpers/general";
import { getRouteParamNum } from "@/utils/routing/formatting";
import QuickActionsModal from "@/features/Accounts/accountsSubviews/AccountDetail/components/quickActionsModal/QuickActionsModal";

// Interfaces and styling
import { CustomerActivity, ActivityType } from "./activityPanelInterfaces";
import { ActivityLog } from "@/interfaces";
import EmailActivityCard from "./emailActivity/EmailActivityCard";
import LogCallActivityCard from "./logCallActivity/LogCallActivityCard";
import SmsActivityCard from "./smsActivity/SmsActivityCard";
import NoteActivityCard from "./noteActivity/NoteActivityCard";
import PaymentActivityCard from "./paymentActivity/PaymentActivityCard";
import LogActivityCard from "./logActivity/LogActivityCard";
import dayjs from "dayjs";
import styles from "./activityPanel.module.scss";

/**
 * Helper: Parse the raw customer activity data into a flat array.
 */
const parseCustomerActivity = (
  customerActivity: any,
  setPinnedNotes: (notes: Note[]) => void
): CustomerActivity[] => {
  const activities: CustomerActivity[] = [];
  Object.entries(customerActivity).forEach(([key, val]) => {
    switch (key) {
      case "emails":
        (val as Email[]).forEach((email) => {
          activities.push({
            id: email.recId,
            activityType: ActivityType.enum.Email,
            activity: email,
            timestamp: email.timestamp || "",
          });
        });
        break;
      case "messages":
        (val as Messages[]).forEach((message) => {
          activities.push({
            id: message.ecomRecId,
            activityType: ActivityType.enum.Message,
            activity: message,
            timestamp: message.timestamp || "",
          });
        });
        break;
      case "notes":
        const pinned = (val as Note[]).filter((note) => note.pinned);
        setPinnedNotes(pinned);
        (val as Note[]).forEach((note) => {
          activities.push({
            id: note.recId!,
            activityType: ActivityType.enum.Note,
            activity: note,
            timestamp: note.updatedUtc || "",
          });
        });
        break;
      case "payments":
        (val as Payment[]).forEach((payment) => {
          activities.push({
            id: payment.recId!,
            activityType: ActivityType.enum.Payment,
            activity: payment,
            timestamp: payment.timestamp || "",
          });
        });
        break;
      case "calls":
        (val as Call[]).forEach((call) => {
          activities.push({
            id: call.recId,
            activityType: ActivityType.enum.Call,
            activity: call,
            timestamp: call.timestamp || "",
          });
        });
        break;
      default:
        break;
    }
  });
  return activities.sort((a, b) => dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf());
};

const ActivityPanel: FC = () => {
  // --- Route and basic state ---
  const colRecId = getRouteParamNum(useParams().colRecId);
  const [searchParams, setSearchParams] = useSearchParams();
  const panelRef = useRef<HTMLDivElement>(null);

  // --- Local UI state ---
  const [callerPhoneNumber, setCallerPhoneNumber] = useState("");
  const [panelHeight, setPanelHeight] = useState(0);
  const [activityData, setActivityData] = useState<CustomerActivity[] | undefined>(undefined);
  const [pinnedNotes, setPinnedNotes] = useState<Note[] | undefined>(undefined);
  const [logData, setLogData] = useState<CustomerActivity[]>([]);
  const [loadingLogData, setLoadingLogData] = useState(false);
  const [selectedEmailRecId, setSelectedEmailRecId] = useState<number | null>(null);
  const [selectedLog, setSelectedLog] = useState<ActivityLog | null>(null);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [logCallModalOpen, setLogCallModalOpen] = useState(false);
  const [errors, setErrors] = useState<{ error: boolean; errorMessage: string }>({
    error: false,
    errorMessage: "",
  });

  // --- Account and derived data ---
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const contactInformation = useAccountSelector((s) => s.contactInformation);
  const buyer = accountInformation?.buyer;
  const isLegal = !!accountInformation?.legalStatus;
  const appBuyerRecId = buyer?.appBuyerRecId ?? null;
  const accountnum = accountInformation?.accountNum;
  const cannotContactBuyerAndCobuyer =
    !!contactInformation?.buyer?.noEmail && !!contactInformation?.coBuyer?.noEmail;

  // --- Tab and routing helpers ---
  const currentTab =
    (searchParams.get("activityType") as ActivityType | undefined) ?? ActivityType.enum.All;
  const setCurrentTab = (activityType: ActivityType) => {
    searchParams.set("activityType", activityType);
    setSearchParams(searchParams);
  };

  // --- Data Loading via useReq ---
  const {
    isLoading: loadingActivity,
    load: loadActivity,
    value: loadedActivity,
  } = useReq<CustomerActivity[]>(
    async () => {
      if (!colRecId) return [];
      console.log("Fetching customer activity for colRecId:", colRecId);
      const customerActivity = await customerService.getCustomerActivity(colRecId);
      return parseCustomerActivity(customerActivity, setPinnedNotes);
    },
    false,
    [], // Keep this empty to prevent automatic refetching
    (error) => {
      console.error(error);
      setErrors({ error: true, errorMessage: "There was an issue loading the customer activity" });
    }
  );

  // Optimized refresh function for SMS events - preserves UI state
  const refreshActivityData = useCallback(async () => {
    if (!colRecId) return;

    try {
      // Store UI state values that need to be preserved
      const scrollPosition = panelRef.current?.scrollTop || 0;

      // Get fresh activity data
      const customerActivity = await customerService.getCustomerActivity(colRecId);
      const freshActivityData = parseCustomerActivity(customerActivity, setPinnedNotes);

      // Update activity data without triggering full component reset
      setActivityData(freshActivityData);

      // Restore scroll position after data update (in next render cycle)
      setTimeout(() => {
        if (panelRef.current) {
          panelRef.current.scrollTop = scrollPosition;
        }
      }, 0);
    } catch (error) {
      console.error("Error refreshing activity data:", error);
    }
  }, [colRecId]);

  // Stable callback to load full activity data
  const initData = useCallback(async () => {
    if (colRecId) {
      await loadActivity();
    }
  }, [loadActivity, colRecId]);

  // --- Effects ---

  // Initial load: run once per mount (or when colRecId changes)
  const prevColRecIdRef = useRef<number | null>(null);

  // Then replace your existing initData useEffect with this:
  useEffect(() => {
    // Only fetch if colRecId exists and has changed from previous value
    if (colRecId && prevColRecIdRef.current !== colRecId) {
      prevColRecIdRef.current = colRecId;
      initData();
    }
  }, [colRecId, initData]);

  // Update local state when new activity data is loaded
  useEffect(() => {
    if (loadedActivity) {
      setActivityData(loadedActivity);
    }
  }, [loadedActivity]);

  // Fetch company details
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const companyId = accountInformation?.compId;
        if (companyId) {
          const companyDetails = await companyService.getCompanyDetails(companyId);
          const { phone, phone800 } = companyDetails;
          const validPhoneNumber =
            phone && phone.trim() !== "" && phone !== "0"
              ? phone
              : phone800 && phone800.trim() !== "" && phone800 !== "0"
              ? phone800
              : "";
          setCallerPhoneNumber(validPhoneNumber);
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };
    fetchCompanyDetails();
  }, [accountInformation]);

  // Fetch log data when in Log tab
  useEffect(() => {
    if (currentTab === ActivityType.enum.Log && colRecId) {
      setLoadingLogData(true);
      accountsService
        .getActivityLog(colRecId)
        .then((res) => {
          const activityLog = res.map((log) => ({
            id: log.recId,
            activityType: ActivityType.enum.Log,
            activity: log,
            timestamp: log.contactOn || "",
          }));
          setLogData(activityLog);
        })
        .finally(() => {
          setLoadingLogData(false);
        });
    }
  }, [currentTab, colRecId]);

  // Update panel height based on its ref and loaded activity
  useEffect(() => {
    if (panelRef.current?.clientHeight) {
      setPanelHeight(panelRef.current.clientHeight);
    }
  }, [panelRef, activityData]);

  // --- Check data loading state ---
  const isInitialLoading = !activityData && !errors.error;

  // --- Derived UI State ---
  const displayAll = currentTab !== ActivityType.enum.Message;
  const navBarItems = ActivityType.options.map((type) => ({
    title: type,
    isActive: currentTab === type,
    onClick: () => setCurrentTab(type),
  }));
  const isLoading =
    (currentTab === ActivityType.enum.Log && loadingLogData) ||
    (currentTab !== ActivityType.enum.Log && loadingActivity && !errors.error);

  // --- Handlers ---

  // Handle new note submission
  const handleNoteSubmit = async (newNote: string): Promise<boolean> => {
    if (!appBuyerRecId || !colRecId || newNote === "") return false;
    try {
      await notesService.insertNote(appBuyerRecId, colRecId, newNote);
      await initData(); // refresh activity data
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // Handle pinned note updates
  const updatePinnedNote = async (note: Note) => {
    const pinnedNoteActivityData = activityData?.find(
      (value) => value.activityType === ActivityType.enum.Note && value.activity === note
    );
    const pinnedNote = pinnedNoteActivityData?.activity as Note;
    await customerService
      .updatePinnedNote({
        recId: note.recId!,
        pinned: !note.pinned,
      })
      .then((res) => {
        if (!note.pinned) {
          setActivityData((prev) => {
            if (prev) {
              const newActivityData = prev.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.enum.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.enum.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
          setPinnedNotes((prev) => {
            if (prev) {
              return [
                ...prev,
                {
                  ...pinnedNote,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
              ].sort((a, b) => (a.pinnedOnUtc! > b.pinnedOnUtc! ? -1 : 1));
            }
            return [
              {
                ...pinnedNote,
                pinned: res.pinned,
                pinnedOn: res.pinnedBy,
                pinnedOnUtc: formatDate(res.pinnedOnUtc),
              },
            ];
          });
        } else {
          setPinnedNotes((prev) => prev?.filter((prevNote) => prevNote.recId !== note.recId));
          setActivityData((prev) => {
            if (prev) {
              const newActivityData = prev.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.enum.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.enum.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
        }
      });
  };

  // --- Early Returns ---
  if (errors.error) return <div>{errors.errorMessage}</div>;

  // Instead of returning null, show a loading state when we don't have essential data
  const hasRequiredData = accountInformation && colRecId && appBuyerRecId;

  // Always render even without data, but show a loading state
  return (
    <AccountsMainPanel
      navBarTitle="Activity"
      navBarItems={navBarItems}
      navBarItemsStyle={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "8px",
      }}
      containerStyle={{
        width: "100%",
        height: "100%",
        overflow: "hidden", // Keep the main container from scrolling
      }}
      loading={isLoading || (!hasRequiredData && !errors.error)}
    >
      {hasRequiredData ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden", // Prevent the container from scrolling
          }}
        >
          {/* Static content section (doesn't scroll) */}
          <Box sx={{ flexShrink: 0 }}>
            {currentTab === ActivityType.enum.Note && (
              <NotesTextBox handleNoteSubmit={handleNoteSubmit} />
            )}

            {currentTab === ActivityType.enum.Email && (
              <div className={styles.buttonHeader}>
                <div className={styles.button}>
                  <Button
                    label="Compose Email"
                    disabled={isLegal || cannotContactBuyerAndCobuyer}
                    onClick={() => setEmailModalOpen(true)}
                    loading={activityData === undefined}
                  />
                  <div className={styles.disclaimer}>
                    {isLegal
                      ? "Cannot contact customer with legal status"
                      : cannotContactBuyerAndCobuyer
                      ? "Both buyer and co-buyer have no email preference on"
                      : undefined}
                  </div>
                </div>
              </div>
            )}

            {currentTab === ActivityType.enum.Call && (
              <div className={styles.buttonHeader}>
                <div className={styles.button}>
                  <Button label="Log Call" onClick={() => setLogCallModalOpen(true)} />
                </div>
              </div>
            )}
          </Box>

          {/* Scrollable content area */}
          {currentTab === ActivityType.enum.Message ? (
            <ChatBox
              activityData={activityData ?? []}
              appBuyerRecId={appBuyerRecId as number}
              initData={refreshActivityData} // Use the optimized refresh function here
              height={panelHeight}
              loading={activityData === undefined && !errors.error}
              isLegal={isLegal}
              canTextBuyer={!contactInformation?.buyer?.noText}
              canTextCoBuyer={!contactInformation?.coBuyer?.noText}
            />
          ) : (
            <Box
              className={styles.activities}
              sx={{
                display: displayAll ? "flex" : "none",
                flexDirection: "column",
                width: "100%",
                flex: 1,
                overflowX: "hidden",
                overflowY: "auto", // This is the scrollable part
                padding: "0 4px", // Small padding to prevent content from touching edges
              }}
              ref={panelRef}
            >
              {currentTab === ActivityType.enum.All && pinnedNotes?.length ? (
                <>
                  <h3 className={styles.pinnedNotesHeader}>Pinned Notes</h3>
                  {pinnedNotes.map((note, idx) => (
                    <NoteActivityCard
                      activity={note}
                      updatePinnedNote={updatePinnedNote}
                      key={`pn-${idx}`}
                    />
                  ))}
                  <h3 className={styles.pinnedNotesHeader}>Rest of Activity</h3>
                </>
              ) : null}

              {currentTab !== ActivityType.enum.Log &&
                activityData &&
                activityData.map((activity, idx) => {
                  if (activity.activityType === currentTab || currentTab === "All") {
                    switch (activity.activityType) {
                      case ActivityType.enum.Email:
                        return (
                          <EmailActivityCard
                            activity={activity.activity as Email}
                            setSelectedRecId={setSelectedEmailRecId}
                            key={`Email${idx}`}
                          />
                        );
                      case ActivityType.enum.Message:
                        return (
                          <SmsActivityCard
                            activity={activity.activity as Messages}
                            key={`Message${idx}`}
                          />
                        );
                      case ActivityType.enum.Note:
                        return (
                          <NoteActivityCard
                            activity={activity.activity as Note}
                            updatePinnedNote={updatePinnedNote}
                            key={`Note${idx}`}
                          />
                        );
                      case ActivityType.enum.Payment:
                        return (
                          <PaymentActivityCard
                            activity={activity.activity as Payment}
                            accountnum={accountnum}
                            key={`Payment${idx}`}
                          />
                        );
                      case ActivityType.enum.Call:
                        return (
                          <LogCallActivityCard
                            activity={activity.activity as Call}
                            callerPhoneNumber={callerPhoneNumber}
                            key={`Call${idx}`}
                          />
                        );
                      default:
                        return null;
                    }
                  }
                  return null;
                })}

              {currentTab === ActivityType.enum.Log &&
                logData.map((log, idx) => (
                  <LogActivityCard
                    activity={log.activity as ActivityLog}
                    setSelectedLog={setSelectedLog}
                    key={`log${idx}`}
                  />
                ))}
            </Box>
          )}

          {/* Modals and other UI elements that aren't part of the scroll area */}
          <EmailContentsModal
            handleClose={() => setSelectedEmailRecId(null)}
            emailRecId={selectedEmailRecId}
          />

          {emailModalOpen && colRecId && (
            <EmailModal
              colRecId={colRecId as number}
              initEmailData={initData}
              open={emailModalOpen}
              onClose={() => setEmailModalOpen(false)}
              canEmailBuyer={!contactInformation?.buyer.noEmail}
              canEmailCoBuyer={!contactInformation?.coBuyer.noEmail}
            />
          )}

          {logCallModalOpen && colRecId && (
            <LogCallModal
              colRecId={colRecId as number}
              reloadActivity={initData}
              open={logCallModalOpen}
              onClose={() => setLogCallModalOpen(false)}
            />
          )}

          {!!selectedLog && (
            <CommonModal
              open={true}
              onClose={() => setSelectedLog(null)}
              title={[
                formatDate(selectedLog.contactOn),
                selectedLog.detailShort ?? selectedLog.transType,
              ].join(" - ")}
            >
              {selectedLog.detailLong.startsWith("<") ? (
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedLog.detailLong) }}
                />
              ) : (
                <div>{selectedLog.detailLong}</div>
              )}
            </CommonModal>
          )}

          <QuickActionsModal reloadActivity={initData} />
        </Box>
      ) : (
        // Empty Box for when we don't have the required data but still want to render the component
        <Box sx={{ width: "100%", height: "100%" }} />
      )}
    </AccountsMainPanel>
  );
};

export default ActivityPanel;
