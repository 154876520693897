import { FC, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// kendo
import Grid from "@mui/material/Unstable_Grid2";
import BackLink from "@/mui/components/buttons/BackLink";
// components
import InventoryDetailsRouter from "./InventoryDetailsRouter";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useInventoryDetailCtx } from "./InventoryDetailProvider";
// utils
import { inventoryService } from "@/services/inventoryService";
import { isProd } from "@/utils/helpers/general";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { CurrentInspection, InventoryVehicle } from "@/interfaces/Inventory";
// style
import styles from "./InventoryDetailsContainer.module.scss";
import InventoryDetailsNavTabs from "./InventoryDetailsNavTabs";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";

const navigationItems = [
  { name: "Vehicle", route: "vehicle" },
  { name: "Expenses", route: "expenses" },
  { name: "Vehicle Check-In", route: "vehicleCheckIn" },
  { name: "Vehicle Options", route: "vehicleOptions" },
  { name: "Title & Tag", route: "titleTag" },
  { name: "Gps & Odometer", route: "gpsOdometer" },
  { name: "Suggested Sale/Sold Vehicle", route: "suggestedSold" },
  { name: "Buyer's Guide", route: "buyersGuide" },
  { name: "Documents", route: "documents" },
];

const InventoryDetailsContainer: FC = () => {
  const { pathname, search } = useLocation();
  const invRecId = getRouteParamNum(useParams().invRecId);
  const { hasAfsLevel } = useAuthSelector((s) => s);

  const query = new URLSearchParams(search);

  const list = query.get("list");
  let listPath = `/inventory`;
  if (list) {
    listPath = `/inventory/${list}`;
  }
  const setInventoryVehicle = useInventoryDetailCtx((s) => s.setInventoryVehicle);
  const setInspectorList = useInventoryDetailCtx((s) => s.setInspectorList);
  const setCurrentInspection = useInventoryDetailCtx((s) => s.setCurrentInspection);

  const [headerText, setHeaderText] = useState<string>("");

  const loadHeaderText = useCallback(async () => {
    if (invRecId === null) return;

    const headerText = await inventoryService.getHeaderText(invRecId.toString());
    setHeaderText(headerText);
  }, [invRecId]);

  const loadVehicle = useCallback(async () => {
    if (invRecId === null) return;

    const vehicle = await inventoryService.getInventoryVehicle(invRecId.toString());
    setInventoryVehicle(vehicle as InventoryVehicle);
  }, [invRecId]);

  const loadInspectors = async () => {
    const inspectors = await inventoryService.getInspectors();
    setInspectorList(inspectors || []);
  };

  const getCurrentInspection = async () => {
    if (invRecId === null) return;

    try {
      const initVehicleInspection = await inventoryService.getCurrentInspection(invRecId);
      setCurrentInspection(initVehicleInspection as CurrentInspection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadHeaderText();
    loadVehicle();
    loadInspectors();
    getCurrentInspection();
  }, [invRecId]);

  const defaultPath = `/inventory/unsold`;
  const backPath = sessionStorage.getItem('lastInventoryPath') || defaultPath;
  
  return (
    <Grid container direction="column" sx={{ p: 4, pt: 0, flex: 1, width: "100%" }}>
      <BackLink to={backPath} />

      <Grid
        container
        direction="row"
        sx={{
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          alignItems: "center",
          borderBottom: "4px solid #e6e6e6",
          background: BG_HIGHLIGHT,
        }}
      >
        <Grid component="h1" sx={{ m: 0, py: 1.5, px: 4.5, fontSize: 24, fontWeight: 700 }}>
          {headerText}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        sx={{
          border: `1px solid ${BG_HIGHLIGHT}`,
          borderRadius: 1,
          overflow: "hidden", // Prevent scrolling outside the main container
        }}
      >
        <InventoryDetailsNavTabs />

        {/* Set SaleDetailsRouter to scroll */}
        <Grid
          container
          flex={1}
          sx={{
            overflowY: "auto", // Enable vertical scrolling
            maxHeight: "calc(100vh - 200px)", // Adjust height based on your layout
          }}
        >
          <InventoryDetailsRouter />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InventoryDetailsContainer;
