import { FC, useState } from "react";
import { Payment } from "@/services/customerService";
import { DateFormat, formatDate } from "@/utils/helpers/general";
import { Icons } from "@/components/icons";
import { Button } from "@/components";
import StandardizedActivityCard from '../StandardizedActivityCard';
import styles from "../activityPanel.module.scss";
import { ColTypeCode, colTypeMap } from "@/enums";
import { toast } from "react-toastify";
import { paymentService } from "@/services/paymentService";

const PaymentActivityCard: FC<{
  activity: Payment;
  accountnum: string | undefined;
}> = ({ activity, accountnum }) => {
  const [paymentClickLoading, setPaymentClickLoading] = useState(false);
  const [paymentReceiptLoading, setPaymentReceiptLoading] = useState(false);
  const paymentTypeCode = activity.paymentType as ColTypeCode;
  const paymentTypeLabel = paymentTypeCode ? colTypeMap[paymentTypeCode]?.label : "Unknown";

  const handlePaymentClick = async (payment: Payment) => {
    setPaymentClickLoading(true);

    if (activity.pmtRecId! === 0 || activity.pmtRecId! === null) {
      toast.error("No Receipt Id to Send");
      setPaymentClickLoading(false);
      return;
    }

    await paymentService
      .sendReceipt(activity.pmtRecId!)
      .then(() => {
        toast.success("Receipt Sent");
      })
      .catch(() => toast.error("Unable to send receipt"))
      .finally(() => setPaymentClickLoading(false));
  };

  const handlePaymentReprint = async (payment: Payment) => {
    setPaymentReceiptLoading(true);

    try {
      const receiptUrl = await paymentService.getReceiptUrl(activity.pmtRecId!);
      if (!receiptUrl) throw new Error();
      window.open(receiptUrl);
    } catch (e) {
      toast.error("Unable to reprint receipt");
    } finally {
      setPaymentReceiptLoading(false);
    }
  };

  // Payments should only be in 'New' status for a few seconds
  if (activity.pmtStatus === "New") return null;

  let paymentDetails = (
    <div>
      <span className={styles.title}>{activity.pmtStatus}</span> - Account# {accountnum} -{" "}
      {activity.paidIn} {activity.paidBy} payment of{" "}
      <span className={styles.success}>${activity.totalApplied}</span> ({paymentTypeLabel})
    </div>
  );

  if (activity.pmtStatus !== "Done-Posted" && activity.pmtStatus !== "Posted-Done") {
    const totalClassName = activity.pmtStatus === "Approved" ? styles.warning : styles.error;
    paymentDetails = (
      <div>
        <span className={styles.title}>{activity.pmtStatus}</span>
        {activity.paidIn} {activity.paidBy} payment of{" "}
        <span className={totalClassName}>${activity.totalApplied}</span>
      </div>
    );
  } else {
    if (activity.paymentType === "CPI ADJ") {
      paymentDetails = (
        <div>
          CPI adjustment of <span className={styles.success}>${activity.carPmt}</span>
        </div>
      );
    }

    if (activity.paymentType === "PrinOnly") {
      paymentDetails = (
        <div>
          Principal payment of <span className={styles.success}>${activity.carPmt}</span>
        </div>
      );
    }
  }
  
  return (
    <StandardizedActivityCard
      icon={<Icons.DollarDouble />}
      timestamp={formatDate(activity.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
    >
      <div>
        {paymentDetails}
        {(activity.pmtStatus === 'Done-Posted' || activity.pmtStatus === 'Posted-Done') && (
          <div className={styles.actionDiv}>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Button
                label="Resend Receipt"
                secondary
                onClick={() => handlePaymentClick(activity)}
                loading={paymentClickLoading}
              />
              <Button
                label="Print Receipt"
                secondary
                onClick={() => handlePaymentReprint(activity)}
                loading={paymentReceiptLoading}
              />
            </div>
          </div>
        )}
      </div>
    </StandardizedActivityCard>
  );
};

export default PaymentActivityCard;