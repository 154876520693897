import { FC } from 'react';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// components
import DateFilter from '../tableFilterComponents/DateFilter/DateFilter';
import CategoryFilter from '../tableFilterComponents/CategoryFilter';
// state
import { useAccountsViewCtx } from '../accountsViewState';
// interfaces
import { AcctsSubviewKey, SubviewKey } from '../viewInfo';
import { AllowedCategoryFilterKey } from './default';

interface TableFiltersProps {
  categoryFilterOptions?: string[];
  categoryFilterField?: AllowedCategoryFilterKey;
}

// @todo move to view-info
const subviewsWithDateFilters: SubviewKey[] = [AcctsSubviewKey.terminations];

const TableFilters: FC<TableFiltersProps> = ({ 
  categoryFilterOptions,
  categoryFilterField 
}) => {
  const subview = useAccountsViewCtx((s) => s.subview);
  const initDateFilterFields = useAccountsViewCtx((s) => s.subviewInfo.initDateFilterFields[0]!);
  const contextOptions = useAccountsViewCtx((s) => s.subviewInfo.categoryFilterOptions);

  const isDateFilterEnabled = subviewsWithDateFilters.includes(subview);

  // Use provided field or determine from subview
  const filterField = categoryFilterField || (
    subview === AcctsSubviewKey.unpaidSales
      ? 'spStatus'
      : subview === AcctsSubviewKey.legalAccounts
      ? 'status'
      : subview === AcctsSubviewKey.recurringPayments
      ? 'recurringStatus'
      : subview === AcctsSubviewKey.insuranceClaims
      ? 'claimStatus'
      : subview === AcctsSubviewKey.requestRewrite
      ? 'reqRewriteStatus'
      : 'saleType'
  ) as AllowedCategoryFilterKey;

  // Use provided options or context options
  const options = categoryFilterOptions || contextOptions;

  return (
    <Grid container direction="row" gap={1}>
      {options && options.length > 0 && (
        <CategoryFilter 
          field={filterField} 
          options={options}  // Pass options to CategoryFilter
        />
      )}
      {isDateFilterEnabled && <DateFilter field={initDateFilterFields} />}
    </Grid>
  );
};

export default TableFilters;