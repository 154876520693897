import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useBlocker } from "react-router-dom";
// state
import { useLeasingRatesCtx } from "../LeaseRateProvider";
// interfaces
import { LeasingRateForm } from "@/interfaces/LeaseRate";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Checkbox, FormControlLabel, Box } from "@mui/material";
import { Divider } from "@mui/material";
// components
import BackLink from "@/mui/components/buttons/BackLink";
import { Button, Loader } from "@/components";
import NavBlock from "@/utils/forms/NavBlock";
// services
import { companyService } from "@/services/companyService";
import { BG_HIGHLIGHT, DCS_BLUE } from "@/mui/theme/colors";
import GeneralRateSheet from "../CustomFields/GeneralRateSheet";
import UsedCarRateSheet from "../CustomFields/UsedCarRateSheet";
import NewCarRateSheet from "../CustomFields/NewCarRateSheet";
import NotesSection from "../CustomFields/NoteSection";
import PrintButton from "../RatePdfLayout/PrintButton";

// Export the state for use in PrintButton
export const printOptionsState = {
  showNewCars: false,
  setShowNewCars: (value: boolean) => { printOptionsState.showNewCars = value; }
};

const CancelButton: FC<{ onClick?: () => void }> = ({ onClick = () => null }) => {
  const resetToDefault = useLeasingRatesCtx((state) => state.resetToDefault);
  
  const handleClickCancel = () => {
    resetToDefault();
    onClick && onClick();
  };

  return <Button type="button" label="Cancel" onClick={handleClickCancel} />;
};

const SubmitButton: FC = () => {
  const { isDirty, isSubmitting } = useLeasingRatesCtx((s) => s.leasingRateForm.formState);

  return (
    <Button
      type="submit"
      disabled={!isDirty || isSubmitting}
      loading={isSubmitting}
      label={"Submit"}
    />
  );
};

const LeasingRatesDetail: FC<{
  isNew?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}> = ({ isNew = false, onSubmit = () => null, onCancel = () => null }) => {
  const loadLeasingRatesData = useLeasingRatesCtx((s) => s.loadLeasingRatesData);
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitSuccessful },
  } = useLeasingRatesCtx((s) => s.leasingRateForm);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (isDirty || !isValid) && currentLocation.pathname !== nextLocation.pathname;
  });

  // State for the "include in print" checkbox
  const [showNewCarSection, setShowNewCarSection] = useState(true);

  // Update the shared state when our local state changes
  useEffect(() => {
    printOptionsState.setShowNewCars(showNewCarSection);
  }, [showNewCarSection]);

  const handleSubmitForm = async (form: LeasingRateForm) => {
    try {
      await companyService.updateLeasingRateSettings(form);
      reset(form);
      toast.success("Success: updating leasing rate settings.");
      onSubmit && onSubmit();
    } catch (error) {
      toast.error("There was an issue saving the leasing rate settings.");
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    // Load the data when component mounts
    loadLeasingRatesData.load();
  }, []);

  return (
    <>
      {loadLeasingRatesData.isLoading ? (
        <Loader size="large" />
      ) : (
        <>
          <Grid
            container
            direction="row"
            flex={1}
            component="header"
            width="100%"
            marginTop={2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{ backgroundColor: BG_HIGHLIGHT }}
          >
            <Grid component="h2" marginTop={0}>
              Leasing Rate Settings
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            flex={1}
            marginTop={2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{
              width: "100%",
              backgroundColor: BG_HIGHLIGHT,
            }}
          >
            <Grid
              container
              direction="column"
              onSubmit={handleSubmit(handleSubmitForm)}
              gap={3}
              flex={1}
              paddingY={2}
              component="form"
            >
              {/* General Rate Sheet Section */}
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  General Rate Sheet
                </Grid>
                <Divider
                  sx={{
                    flex: 1,
                    maxHeight: "1px",
                    borderBottomWidth: "1px",
                    mx: 2,
                    bgcolor: DCS_BLUE,
                  }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <GeneralRateSheet />
              </Grid>

              {/* Used Car Rate Sheet Section */}
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Used Car Rate Sheet
                </Grid>
                <Divider
                  sx={{ 
                    flex: 1, 
                    maxHeight: "1px", 
                    borderBottomWidth: "1px",
                    mx: 2, 
                    bgcolor: DCS_BLUE 
                  }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <UsedCarRateSheet />
              </Grid>

              {/* New Car Rate Sheet Section - with checkbox */}
              <Box sx={{ marginBottom: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNewCarSection}
                      onChange={(e) => setShowNewCarSection(e.target.checked)}
                    />
                  }
                  label="Show New Car Rate Sheet"
                />
              </Box>

              {showNewCarSection && (
                <>
                  <Grid
                    container
                    direction="row"
                    flex={1}
                    justifyContent="start"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid
                      component={Typography}
                      color={DCS_BLUE}
                      fontSize={18}
                      fontWeight={700}
                      alignItems="center"
                    >
                      New Car Rate Sheet
                    </Grid>
                    <Divider
                      sx={{ 
                        flex: 1, 
                        maxHeight: "1px", 
                        borderBottomWidth: "1px",
                        mx: 2, 
                        bgcolor: DCS_BLUE 
                      }}
                    />
                  </Grid>

                  <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                    <NewCarRateSheet />
                  </Grid>
                </>
              )}

              {/* Notes Section */}
              <Grid
                container
                direction="row"
                flex={1}
                justifyContent="start"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  component={Typography}
                  color={DCS_BLUE}
                  fontSize={18}
                  fontWeight={700}
                  alignItems="center"
                >
                  Notes & Additional Settings
                </Grid>
                <Divider
                  sx={{ 
                    flex: 1, 
                    maxHeight: "1px", 
                    borderBottomWidth: "1px",
                    mx: 2, 
                    bgcolor: DCS_BLUE 
                  }}
                />
              </Grid>

              <Grid container direction="row" flex={1} gap={3} paddingX={2}>
                <NotesSection />
              </Grid>

              <Grid container direction="row" justifyContent="end" gap={1}>
                <PrintButton />
                <SubmitButton />
                <CancelButton onClick={onCancel} />
              </Grid>
            </Grid>

            {!isNew && <NavBlock blocker={blocker} />}
          </Grid>
        </>
      )}
    </>
  );
};

export default LeasingRatesDetail;