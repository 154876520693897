import { Route, Routes } from "react-router-dom";
import LeasingRatesDetail from "./LeaseRateDetail";
import LeasingRatesProvider from "./LeaseRateProvider";

const LeasingRatesRouter = () => {
  return (
    <LeasingRatesProvider>
      <Routes>
        <Route index element={<LeasingRatesDetail />} />
      </Routes>
    </LeasingRatesProvider>
  );
};

export default LeasingRatesRouter;