import { NavLink } from 'react-router-dom';
// mui
import { GridColTypeDef } from '@mui/x-data-grid';
import Button from '@/mui/components/buttons/MuiKendoButton';
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
import { currencyCellFmt, datetimeCellFmt } from '@/mui/components/dataTable/cellFormatUtils';
// interfaces
import { ReversiblePayment } from '../interfaces';

const numProps: GridColTypeDef = { type: 'number', align: 'right', headerAlign: 'left' };

export const tableColumns: MuiColumnDef<ReversiblePayment>[] = [
  {
    field: 'pmtTime',
    headerName: 'Date',
    valueFormatter: datetimeCellFmt,
    valueGetter: (p) => p?.row.pmtTime.toDate(),
    align: 'center',
    type: 'dateTime',
    flex: 1,
  },
  { field: 'totalPaid', headerName: 'Total Paid', valueFormatter: currencyCellFmt, ...numProps },
  { field: 'prinPaid', headerName: 'Principal', valueFormatter: currencyCellFmt, ...numProps },
  { field: 'intPaid', headerName: 'Interest', valueFormatter: currencyCellFmt, ...numProps },
  { field: 'lcPaid', headerName: 'LC Paid', valueFormatter: currencyCellFmt, ...numProps },
  { field: 'taxPaid', headerName: 'Tax Paid', valueFormatter: currencyCellFmt, ...numProps },
  { field: 'inDaysLate', headerName: 'Days Late', minWidth: 90, ...numProps },
  { field: 'paidBy', headerName: 'Paid By', minWidth: 80 },
  { field: 'paidIn', headerName: 'Paid In', minWidth: 80 },
  {
    field: 'recId',
    headerName: '',
    renderCell: (params) => (
      <NavLink to={`./${params.row.recId}/`} relative="path">
        <Button>Select</Button>
      </NavLink>
    ),
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
  },
];
