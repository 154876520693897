import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import RequestForRewritesTable from "./RequestRewriteTable";
import RequestRewriteForm from "./RequestRewriteForm";
// state
import RequestRewriteProvider from "./RequestRewriteProvider";

/** ###  */
const RequestForRewriteRouter: FC = () => {
  return (
    <RequestRewriteProvider>
      <Routes>
        <Route path="/" element={<RequestForRewritesTable />} />
        <Route path="/:recId" element={<RequestRewriteForm />} />

        {/* Fallback */}
        <Route path="*" element={<Navigate to="./" relative="path" replace={true} />} />
      </Routes>
    </RequestRewriteProvider>
  );
};

export default RequestForRewriteRouter;
