import { FC } from "react";
// layout
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { NumberInput } from "@/components/inputs/numberInput/NumberInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useSidenoteCtx } from "./SidenoteProvider";
// utils
import { PaymentInterval } from "@/enums/payment";

const textStyle = { marginTop: "1px", marginBottom: "1px" };

/** ### Right column form fields for Sidenote Form */
const RightContainer: FC = () => {
  const formState = useSidenoteCtx((s) => s.formState);
  const isNew = useSidenoteCtx((s) => s.isNew);
  const pmtAmtError = useSidenoteCtx((s) => s.pmtAmtError);

  return (
    <SectionColumn p={0} gap={3}>
      <SectionColumn>
        {/* @todo if this is always disabled, remove field from form validation */}
        <CurrencyInput
          label="Sidenote Total"
          {...formState.getFieldProps("snTotal")}
          style={textStyle}
          required
          disabled
        />
        <CurrencyInput
          label="Down Payment"
          {...formState.getFieldProps("snDown")}
          style={textStyle}
          disabled={!isNew}
        />
        {/* @todo if this is always disabled, remove field from form validation */}
        <CurrencyInput
          label="Amount Financed"
          {...formState.getFieldProps("snAmtFin")}
          style={textStyle}
          required
          disabled
        />

        <NumberInput
          label="Interest Rate"
          {...formState.getFieldProps("intRate")}
          style={textStyle}
          disabled={!isNew}
        />
        <MuiKendoDateField
          label="First Payment Due"
          {...formState.getFieldProps("nextDueDate")}
          onChange={(e) => {
            formState.getFieldProps("nextDueDate").onChange(e);
            formState.getFieldProps("pmtDue").onChange(e);
          }}
          required
          disabled={!isNew}
        />
        <DropdownInput
          label="Schedule"
          {...formState.getFieldProps("pmtSched")}
          required
          data={PaymentInterval.options}
          disabled={!isNew}
          errors={!!formState.getFieldProps("pmtSched").errors}
        />
        <CurrencyInput
          label="Payment Amount"
          {...formState.getFieldProps("pmtAmt")}
          style={textStyle}
          required
          disabled={!isNew}
        />
        {pmtAmtError && (
          <div style={{ color: 'red', fontSize: '12px', marginTop: '-1px' }}>
            {pmtAmtError}
          </div>
        )}
        {/* @todo if this is always disabled, remove field from form validation */}
        <CurrencyInput
          label="Finance Charge"
          {...formState.getFieldProps("snFinChg")}
          style={textStyle}
          required
          disabled
        />
        <TextInput
          label="SN Detail"
          {...formState.getFieldProps("snDetail")}
          style={textStyle}
          required
          readOnly={!isNew}
        />
      </SectionColumn>
    </SectionColumn>
  );
};

export default RightContainer;
