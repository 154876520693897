import { FC } from "react";
// layout
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { TextInput } from "@/components/inputs/text/TextInput";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useRequestRewriteCtx } from "./RequestRewriteProvider";
// utils
import { PaymentInterval } from "@/enums/payment";

const textStyle = { marginTop: "1px", marginBottom: "1px" };

/** ### Right column form fields for Sidenote Form */
const RightContainer: FC = () => {
  const formState = useRequestRewriteCtx((s) => s.formState);
  const isNew = useRequestRewriteCtx((s) => s.addingNewRequestRewrite);
  
  return (
    <SectionColumn p={0} gap={3}>
      <SectionColumn>
        <TextInput
          label="Reason For Amendment"
          {...formState.getFieldProps("reason")}
          style={textStyle}
          required
          disabled={!isNew}
          errors={!!formState.getFieldProps("reason").errors}
        />
        <DropdownInput
          label="Schedule"
          {...formState.getFieldProps("schedule")}
          required
          data={PaymentInterval.options}
          disabled={!isNew}
          errors={!!formState.getFieldProps("schedule").errors}
        />
        <CurrencyInput
          label="Payment Amount"
          {...formState.getFieldProps("paymentAmount")}
          required
          style={textStyle}
          disabled={!isNew}
        />
        <MuiKendoDateField
          label="Beginning"
          {...formState.getFieldProps("startDate")}
          required
          disabled={!isNew}
        />
        <TextInput
          label="Requested By"
          {...formState.getFieldProps("requestedBy")}
          style={textStyle}
          disabled={true}
        />
      </SectionColumn>
    </SectionColumn>
  );
};

export default RightContainer;
