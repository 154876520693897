import { FC, useEffect, useState } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { useRequestRewriteCtx } from "./RequestRewriteProvider";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import SectionBody from "@/mui/components/form/Section/SectionBody";
import Section from "@/mui/components/form/Section";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
import { LoadingButton } from "@mui/lab";
import { requestRewriteService } from "@/services/requestRewriteService";
import { RequestForRewriteForm } from "@/interfaces/RequestRewrite";
import validateReqBody from "@/utils/forms/validateReqBody";
import { useBlocker, useNavigate } from "react-router-dom";
import NavBlock from "@/utils/forms/NavBlock";
import { toast } from "react-toastify";
import dayjs from "dayjs";

/** ###  */
const RequestRewriteForm: FC = () => {
  const navigate = useNavigate();    
  const addingNew = useRequestRewriteCtx((s) => s.addingNewRequestRewrite);
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [success, setSuccess] = useState(false);  
  const appRecId = useRequestRewriteCtx((s) => s.appRecId);  
  const activeRequestRewrite = useRequestRewriteCtx((s) => s.selectedRequestRewrite);  
  const requestRewrites = useRequestRewriteCtx((s) => s.requestRewrites);  
  const { form, formState } = useRequestRewriteCtx((s) => s);    
  const { isValid, isDirty,  } = formState;
  const reqBody = validateReqBody(RequestForRewriteForm, form);
  const isReqValid = reqBody.isValid;
  const isDisabled = addingNew && !isDirty || !isValid || !isReqValid;
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    console.log({isDirty, success});
    return (isDirty && !success) && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleSubmit = async () => {
    try {
      if (!reqBody.reqBody) {
        console.warn("Invalid payload:", { reqBody: reqBody.validation.error, form });
        return;
      }
      setIsSubmitting(true);

      if (addingNew) {
        const request = await requestRewriteService.postRequestForRewrite(reqBody.reqBody);
        if (typeof request?.recId === "number") {
          toast.success("Request rewrite added successfully");
          setSuccess(true);
        } else {
          toast.error("Error posting request rewrite");
          console.error("Error posting request rewrite", request);
        }
      } else {
        //Print document
      }

    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => navigate("..");

  useEffect(() => {
    console.log({ activeRequestRewrite });
    if (!activeRequestRewrite) return;

    const loadPreviousAmmendment = async () => {
      try {
        if (!appRecId) return;
        const response = await requestRewriteService.getPreviousAmendments(appRecId);
        activeRequestRewrite.previousAmendments = response?.previousAmendments ? "Yes" : "No";
        activeRequestRewrite.previousDate = response?.previousDate ? dayjs(response?.previousDate).startOf("day") : null;
      } catch (error) {
        console.error("Error loading previous ammendments", error);
      } finally {
        formState.resetToDefault(activeRequestRewrite, true);
      }
    };
    loadPreviousAmmendment();    
  }, [activeRequestRewrite])

  useEffect(() => {
    if (success) {
      requestRewrites.load();
      navigate("..");
    }
  }, [success, navigate]);
  
  return (
    <AccountsMainPanel navBarTitle="Request Rewrite" loading={false}>
      <Section expanded={true}>
        <SectionBody
          sx={{ py: 0 }}
          leftColElems={<LeftContainer />}
          rightColElems={<RightContainer />}
        />
        <SectionBody>
          <Grid container direction="row" flex={1} justifyContent="flex-end" gap={1}>
            <LoadingButton onClick={handleSubmit} loading={isSubmitting} disabled={isDisabled}>
              Submit
            </LoadingButton>
            <LoadingButton onClick={handleCancel} loading={isSubmitting}>
              Cancel
            </LoadingButton>            
          </Grid>
        </SectionBody>
        {!success && <NavBlock blocker={blocker} />}
      </Section>
    </AccountsMainPanel>
  );
};

export default RequestRewriteForm;
