import { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// mui
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// interfaces
import { Filter } from '@/interfaces/requests';
import { AllowedFilterKey as AllowedAcctFilterKey } from '../../accountsSubviews/default';
import { AllowedFilterKey as AllowedPmtFilterKey } from '../../pmtSubviews/default';
// utils
import {
  DateRangeOptionKey,
  dateRangeOptions,
  dateRangeOptionLabels,
  formatDateTz,
} from '@/general/dates';
dayjs.extend(utc);

/** ###  */
const DateRangeDropdown: FC<{
  field: AllowedAcctFilterKey | AllowedPmtFilterKey;
  inputValue: DateRangeOptionKey;
  setInputValue: (v: DateRangeOptionKey) => void;
  setForm: (fromFilter: Filter, toFilter: Filter) => void;
}> = ({ field, inputValue, setInputValue, setForm }) => {
  return (
    <Autocomplete
      sx={{ minWidth: '200px' }}
      options={dateRangeOptionLabels}
      disableClearable
      value={inputValue}
      onChange={(_, dateRangeLabel) => { setInputValue(dateRangeLabel as DateRangeOptionKey); const selectedRange = dateRangeOptions[dateRangeLabel as DateRangeOptionKey];

        if (!selectedRange) { console.error(`Invalid date range label: ${dateRangeLabel}`); return; }

        const { beginDate, endDate } = selectedRange;

        // Create "Date from" filter
        const initDateFromFilter: Filter = {
          field,
          operator: '>=',
          value: formatDateTz(beginDate),
        };
        // Create "Date to" filter
        const initDateToFilter: Filter = {
          field,
          operator: '<=',
          value: formatDateTz(endDate),
        };
        setForm(initDateFromFilter, initDateToFilter);
      }}
      renderInput={(p) => <TextField {...p} label="Date Range" />}
    />
  );
};

export default DateRangeDropdown;
