import { FC } from "react";
import Box from "@mui/material/Box";
import AcctMiscCard from "./AcctMiscCard";
import VehicleStatusCard from "./VehicleStatusCard";
import AcctStatusCard from "./AcctStatusCard";
import GpsCard from "../../components/GpsView/GpsCard";

/** ### Layout for left container of account-detail view */
const LeftContainer: FC = () => (
  <Box
    sx={{ 
      width: "380px", 
      minWidth: "350px", 
      maxWidth: "380px", 
      height: '100%', 
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden', // Prevent scrolling at this level
      '& > *': {
        flexShrink: 0, // Prevent individual items from shrinking
        width: '100%'   // Ensure full width
      }
    }}
  >
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: '20px', 
      overflowY: 'auto', 
      height: '100%',
      pr: '10px' // Add some padding for potential scrollbar
    }}>
      <AcctMiscCard />
      <VehicleStatusCard />
      <AcctStatusCard />
      <GpsCard />
    </Box>
  </Box>
);

export default LeftContainer;