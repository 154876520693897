import { FC, ReactNode } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './activityPanel.module.scss';

interface StandardizedActivityCardProps {
  icon: ReactNode;
  timestamp: string;
  iconContainerClassName?: string;
  children: ReactNode;
}

/**
 * A wrapper component to ensure all activity cards have consistent layout
 */
const StandardizedActivityCard: FC<StandardizedActivityCardProps> = ({
  icon,
  timestamp,
  iconContainerClassName = styles.messageIconContainer,
  children,
}) => {
  return (
    <Grid container direction="column">
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={iconContainerClassName}>
            {icon}
          </div>
          <div className={styles.messageBody}>
            {children}
          </div>
        </div>
        <div className={styles.messageRight}>
          {timestamp}
        </div>
      </div>
    </Grid>
  );
};

export default StandardizedActivityCard;