import { FC } from "react";
// mui
import MuiKendoDateField from "@/mui/components/form/MuiKendoDateField";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
// kendo
import { TextInput } from "@/components/inputs/text/TextInput";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// components
// state
import { useRequestRewriteCtx } from "./RequestRewriteProvider";
// validation

const textStyle = { marginTop: "1px", marginBottom: "1px", height: "38px" };

/** ###  */
const LeftContainer: FC = () => {
  const formState = useRequestRewriteCtx((s) => s.formState);
  return (
    <SectionColumn p={0}>
      <MuiKendoDateField
        {...formState.getFieldProps("requestedDate")}
        label="Request Date"
        readOnly={true}
      />
      <TextInput
        {...formState.getFieldProps("accountNumber")}
        label="Account"
        style={textStyle}
        readOnly={true}
      />
      <TextInput
        {...formState.getFieldProps("buyerName")}
        label="Buyer"
        style={textStyle}
        readOnly={true}
      />
      <TextInput
        {...formState.getFieldProps("previousAmendments")}
        label="Previous Amendments"
        style={textStyle}
        readOnly={true}
      />
      <MuiKendoDateField
        {...formState.getFieldProps("previousDate")}
        label="When"
        readOnly={true}
      />
      <CurrencyInput
        {...formState.getFieldProps("pastDue")}
        label="Amount Past Due"
        style={textStyle}
        readOnly={true}
      />
    </SectionColumn>
  );
};

export default LeftContainer;
