import { PaymentInterval } from "@/enums";
import { zDayjs } from "@/utils/forms/fieldSchemas";
import dayjs from "dayjs";
import { z } from "zod";

export const RequestForRewriteRes = z.object({
  recId: z.number().nullish(),
  requestedDate: z.any().nullish(),
  startDate: z.any().nullish(),
  accountNumber: z.string().nullish(),
  reason: z.string().nullish(),
  requestedBy: z.string().nullish(),
  status: z.string().nullish(),
  pastDue: z.number().nullish(),
  buyerName: z.string().trim().min(1),
  previousAmendments: z.string().default("No"),
  previousDate: zDayjs.nullable().default(null),
  schedule: PaymentInterval.default("Weekly"),
  paymentAmount: z.number().positive().default(0),
  appRecId: z.number().positive().default(0),
  colRecId: z.number().positive().default(0),
});

export type RequestForRewriteRes = z.infer<typeof RequestForRewriteRes>;
export type RequestForRewrite = z.infer<typeof RequestForRewriteRes>;

export const RequestForRewriteForm = z.object({
  requestedDate: zDayjs.default(dayjs().local()),
  accountNumber: z.string().trim().min(1),
  buyerName: z.string().trim().min(1),
  previousAmendments: z.string().nullable().default(null),
  previousDate: zDayjs.nullable().default(null),
  pastDue: z.number().positive().default(0),
  reason: z.string().trim().min(1),
  schedule: PaymentInterval.default("Weekly"),
  paymentAmount: z.number().positive().default(0),
  startDate: zDayjs.default(dayjs().add(7, "days").local()),
  requestedBy: z.string().trim().min(1),
  appRecId: z.number().positive().default(0),
  colRecId: z.number().positive().default(0),
});
export type RequestForRewriteForm = z.infer<typeof RequestForRewriteForm>;

export const PreviousAmendments = z.object({
  previousAmendments: z.boolean().default(false),
  previousDate: zDayjs.nullable().default(null),
});
export type PreviousAmendments = z.infer<typeof PreviousAmendments>;
