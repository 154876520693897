import { FC } from 'react';
import { Link } from 'react-router-dom';
import { GridCellProps } from '@progress/kendo-react-grid';
import { AccountDb } from '../interfaces';

// Use your MUI color (LINK_BLUE) or any color you'd like
import { LINK_BLUE } from '@/mui/theme/colors';

export const StockNumLinkCell: FC<GridCellProps> = (props) => {
  const row = props.dataItem as AccountDb;
  const { vehRecId, stockNum } = row;

  const linkPath = `/inventory/${vehRecId}/vehicle?list=sold`;

  return (
    <td className="k-table-td">
      <Link
        to={linkPath}
        style={{ color: LINK_BLUE, textDecoration: 'underline' }}
      >
        {stockNum}
      </Link>
    </td>
  );
};
