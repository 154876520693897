import { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { debounce } from "lodash-es";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useSalesViewCtx } from "../../SalesViewProvider";
import { useSalesListCtx } from "../SalesListProvider";
import { useTableCtx, ITableCtx } from "./TableProvider";
// utils
import { fetchMappedCompanies, fetchSalesList } from "./requests";
// interfaces
import { SalesSubview } from "../../enums";

export const useSalesListViewData = () => {
  const subview = useParams().subview as SalesSubview;
  const compId = useAuthSelector((s) => s.compId);
  const viewState = useSalesViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const listViewState = useSalesListCtx((s) => s);

  // Create refs to hold viewState and listViewState so they don't trigger re-creation of our debounced callback.
  const viewStateRef = useRef(viewState);
  useEffect(() => {
    viewStateRef.current = viewState;
  }, [viewState]);

  const listViewStateRef = useRef(listViewState);
  useEffect(() => {
    listViewStateRef.current = listViewState;
  }, [listViewState]);

  // Use a ref to track the last time we fetched to avoid duplicate calls.
  const lastFetchTimeRef = useRef<number>(0);
  const MIN_FETCH_INTERVAL = 100; // milliseconds

  // Initial fetch effect: runs when these stable values change.
  useEffect(() => {
    if (tableState.sorts.length > 0) {
      const now = Date.now();
      if (now - lastFetchTimeRef.current < MIN_FETCH_INTERVAL) {
        return;
      }
      lastFetchTimeRef.current = now;
      fetchSalesList(subview, viewState, tableState, listViewState);
      fetchMappedCompanies(listViewState.setMappedCompaniesCt);
    }
    // We stringifiy arrays/objects to avoid unnecessary re-calls.
  }, [
    tableState.dateRangeFilter
      ? `${tableState.dateRangeFilter.from.toString()}${tableState.dateRangeFilter.to.toString()}`
      : "",
    tableState.filters.map((f) => `${f.field.toString()}-${f.operator}-${f.value}`).join(""),
    tableState.sorts.map((s) => `${s.field.toString()}-${s.dir}`).join(""),
    tableState.page.skip,
    tableState.page.take,
    tableState.pageSizeValue,
    listViewState.subviewInfo.route,
    compId,
  ]);

  // Debounce the fetch when the text search filter changes.
  // Note: We depend only on subview here; viewState and listViewState are accessed via refs.
  const debouncedFetch = useCallback(
    debounce((subview: SalesSubview, tableState: ITableCtx) => {
      fetchSalesList(subview, viewStateRef.current, tableState, listViewStateRef.current);
      fetchMappedCompanies(listViewStateRef.current.setMappedCompaniesCt);
    }, 400),
    [subview]
  );

  // Skip the initial text search effect so that it doesn't fire on mount.
  const initialTextSearch = useRef(true);
  useEffect(() => {
    if (initialTextSearch.current) {
      initialTextSearch.current = false;
      return;
    }
    if (tableState.textSearchFilter !== null && tableState.sorts.length > 0) {
      debouncedFetch(subview, tableState);
    }
    // Cancel any pending debounced calls when dependencies change.
    return () => {
      debouncedFetch.cancel();
    };
    // Use JSON.stringify to stabilize sorts dependency.
  }, [tableState.textSearchFilter, debouncedFetch, subview, JSON.stringify(tableState.sorts)]);

  return listViewState.salesListRows;
};
