import { FC, PropsWithChildren, createContext, useState, useEffect } from "react";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import { companyService } from "@/services/companyService";
import { useAuthSelector } from "@/features/auth/authSlice";
import { CompanyAddOnsTax, CompanyAddOnsTaxForm } from "@/interfaces/CtxAdd";
import useReq from "@/utils/useReq";
import { useForm } from "react-hook-form";
import { useMatch, useNavigate, useParams } from "react-router-dom";

const defaultValues: CompanyAddOnsTaxForm = {
  recId: 0,
  price1: null,
  price2: null,
  price3: null,
  price4: null,
  price5: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  cost1: null,
  cost2: null,
  cost3: null,
  cost4: null,
  cost5: null,
  cost6: null,
  cost7: null,
  cost8: null,
  cost9: null,
  cost10: null,
  stateRate: null,
  stateOn: null,
  stateMax: null,
  stateMin: null,
  countyRate: null,
  countyOn: null,
  countyMax: null,
  countyMin: null,
  cityRate: null,
  cityOn: null,
  cityMax: null,
  cityMin: null,
  locRate: null,
  locOn: null,
  locMax: null,
  locMin: null,
  addOn1: null,
  addOn2: null,
  addOn3: null,
  addOn4: null,
  addOn5: null,
  addOn6: null,
  addOn7: null,
  addOn8: null,
  addOn9: null,
  addOn10: null,
  stateTax1: false,
  stateTax2: false,
  stateTax3: false,
  stateTax4: false,
  stateTax5: false,
  stateTax6: false,
  stateTax7: false,
  stateTax8: false,
  stateTax9: false,
  stateTax10: false,
  cityTax1: false,
  cityTax2: false,
  cityTax3: false,
  cityTax4: false,
  cityTax5: false,
  cityTax6: false,
  cityTax7: false,
  cityTax8: false,
  cityTax9: false,
  cityTax10: false,
  countyTax1: false,
  countyTax2: false,
  countyTax3: false,
  countyTax4: false,
  countyTax5: false,
  countyTax6: false,
  countyTax7: false,
  countyTax8: false,
  countyTax9: false,
  countyTax10: false,
  locTax1: false,
  locTax2: false,
  locTax3: false,
  locTax4: false,
  locTax5: false,
  locTax6: false,
  locTax7: false,
  locTax8: false,
  locTax9: false,
  locTax10: false,
  maxTradeAllow: null,
};

const useCtxState = () => {
  const navigate = useNavigate();
  const compId = useAuthSelector((s) => s.compId);
  const [profiles, setProfiles] = useState<CompanyAddOnsTax[] | undefined>(undefined);
  const [activeProfile, setActiveProfile] = useState<CompanyAddOnsTaxForm | undefined>(undefined);
  const [profileName, setProfileName] = useState<string | null>("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const match = useMatch({ path: "/:root/:viewRoot/:currentView/:id" });
  const addOnsId =
    match?.params?.id && (match.params.id === "new" ? 0 : Number.parseInt(match.params.id));

  const addOnsSalesTaxForm = useForm<CompanyAddOnsTaxForm>({
    defaultValues,
    mode: "onChange",
  });

  const { reset, getValues } = addOnsSalesTaxForm;

  const addOnsSalesTaxRows = useReq(async () => {
    if (!compId) return;
    return await companyService.getAddOnsSalesTaxSettings(compId);
  });

  const loadAddOnsSalesTaxList = async () => {
    const addOnsSalesTaxData = addOnsSalesTaxRows.value?.sort((a, b) => a.recId! - b.recId!);
    if (!addOnsSalesTaxData || !Array.isArray(addOnsSalesTaxData)) {
      setProfiles([]);
      setActiveProfile(undefined);
      return;
    }
    setProfiles(addOnsSalesTaxData);

    if (addOnsId) {
      var profile = addOnsSalesTaxData.find((lienholder) => lienholder.recId == addOnsId);
      setActiveProfile(profile);
      reset(profile);
    }

    if (addOnsSalesTaxData.length > 0 && addOnsSalesTaxData[0]) {
      const currentActiveRow =
        addOnsSalesTaxData.find((s) => activeProfile?.recId === s.recId) ?? addOnsSalesTaxData[0];
      setActiveProfile(currentActiveRow);
    } else {
      setActiveProfile(undefined);
    }
  };

  // Precompute a Set of valid keys to reduce repetitive computation
  const validKeys = new Set<keyof CompanyAddOnsTaxForm>(
    Object.keys(defaultValues) as (keyof CompanyAddOnsTaxForm)[]
  );

  // Use a Map for faster lookups if `profiles` doesn't change often
  const profilesMap = profiles
    ? new Map(profiles.map((profile) => [profile.recId, profile]))
    : null;

  const handleRowClick = (recId: number) => {
    if (!profilesMap) return;

    const selectedProfile = profilesMap.get(recId); // O(1) lookup
    if (!selectedProfile) {
      console.warn(`Profile with recId ${recId} not found`);
      return;
    }

    // Reduce the profile to include only valid fields
    const limitedProfile = Object.fromEntries(
      Object.entries(selectedProfile).filter(
        ([key, value]) => validKeys.has(key as keyof CompanyAddOnsTaxForm) && value !== undefined
      )
    ) as Partial<CompanyAddOnsTaxForm>;

    // Safely reset with the limited profile and update activeProfile
    reset(limitedProfile);
    setProfileName(selectedProfile.saleType);
    setActiveProfile(limitedProfile as CompanyAddOnsTaxForm);
    navigate(`${recId}`);
  };

  useEffect(() => {
    addOnsSalesTaxRows.load();
  }, [compId]);

  useEffect(() => {
    loadAddOnsSalesTaxList();
  }, [addOnsSalesTaxRows.value]);

  useEffect(() => {
    if (addOnsSalesTaxRows.value) {
      var profile = addOnsSalesTaxRows.value.find((addOns) => addOns.recId == addOnsId);
      if (profile) {
        setActiveProfile(profile);
        setProfileName(profile.saleType);
        reset(profile);
        return;
      }
    }
    
    setActiveProfile(undefined);
    setProfileName("");
    reset(defaultValues); // Reset the form with updated values
  }, [addOnsId]);

  return {
    isLoading: addOnsSalesTaxRows.isLoading,
    resetToDefault: () => reset(defaultValues),
    profiles,
    setProfiles,
    activeProfile,
    setActiveProfile,
    profileName,
    setProfileName,
    addOnsSalesTaxForm,
    handleRowClick,
    isConfirmOpen,
    setIsConfirmOpen,
    isSubmitting,
    setIsSubmitting,
    addOnsSalesTaxRows,
    loadAddOnsSalesTaxList,
    loadAddOnsSalesTaxProfiles: addOnsSalesTaxRows,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type AddOnsSalesTaxCtx = ICtx;
export const useAddOnsSalesTaxCtx = useCtxFactory(Ctx);

const AddOnsSalesTaxProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default AddOnsSalesTaxProvider;
