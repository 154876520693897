import { FC, useState } from "react";
import { toast } from "react-toastify";
// mui
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import { getRowId } from "@/mui/components/dataTable/utils";
// components
import { DropDownButton, Loader } from "@/components";
import CommonModal from "@/mui/components/CommonModal";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";
// state
// import { useRepoCompanyProviderCtx } from "../RepoCompanyProvider";
// utils
import { tableColumns } from "./tableColumns";
import { initPage, tableStyle } from "@/features/Settings/default";
// interfaces
import { useLienholdersCtx } from "../LienHolderProvider";
import LienHolderDetail from "../LienHolderDetail";

const rowHeightPx = 60;
const modalSx: Grid2Props | undefined = {
  display: "flex",
  p: 3,
  overflow: "hidden",
  gap: 2,
  width: "70%",
};

const AddNewButton: FC = () => {  
  const loadLienHolders = useLienholdersCtx((s) => s.loadLienHolders);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropDownButton 
      open={isOpen} 
      setOpen={(open) => open && setIsOpen(true)}
      label="Add New Lien Holder"
    >
      <CommonModal 
        open={isOpen} 
        onClose={() => setIsOpen(false) }
        containerProps={modalSx}
      >
        <LienHolderDetail
          isNew={true} 
          onSubmit={() => { 
            setIsOpen(false);
            loadLienHolders.load();
            toast.success("Lien Holder created successfully");
          }} 
          onCancel={() => setIsOpen(false)}
        /> 
      </CommonModal>
    </DropDownButton>
  );
};

const LienHolderTable: FC = () => {
  const lienHolderRows = useLienholdersCtx((s) => s.lienholders ?? []);
  const isLoading = useLienholdersCtx((s) => s.isLoading);
  const onRowClick = useLienholdersCtx((s) => s.handleRowClick);

  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageModel, setPageModel] = useState(initPage);

  return (
    <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT }}>
      <Grid component="h3">Lien Holders</Grid>
      {isLoading ? (
        <Loader size="large" />
      ) : (
        <DataGrid
          rowHeight={rowHeightPx}
          loading={isLoading}
          rows={lienHolderRows}
          columns={tableColumns}
          getRowId={getRowId("recId")}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          paginationModel={pageModel}
          onPaginationModelChange={setPageModel}
          slots={{ toolbar: TableToolbar }}
          slotProps={{ toolbar: { placeholder: "Search filter", ButtonGroup: AddNewButton }}}
          sx={tableStyle}
          onRowClick={(params) => onRowClick(params.row.recId)}
          style={{ cursor: "pointer" }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                recId: false, // Hides the recId column
              },
            },
          }}
        />
      )}
    </Grid>
  );
};

export default LienHolderTable;
