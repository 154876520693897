import { FC, useEffect, useState } from "react";
// mui
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import TableToolbar from "@/mui/components/dataTable/TableToolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { getRowId } from "@/mui/components/dataTable/utils";
import { MuiColumnDef } from '@/mui/components/dataTable/interfaces';
// components
import { BG_HIGHLIGHT } from "@/mui/theme/colors";
// utils
import { initPage, tableStyle } from "@/features/Settings/default";
import { Loader } from "@progress/kendo-react-all";
import { stateCellFmt } from '@/mui/components/dataTable/cellFormatUtils';
import { collectionsService, TextTemplate } from '@/services/collectionsService';
import { useAuthSelector } from "@/features/auth/authSlice";


const tableColumns:MuiColumnDef[] = [
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    minWidth: 120,
    valueFormatter: stateCellFmt,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 120,
    valueFormatter: stateCellFmt,
  },
  {
    field: 'body',
    headerName: 'Description',
    flex: 2,
    minWidth: 200,
    valueFormatter: stateCellFmt,
  }
]

const TextTemplateModalContent: FC<{ onRowClick: (params: any) => void }> = ({onRowClick}) => {  
  // const repoCompanyRows = //useRepoCompanyProviderCtx((s) => s.repoCompanies ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [textTemplate, setTextTemplate] = useState<TextTemplate[]>([]);
  const { compId } = useAuthSelector((state) => state);


  // Table params
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pageModel, setPageModel] = useState(initPage);
  const rowHeightPx = 60;

  const initData = async () => {
    setIsLoading(true);

    const [
      getTextTemplates,
    ] = await Promise.all([
      collectionsService.getTextTemplates(compId!),
    ]);   

    setTextTemplate(getTextTemplates.filter((t) => t.category === 'Collections'));
    setIsLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Grid py={2} px={4} borderRadius={2.5} sx={{ backgroundColor: BG_HIGHLIGHT }}>
      <Grid component="h3">Text Templates</Grid>
      {isLoading ? (
        <Loader size="large" />
      ) : (
        <DataGrid
          rowHeight={rowHeightPx}
          loading={isLoading}
          rows={textTemplate}
          columns={tableColumns}
          getRowId={getRowId("recId")}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          paginationModel={pageModel}
          onPaginationModelChange={setPageModel}
          slots={{ toolbar: TableToolbar }}
          slotProps={{ toolbar: { placeholder: "Search filter" }}}
          sx={tableStyle}
          onRowClick={(params) => onRowClick(params)}
          style={{ cursor: "pointer" }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                recId: false, // Hides the recId column
              },
            },
          }}
        />
      )}
    </Grid>
  );
};

export default TextTemplateModalContent;

