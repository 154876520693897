import { FC } from "react";
// kendo
import Hotlist from "@/components/hotlist/Hotlist";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// style
import styles from "./LeftContainer.module.scss";

interface AcctStatusCardProps {
  loading?: boolean;
}

/** ###  */
const AcctStatusCard: FC<AcctStatusCardProps> = ({ loading }) => {
  // Get the appRecId from the store
  const appRecId = useAccountSelector((s) => s.accountInformation?.appRecId);
  
  return (
    <div className={styles.accountStatusCard} style={{ gap: "8px" }}>
      <div className={styles.title}>Account Status</div>
      <Hotlist 
        recId={appRecId ?? null} 
        transType="Account" 
        initialStatusFilter="Active" 
      />
    </div>
  );
};

export default AcctStatusCard;