import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// Components
import { TextInput } from "@/components/inputs/text/TextInput";
import { PhoneInput } from "@/components/inputs/phoneInput/PhoneInput";
import { SectionHeader } from "@/components/sectionHeader/SectionHeader";
import { Button } from "@/components/button/Button";
import CompanyDefaults from "./CompanyDefaults"; // Import the CompanyDefaults component
import { useCompanyMgmtCtx } from "./CompanyMgmtProvider"; // Import the context hook
import { Grid, Typography } from "@mui/material"; // Import MUI components
import { Loader } from "@/components";
import { toast } from "react-toastify";
import { companyService } from "@/services/companyService";
import { NumberInput } from "@/components";
import AddressAutocomplete from "@/components/inputs/addressAutocomplete/AddressAutocomplete";
import { getAddressFromGoogleMaps } from "@/utils/helpers/general";
import { useBlocker } from "react-router-dom";
import NavBlock from "@/utils/forms/NavBlock";
import ConfirmationModal from "@/mui/components/ConfirmationModal";
import { systemService } from "@/services/systemService";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";
import UploadModal from "./UploadModal";
import { Icons } from "@/components/icons";

interface FormValues {
  recId: number;
  orgRecId: number; // Organization Record ID
  compId: number; // Company ID
  companyName: string;
  shortName: string;
  legalName: string;
  abbreviation: string;
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  phoneNumber: string;
  phone800: string;
  fax: string;
  federalId: string;
  dealerNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  lessorNumber: string;
  timeZone: string;
  adjustDaylightSavingsTime: boolean;
  companyLogo: FileList | null;
  minCreditCardAmount: number;
  salesTaxNum: string;

  // Ecom Setup Fields
  voicePhone: string;
  forwardCallsTo: string;
  emailFrom: string;
  emailDisplayName: string;
}

const CompanyDetails: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Tracks the currently selected tab

  // Local loading state
  const [isLoading, setIsLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState<string | null>(null); // State for the logo preview
  const [uploadedBlobName, setUploadedBlobName] = useState<string | null>(null);
  // Upload Modal states
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [selectedLogoFile, setSelectedLogoFile] = useState<File | null>(null);
  const [logoInputError, setLogoInputError] = useState(false);

  // Access company details from context
  const { companyDetails, error, refetchCompanyDetails } = useCompanyMgmtCtx((state) => ({
    companyDetails: state.companyDetails,
    error: state.error,
    refetchCompanyDetails: state.refetchCompanyDetails,
  }));

  const { control, handleSubmit, reset, watch, setValue, formState } = useForm<FormValues>({
    defaultValues: {
      orgRecId: 0,
      compId: 0,
      companyName: "",
      shortName: "",
      legalName: "",
      abbreviation: "",
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      phoneNumber: "",
      phone800: "",
      fax: "",
      federalId: "",
      dealerNumber: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      lessorNumber: "",
      timeZone: "",
      adjustDaylightSavingsTime: false,
      companyLogo: null,
      minCreditCardAmount: 0,
      salesTaxNum: "",
      // Ecom Setup Fields
      voicePhone: "",
      forwardCallsTo: "",
      emailFrom: "",
      emailDisplayName: "",
    },
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const handleCloseConfirmationModal = () => setIsConfirmOpen(false);

  // Block navigation if form is dirty
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return formState.isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleAddressPlaceChange = (place: google.maps.places.PlaceResult) => {
    const fullAddress = getAddressFromGoogleMaps(place);
    setValue("addressLine1", fullAddress.address || "");
    setValue("city", fullAddress.city || "");
    setValue("state", fullAddress.state || "");
    setValue("zip", fullAddress.zip || "");
    setValue("county", fullAddress.county || "");
  };

  useEffect(() => {
    if (!companyDetails && !error) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (companyDetails) {
      reset({
        orgRecId: companyDetails.orgRecId || 0,
        compId: companyDetails.compId || 0,
        companyName: companyDetails.companyName || "",
        shortName: companyDetails.shortName || "",
        legalName: companyDetails.legalName || "",
        abbreviation: companyDetails.abbreviation || "",
        addressLine1: companyDetails.address || "",
        city: companyDetails.city || "",
        state: companyDetails.state || "",
        zip: companyDetails.zip || "",
        county: companyDetails.county || "",
        phoneNumber: companyDetails.phone || "",
        phone800: companyDetails.phone800 || "",
        fax: companyDetails.fax || "",
        federalId: companyDetails.federalId || "",
        dealerNumber: companyDetails.dealerNumber || "",
        contactName: companyDetails.contactName || "",
        contactEmail: companyDetails.contactEmail || "",
        contactPhone: companyDetails.contactPhone || "",
        lessorNumber: companyDetails.lessorNumber || "",
        timeZone: companyDetails.timeZone || "",
        adjustDaylightSavingsTime: companyDetails.dayLight ?? false,
        companyLogo: null,
        salesTaxNum: companyDetails.salesTaxNum || "",
        // Ecom Setup
        voicePhone: companyDetails.voicePhone || "",
        forwardCallsTo: companyDetails.forwardCallsTo || "",
        emailFrom: companyDetails.emailFrom || "",
        emailDisplayName: companyDetails.emailDisplayName || "",
      });
      // Optionally fetch the existing logo right away:
      fetchLogo();
    }
  }, [companyDetails, error, reset]);

  const fetchLogo = async () => {
    if (!companyDetails?.companyLogo) {
      setLogoPreview("imagePlaceholder");
      setIsLoading(false);
      return;
    }
    try {
      let blobName = companyDetails.companyLogo;
      if (blobName.startsWith("http")) {
        setLogoPreview(blobName);
        setIsLoading(false);
        return;
      }
      if (!blobName.startsWith(`orgid_${companyDetails.orgRecId}`)) {
        blobName = `orgid_${companyDetails.orgRecId}/${blobName}`;
      }
      const photoObject = [
        {
          orgId: companyDetails.orgRecId,
          containerName: "uploads",
          blobName,
        },
      ];
      const response = await systemService.getBlobReadUrls(photoObject);
      const logoUrl = response?.[0]?.url || "imagePlaceholder";
      setLogoPreview(logoUrl);
    } catch (error) {
      console.error("Error fetching logo:", error);
      setLogoPreview("imagePlaceholder");
    } finally {
      setIsLoading(false);
    }
  };
  // Handle logo upload via modal
  const handleUploadLogo = async () => {
    if (!selectedLogoFile) {
      setLogoInputError(true);
      return;
    }
    const file = selectedLogoFile;
    const maxFileSize = 2 * 1024 * 1024; // 2MB
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExt = file.name.split(".").pop()?.toLowerCase() || "";
    // Validate file type and size
    if (!allowedExtensions.includes(fileExt)) {
      toast.error("Invalid file format. Allowed formats: JPG, JPEG, PNG.");
      return;
    }
    if (file.size > maxFileSize) {
      toast.error("File size exceeds 2MB. Please upload a smaller file.");
      return;
    }
    setUploadingLogo(true);
    try {
      const blobName = `${crypto.randomUUID()}.${fileExt}`;
      const sasUploadUrl = await systemService.getBlobUploadUrl(companyDetails?.orgRecId || 0);
      await systemService.uploadBlob(file, companyDetails?.orgRecId || 0, blobName);

      const photoObject = [
        {
          orgId: companyDetails?.orgRecId || 0,
          containerName: "uploads",
          blobName: `orgid_${companyDetails?.orgRecId}/${blobName}`,
        },
      ];

      const readUrlResponse = await systemService.getBlobReadUrls(photoObject);

      const finalLogoUrl = readUrlResponse?.[0]?.url || "imagePlaceholder";
      setLogoPreview(finalLogoUrl);
      setUploadedBlobName(`orgid_${companyDetails?.orgRecId}/${blobName}`);

      toast.success("Company logo uploaded successfully!");
      setUploadModalOpen(false); // Close the upload modal
    } catch (error) {
      console.error("Error uploading company logo:", error);
      toast.error("Failed to upload the company logo. Please try again.");
    } finally {
      setUploadingLogo(false);
    }
  };

  const onSubmit = async (data: FormValues) => {
    const companyLogoUrl = uploadedBlobName || companyDetails?.companyLogo || "";

    // Build API payload
    const apiPayload = {
      RecId: data.compId,
      orgRecId: data.orgRecId || 0,
      companyName: data.companyName,
      shortName: data.shortName,
      legalName: data.legalName,
      Abv_Name: data.abbreviation,
      address: data.addressLine1,
      city: data.city,
      state: data.state,
      zip: data.zip,
      county: data.county,
      phone: data.phoneNumber,
      phone800: data.phone800 || data.phoneNumber,
      minCreditCardAmount: data.minCreditCardAmount,
      fax: data.fax,
      FedID: data.federalId,
      DealerNum: data.dealerNumber,
      contactName: data.contactName,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      LessorNum: data.lessorNumber,
      timeZone: data.timeZone,
      dayLight: data.adjustDaylightSavingsTime,
      // Here is where we store the actual blobName in the DB
      // so next time you call `fetchLogo()`, you can get the SAS read URL
      companyLogo: companyLogoUrl,
      Email_From: data.emailDisplayName,
      Email_From_Address: data.emailFrom,
      TwilioForwardIncomingCallsTo: data.forwardCallsTo,
      TwilioPhoneNumber: data.voicePhone,
      salesTaxNum: data.salesTaxNum,
    };

    try {
      setIsLoading(true);
      await companyService.updateCompanyDetails(apiPayload);
      toast.success("Company details updated successfully!");

      // Re-fetch the company details to ensure the data is fresh
      if (refetchCompanyDetails) {
        await refetchCompanyDetails();
      }
    } catch (err) {
      console.error("Error updating company details:", err);
      toast.error("Failed to update company details. Please try again.");
    } finally {
      setIsLoading(false);
      handleCloseConfirmationModal();
    }
  };

  if (isLoading) {
    return <Loader size="large" />;
  }
  if (!companyDetails) {
    return <Loader size="large" />;
  }

  const displayLogoPreview = () => {
    if (logoPreview !== 'imagePlaceholder'){
      return <img
        src={
          logoPreview || "https://via.placeholder.com/200"
        }
        alt="Company Logo Preview"
        style={{
          maxWidth: "350px",
          maxHeight: "350px",
          objectFit: "contain",
        }}
        />
    } else {
      return <div 
                style={{
                  width: "300px",
                  height: "300px",
                  margin: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fff' 
                }}>  
              <Icons.ImagePlaceholder style={{ width: 140, height: 140, color: '#ccc' }} />
            </div>
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        flex={1}
        component="header"
        width="100%"
        marginTop={2}
        marginBottom={2}
        py={2}
        px={4}
        borderRadius={2.5}
        sx={{ backgroundColor: BG_HIGHLIGHT }}
      >
        <Grid component="h2" marginTop={0}>
          Company Details
        </Grid>
      </Grid>
      {selectedTab === 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            flex={1}
            marginTop={2}
            marginBottom={2}
            py={2}
            px={4}
            borderRadius={2.5}
            sx={{
              width: "100%",
              backgroundColor: BG_HIGHLIGHT,
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <SectionHeader title="General Information" />
              </Grid>

              {/* Main 2-column layout */}
              <Grid item>
                <Grid container spacing={6}>
                  {/* LEFT COLUMN */}
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2}>
                      {/* Company ID */}
                      <Grid item>
                        <Controller
                          name="compId"
                          control={control}
                          render={({ field }) => (
                            <NumberInput label="Company ID" disabled format="" {...field} />
                          )}
                        />
                      </Grid>
                      {/* Company Name */}
                      <Grid item>
                        <Controller
                          name="companyName"
                          control={control}
                          rules={{ required: "Company Name is required" }}
                          render={({ field }) => <TextInput label="Company Name*" {...field} />}
                        />
                      </Grid>
                      {/* Short Name */}
                      <Grid item>
                        <Controller
                          name="shortName"
                          control={control}
                          rules={{ required: "Short Name is required" }}
                          render={({ field }) => <TextInput label="Short Name*" {...field} />}
                        />
                      </Grid>
                      {/* Legal Name */}
                      <Grid item>
                        <Controller
                          name="legalName"
                          control={control}
                          rules={{ required: "Legal Name is required" }}
                          render={({ field }) => <TextInput label="Legal Name*" {...field} />}
                        />
                      </Grid>
                      {/* Abbreviation */}
                      <Grid item>
                        <Controller
                          name="abbreviation"
                          control={control}
                          rules={{ required: "Abbreviation is required" }}
                          render={({ field }) => <TextInput label="Abbreviation*" {...field} />}
                        />
                      </Grid>

                      {/* Address */}
                      <Grid item>
                        <Controller
                          name="addressLine1"
                          control={control}
                          rules={{ required: "Address is required" }}
                          render={({ field }) => (
                            <AddressAutocomplete
                              label="Address*"
                              {...field}
                              onPlaceSelected={handleAddressPlaceChange}
                              fullAddress={field.value || ""}
                            />
                          )}
                        />
                      </Grid>
                      {/* City */}
                      <Grid item>
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: "City is required" }}
                          render={({ field, fieldState }) => (
                            <TextInput label="City*" {...field} errors={!!fieldState.error} />
                          )}
                        />
                      </Grid>
                      {/* State */}
                      <Grid item>
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: "State is required" }}
                          render={({ field }) => <TextInput label="State*" {...field} />}
                        />
                      </Grid>
                      {/* Zip */}
                      <Grid item>
                        <Controller
                          name="zip"
                          control={control}
                          rules={{ required: "Zip is required" }}
                          render={({ field }) => <TextInput label="Zip*" {...field} />}
                        />
                      </Grid>
                      {/* County */}
                      <Grid item>
                        <Controller
                          name="county"
                          control={control}
                          rules={{ required: "County is required" }}
                          render={({ field }) => <TextInput label="County*" {...field} />}
                        />
                      </Grid>
                      {/* Phone */}
                      <Grid item>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{ required: "Phone is required" }}
                          render={({ field }) => <PhoneInput label="Phone*" {...field} />}
                        />
                      </Grid>
                      {/* Fax */}
                      <Grid item>
                        <Controller
                          name="fax"
                          control={control}
                          render={({ field }) => <PhoneInput label="Fax" {...field} />}
                        />
                      </Grid>
                      {/* Federal ID */}
                      <Grid item>
                        <Controller
                          name="federalId"
                          control={control}
                          render={({ field }) => <TextInput label="Federal ID" {...field} />}
                        />
                      </Grid>
                      {/* Sales Tax Number */}
                      <Grid item>
                        <Controller
                          name="salesTaxNum"
                          control={control}
                          render={({ field }) => <TextInput label="Sales Tax Number" {...field} />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* RIGHT COLUMN */}
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2}>
                      {/* Company Logo */}
                      <Grid item>
                        {/* Outer container for the logo & button */}
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            Company Logo
                          </Typography>
                          </Grid>

                          {/* The logo preview (or placeholder) */}
                          <Grid item alignSelf='center'>
                          {displayLogoPreview()}
                          </Grid>

                          {/* Upload button, absolutely positioned at bottom-right */}
                          <Grid item alignSelf='flex-end'>
                          <Button
                            type="button"
                            label="Upload New"
                            onClick={() => setUploadModalOpen(true)}
                            buttonStyle={{
                              position: "relative",
                              bottom: "10px",
                              right: "10px",
                              width: "150px",
                              height: "40px",
                            }}
                          />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Contact Name */}
                      <Grid item>
                        <Controller
                          name="contactName"
                          control={control}
                          render={({ field }) => <TextInput label="Contact Name" {...field} />}
                        />
                      </Grid>
                      {/* Contact Email */}
                      <Grid item>
                        <Controller
                          name="contactEmail"
                          control={control}
                          render={({ field }) => <TextInput label="Contact Email" {...field} />}
                        />
                      </Grid>
                      {/* Contact Phone */}
                      <Grid item>
                        <Controller
                          name="contactPhone"
                          control={control}
                          render={({ field }) => <PhoneInput label="Contact Phone" {...field} />}
                        />
                      </Grid>
                      {/* Lessor Number */}
                      <Grid item>
                        <Controller
                          name="lessorNumber"
                          control={control}
                          render={({ field }) => <TextInput label="Lessor Number" {...field} />}
                        />
                      </Grid>
                      {/* Dealer Number */}
                      <Grid item>
                        <Controller
                          name="dealerNumber"
                          control={control}
                          render={({ field }) => <TextInput label="Dealer Number" {...field} />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Ecom Setup Section */}
              <Grid item>
                <Grid container direction="column" spacing={2} sx={{ mt: 2 }}>
                  <Grid item>
                    <SectionHeader title="Ecom Setup" />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={2}>
                          {/* VoicePhone */}
                          <Grid item>
                            <Controller
                              name="voicePhone"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput label="Voice/Text Phone" {...field} disabled />
                              )}
                            />
                          </Grid>
                          {/* Forward Calls To */}
                          <Grid item>
                            <Controller
                              name="forwardCallsTo"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput label="Forward Calls To" {...field} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={2}>
                          {/* Email From */}
                          <Grid item>
                            <Controller
                              name="emailFrom"
                              control={control}
                              rules={{
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Email must be a valid address",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <TextInput
                                  label="Email From"
                                  {...field}
                                  disabled
                                  errors={!!fieldState.error}
                                />
                              )}
                            />
                          </Grid>
                          {/* Email Display Name */}
                          <Grid item>
                            <Controller
                              name="emailDisplayName"
                              control={control}
                              render={({ field }) => (
                                <TextInput label="Email Display Name" {...field} />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Submit + Cancel Buttons */}
              <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 4 }}>
                <Grid item>
                  <Button
                    type="button"
                    label="Cancel"
                    buttonStyle={{ width: "140px" }}
                    onClick={() => {
                      reset();
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" label="Submit" buttonStyle={{ width: "140px" }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      {selectedTab === 1 && <CompanyDefaults />}

      <NavBlock blocker={blocker} />
      <ConfirmationModal
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        title="Confirm Submission"
        message="You are about to save company details. Do you want to proceed?"
        onConfirm={handleSubmit(onSubmit)}
        isSubmitting={formState.isSubmitting}
      />
      <UploadModal
        isOpen={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        onUpload={handleUploadLogo}
        file={selectedLogoFile}
        setFile={setSelectedLogoFile}
        fileName={selectedLogoFile?.name}
        fileInputError={logoInputError}
        submitLabel="Upload"
        loading={uploadingLogo}
      />
    </>
  );
};

export default CompanyDetails;
