// components
import AmtDueCell from '../tableCellComponents/AmtDueCell';
import ScCell from '../tableCellComponents/ScCell';
import GPSCell from '../tableCellComponents/GPSCell';
// utils
import { KendoColumnDef, newColumnDef } from '../../../../utils/tableLayout/utils';
import { routeBase } from '../..';
// interfaces
import { AccountDb } from '../interfaces';
import { StockNumLinkCell } from '../tableCellComponents/stockNumLinkcell';

const buildDetailRoute = ({ recId }: AccountDb) => `/${routeBase}/${recId}`;

export const leaseNoTagColumns: KendoColumnDef<keyof AccountDb>[] = [
  newColumnDef('stockNum', 'Stock #', 110, StockNumLinkCell, true, true, undefined, ),
  newColumnDef('buyers', 'Buyers', 'auto'),
  newColumnDef('vehicle', 'Vehicle', 'auto'),
  newColumnDef('inventoryVIN', 'VIN', 'auto'),
  newColumnDef('inventoryTagNum', 'Tag #', 100),
  newColumnDef('inventoryState', 'State', 'auto'),
  newColumnDef('inventoryExp', 'Expiration', 'auto'),
  newColumnDef('inventorySaleStatus', 'Inv Status', 100),
  newColumnDef('code3', 'Lot', 80),

];